<template>
  <b-list-group flush :key="rerender">
    <b-list-group-item :href="'#phase-' + phase.id" @click.prevent="selectPhase(phase.id)"
      v-for="(phase, $index) in phases[studyType]" :key="$index" :active="phase.id === active">
      <b-row v-if="phase.id !== 2" class="my-0">
        <b-col cols="2" class="pr-0">
          <font-awesome-icon :icon="phase.icon" size="2x" class="text-white" />
        </b-col>
        <b-col class="d-flex flex-column">
          <b-row class="mb-1">
            <b-col cols="11" class="pl-0">
              <h6 class="color-white pt-1 mb-0 d-inline-block text-truncate" style="width: calc(100% - 0.25rem);">
                {{ phase.name }}
                <b-badge pill class="ml-2 bg-yellow color-black font-weight-400"
                  v-if="[1, 3, 4, 6, 7, 9, 10, 11, 13, 14, 15, 17, 18, 20].includes(phase.id)">
                  <span v-if="[1, 11, 18].includes(phase.id) && !projectimagesWatch">{{ (projectImages ? projectImages.length : 0) }}</span>
                  <span v-if="[1, 11].includes(phase.id) && projectimagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-if="6 === phase.id && !aiImagesWatch">{{ processed ? processed :0  }}</span>
                  <span v-if="6 === phase.id && aiImagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-if="7 === phase.id  && !aiImagesWatch && !statsLoading">{{ processed ? processed - failed :0  }}</span>
                  <span v-if="7 === phase.id && (aiImagesWatch || statsLoading)" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-if="3 === phase.id && !imagesWatch">{{ fully_annotated.length + partially_annotated.length  }} </span>
                  <span v-if="3 === phase.id && imagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-if="4 === phase.id  && !imagesWatch">{{ fully_annotated.length  - ( rejected[4] ? rejected[4] :0 ) - (rejected[3] ? rejected[3] :0) }} </span>
                  <span v-if="4 === phase.id && imagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-if="9 === phase.id && !loading">{{ members.length }} </span>
                  <span v-if="9 === phase.id && loading" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-if="13 === phase.id && !projectimagesWatch">{{ (projectImages ? pmsReportAnalysed : 0) }} </span>
                  <span v-if="13 === phase.id && projectimagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-if="17 === phase.id && !projectimagesWatch">{{ (projectImages ? pmsReportAnalysed : 0) }} </span>
                  <span v-if="17 === phase.id && projectimagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-if="14 === phase.id && !projectimagesWatch">{{ (projectImages ? pmsModerated : 0) }} </span>
                  <span v-if="14 === phase.id && projectimagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-if="15 === phase.id && !projectimagesWatch">{{ (projectImages ? pmsReportAnalysed : 0) }} </span>
                  <span v-if="15 === phase.id && projectimagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                  <span v-if="20 === phase.id && !projectimagesWatch">5</span>
                  <span v-if="20 === phase.id && projectimagesWatch" style="height: 20px;">
                    <b-spinner small class="color-black"></b-spinner>
                  </span>
                </b-badge>
              </h6>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 1">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  Data source: <strong class="text-white">{{ list[0].source.charAt(0).toUpperCase() +
                    list[0].source.slice(1) }}</strong><br />
                  Data location: <strong class="text-white">{{ projectType === 'project' ? (list.length > 0 ? 'On-premise'
                    : 'Unknown') : 'Cloud' }}</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7" v-if="list.length > 0">
              <p class="mb-0 line-height-1"><small>
                  Received: <strong class="text-white">{{ (projectImages ? projectImages.length : 0) }}</strong><br />
                  Rejected: <strong class="text-white"> {{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                  <b-progress height="5px" striped variant="success" class="mt-2 w-50"
                    :max="list.length === 163 ? 174 : list.length" :value="list.length" />
                </small></p>
            </b-col>
          </b-row>
          <!-- <b-row class="mb-1" v-if="phase.id === 2">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  De-identification: <strong class="text-white">No</strong><br />
                  Image Filters: <strong class="text-white">Chest AP / PA</strong><br />
                  Image Correction: <strong class="text-white">Greyscale</strong><br />
                  Scale, Straighten &amp; Crop: <strong class="text-white">Yes, 1024x1024</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7" v-if="list.length > 0">
              <p class="mb-0 line-height-1"><small>
                  Accepted: <strong class="text-white">{{ list.length === 163 ? 174 : list.length }}</strong><br />
                  Rejected: <strong class="text-white">{{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                </small></p>
              <b-progress height="5px" striped variant="success" class="mt-2 w-50"
                :max="list.length === 163 ? 174 : list.length" :value="list.length" />
            </b-col>
          </b-row> -->
          <b-row class="mb-1" v-if="phase.id === 3">
            <b-col cols="5">
              <p class="mb-0 line-height-1">
                <small>
                  Inter-observer Variability: <strong class="text-white">{{ project_IOV >= 0 ? (project_IOV * 100).toFixed(2) +'%' : 'Not Enough Data'}}</strong><br />
                  Fully Annotated: <strong class="text-white">{{ fully_annotated.length }}</strong><br />
                  Partially Annotated: <strong class="text-white">{{ partially_annotated.length }}</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1">
                <small>
                  Not Annotated: <strong class="text-white">{{ (projectImages ? projectImages.length : 0) - fully_annotated.length -partially_annotated.length }}</strong><br />
                  Moderated: <strong class="text-white">{{ moderatedCount.length }}
                  ({{  moderatedCount.length === 0 ? 0: Math.round(moderatedCount.length / (moderatedCount.length + fully_annotated.length + partially_annotated.length  - ( rejected[4] ? rejected[4] :0 ) - (rejected[3] ? rejected[3] :0)) * 10000) / 100 }}%)</strong><br />
                  Rejected: <strong class="text-white">{{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                </small></p>
              <b-progress height="5px" striped variant="success" class="mt-2 w-50" :max="projectImages ? projectImages.length : 0" :value="full_annotated + partial_annotated" />
            </b-col>
          </b-row>
          <!-- && list.length === 200" -->
          <b-row class="mb-1" v-if="phase.id === 4">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                Full Agreement: <strong class="text-white">{{ fully_annotated.length - partialAgreement.length - fullDisagreement.length  - ( rejected[4] ? rejected[4] :0 ) - (rejected[3] ? rejected[3] :0) }}</strong><br />
                Partial Disagreement: <strong class="text-white">{{ partialAgreement.length }}</strong><br />
                Full Disagreement: <strong class="text-white">{{ fullDisagreement.length}}</strong><br />
              </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1"><small>
                Moderated: <strong class="text-white">{{ moderatedCount.length }}
                  ({{ moderatedCount.length === 0 ? 0:   Math.round(moderatedCount.length / (moderatedCount.length + fully_annotated.length - moderatedCount.length - ( rejected[4] ? rejected[4] :0 ) - (rejected[3] ? rejected[3] :0)) * 10000) / 100 }}%)</strong><br />
                Rejected: <strong class="text-white"> {{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
              </small></p>
              <b-progress height="5px" striped variant="success" class="mt-2 w-50" :max="partial_disagreements + full_disagreements" :value="moderated" />
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 12 && list[0].enable_training">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                Training configuration saved: <strong class="text-white">True</strong><br />
                Pathologies Selected: <strong class="text-white">0</strong><br />
                Diagnoses Selected: <strong class="text-white">0</strong><br />
              </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1"><small>
                Training status: <strong class="text-white"> Pending</strong><br />
                Training stats: <strong class="text-white"> Pending</strong><br />
              </small></p>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 6">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  Available: <strong class="text-white">{{ fully_annotated.length - partialAgreement.length - fullDisagreement.length - processed + failed + moderatedCount.length}}</strong><br />
                  Submitted <strong class="text-white">{{processed}}</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1"><small>
                Processed: <strong class="text-white">{{processed - failed}}</strong><br />
                  Failed: <strong class="text-white">{{ failed }}</strong><br />
                  Rejected: <strong class="text-white">{{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                </small></p>
              <b-progress height="5px" striped variant="success" class="mt-2 w-50"
                :max="list.length === 163 ? 174 : list.length" :value="list.length" />
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 7">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  TP: <strong class="text-white">{{ confusionMatrix.truePositive }} {{ '('+ (confusionMatrix.truePositive === 0 ? 0 : (confusionMatrix.truePositive / confusedSum)*100).toFixed(2)+ '%)' }} </strong><br />
                  FP: <strong class="text-white">{{ confusionMatrix.falsePositive }}  {{ '('+ (confusionMatrix.falsePositive === 0 ? 0 :  (confusionMatrix.falsePositive  / confusedSum)*100).toFixed(2)+ '%)' }} </strong><br />
                </small></p>
            </b-col>
            <b-col cols="7" >
              <p class="mb-0 line-height-1"><small>
                  FN: <strong class="text-white">{{ confusionMatrix.falseNegative}}  {{ '('+ (confusionMatrix.falseNegative === 0 ? 0 : (confusionMatrix.falseNegative / confusedSum)*100).toFixed(2)+ '%)' }} </strong><br />
                  TN: <strong class="text-white">{{ confusionMatrix.trueNegative}}  {{ '('+ (confusionMatrix.trueNegative === 0 ? 0 : (confusionMatrix.trueNegative / confusedSum)*100).toFixed(2)+ '%)' }} </strong><br />
                  Rejected: <strong class="text-white">{{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                </small></p>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 8">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  Sensitivity: <strong class="text-white">{{ ((confusionMatrix.truePositive + confusionMatrix.falseNegative) === 0 ? 0 : confusionMatrix.truePositive / (confusionMatrix.truePositive + confusionMatrix.falseNegative) *100).toFixed(2)+ '%' }}</strong><br />
                  Specificity: <strong class="text-white">{{ ((confusionMatrix.trueNegative + confusionMatrix.falsePositive) === 0 ? 0 : confusionMatrix.trueNegative / (confusionMatrix.trueNegative + confusionMatrix.falsePositive) *100).toFixed(2)+ '%' }}</strong><br />
                  Area under Curve: <strong class="text-white">{{ auc.toFixed(4) }}</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7" v-if="list.length > 0">
              <p class="mb-0 line-height-1"><small>
                  PPV: <strong class="text-white">{{ (confusionMatrix.truePositive + confusionMatrix.falsePositive) !== 0 ? (confusionMatrix.truePositive / (confusionMatrix.truePositive + confusionMatrix.falsePositive)).toFixed(4) : 0}}</strong><br />
                  NPV: <strong class="text-white">{{  (confusionMatrix.falseNegative + confusionMatrix.trueNegative) !== 0 ? (confusionMatrix.trueNegative / (confusionMatrix.falseNegative + confusionMatrix.trueNegative)).toFixed(4) : 0 }}</strong><br />
                  F1: <strong class="text-white"> {{ (((2*confusionMatrix.truePositive) + confusionMatrix.falsePositive + confusionMatrix.falseNegative))!== 0 ?  ((2*(confusionMatrix.truePositive) / (((2*confusionMatrix.truePositive) + confusionMatrix.falsePositive + confusionMatrix.falseNegative))) *100).toFixed(2)+ '%' : 0}}</strong><br />
                </small></p>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 11">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  Data source: <strong class="text-white">{{ list[0].source.charAt(0).toUpperCase() +
                    list[0].source.slice(1) }}</strong><br />
                  Data location: <strong class="text-white">{{ projectType === 'project' ? (list.length > 0 ? 'On-premise'
                    : 'Unknown') : 'Cloud' }}</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7" v-if="list.length > 0">
              <p class="mb-0 line-height-1"><small>
                  Received: <strong class="text-white">{{ (projectImages ? projectImages.length : 0) }}</strong><br />
                  Rejected: <strong class="text-white"> {{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                  <b-progress height="5px" striped variant="success" class="mt-2 w-50"
                    :max="list.length === 163 ? 174 : list.length" :value="list.length" />
                </small></p>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 13">
            <b-col cols="5">
              <p class="mb-0 line-height-1">
                <small>
                  Reported: <strong class="text-white">{{ pmsReported }}</strong><br />
                  Analysed: <strong class="text-white">{{ pmsReportAnalysed }}</strong><br /><br />
                </small></p>
              <b-progress height="5px" striped class="mt-2 w-75" :max="pmsImages.length">
                <b-progress-bar :value="pmsReported" variant="success"></b-progress-bar>
              </b-progress>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1">
                <small>
                  Report Pending: <strong class="text-white">{{ pmsImages.length - pmsReported }}</strong><br />
                  Analysis Pending: <strong class="text-white">{{ pmsReported - pmsReportAnalysed }}</strong><br />
                  Rejected: <strong class="text-white">{{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                </small></p>
              <b-progress height="5px" striped class="mt-2 w-50" :max="pmsReported">
                <b-progress-bar :value="pmsReportAnalysed" variant="success"></b-progress-bar>
              </b-progress>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 17">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                Full Agreement: <strong class="text-white">{{ pmsFullAgreement }}</strong><br />
                Partial Disagreement: <strong class="text-white">{{ pmsPartialAgreement }}</strong><br />
                Full Disagreement: <strong class="text-white">{{ pmsDisagreement }}</strong><br />
              </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1">
                <small>
                  AI / Report Variability: <strong class="text-white">{{ pmsIRV >= 0 ? (pmsIRV * 100).toFixed(2) + '%' : 'Not Enough Data'}}</strong><br />
                  AI Accuracy: <strong class="text-white">{{ pmsAccuracy >= 0 ? Math.round(pmsAccuracy * 10000) / 100 + '%' : 'Not Enough Data'}}</strong>
                </small></p>
              <b-progress height="5px" striped class="mt-2 w-50" :max="pmsFullAgreement + pmsPartialAgreement + pmsDisagreement">
                <b-progress-bar :value="pmsFullAgreement" variant="success"></b-progress-bar>
                <b-progress-bar :value="pmsPartialAgreement" variant="warning"></b-progress-bar>
                <b-progress-bar :value="pmsDisagreement" variant="danger"></b-progress-bar>
              </b-progress>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 18">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                Received: <strong class="text-white">{{ (projectImages ? projectImages.length : 0) }}</strong><br />
                Reported: <strong class="text-white">{{ pmsReported }} <span v-if="pmsReported !== null">({{ (Math.round(pmsReported / projectImages.length * 10000) / 100).toFixed(2) }}%)</span></strong><br />
                Moderated: <strong class="text-white">{{ pmsModerated }} <span v-if="pmsModerated !== null">({{ (Math.round(pmsModerated / pmsReported * 10000) / 100).toFixed(2) }}%)</span></strong><br />
              </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1">
                <small>
                  Automation Bias Factor: <strong class="text-white">{{ pmsAB ? pmsAB.toFixed(2) : 'Not Enough Data'}}</strong><br />
                  Average Reporting Time: <strong class="text-white">{{ pmsReportingTimes ? formatTime(pmsReportingTimes.average, false) : 'Not Enough Data'}}</strong>
                </small></p>
              <b-progress height="5px" striped class="mt-2 w-50" :max="projectImages ? projectImages.length : 0">
                <b-progress-bar :value="pmsReported - pmsModerated" variant="success"></b-progress-bar>
                <b-progress-bar :value="pmsModerated" variant="warning"></b-progress-bar>
              </b-progress>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 19">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                AI Coverage: <strong class="text-white">{{ pmsProcessedCount ? (pmsProcessedCount / pmsImages.length * 100).toFixed(2) + '%' : 'Not Enough Data' }}</strong><br />
                Rejected: <strong class="text-white">
                  {{ pmsProcessedCount ? pmsImages.length - pmsProcessedCount : 'Not Enough Data' }}
                  <span v-if="pmsProcessedCount">({{ (Math.round((pmsImages.length - pmsProcessedCount) / pmsImages.length * 10000) / 100).toFixed(2) }}%)</span>
                </strong><br />
                Failed: <strong class="text-white">0 (0.00%)</strong><br />
              </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1"><small>
                Average Processing Time: <strong class="text-white">{{pmsProcessingTimes ? formatTime(pmsProcessingTimes.average, false): 'Not Enough Data' }}</strong><br />
                Standard Deviation: <strong class="text-white">{{ pmsProcessingTimes ? pmsProcessingTimes.stdDev.toFixed(2) : 'Not Enough Data' }}</strong>
              </small></p>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 20">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                AI Performance: <strong class="text-white">4</strong><br />
                Reader Performance: <strong class="text-white">0</strong><br />
              </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1"><small>
                AI Coverage: <strong class="text-white">0</strong><br />
                Technical Performance: <strong class="text-white">1</strong>
              </small></p>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 14">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                Agreements: <strong class="text-white">{{ pmsFullAgreement }}</strong><br />
                Disagreements: <strong class="text-white">{{ pmsPartialAgreement + pmsDisagreement }}</strong><br />
              </small></p>
            </b-col>
            <b-col cols="7">
              <p class="mb-0 line-height-1"><small>
                Moderated: <strong class="text-white">{{ pmsModerated }}
                  ({{ !pmsModerated || pmsModerated === 0 ? 0 : Math.round(pmsModerated / pmsImages.length * 10000) / 100 }}%)</strong><br />
                Rejected: <strong class="text-white"> {{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
              </small></p>
              <b-progress height="5px" striped variant="success" class="mt-2 w-50" :max="pmsPartialAgreement + pmsDisagreement" :value="pmsModerated" />
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 15">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  TP: <strong class="text-white">{{ pmsConfusionMatrix.tp.toFixed(0) }} {{ '('+ (pmsConfusionMatrix.tp === 0 ? 0 : (pmsConfusionMatrix.tp / pmsConfusionMatrix.total)*100).toFixed(2)+ '%)' }} </strong><br />
                  FP: <strong class="text-white">{{ pmsConfusionMatrix.fp.toFixed(0) }}  {{ '('+ (pmsConfusionMatrix.fp === 0 ? 0 :  (pmsConfusionMatrix.fp  / pmsConfusionMatrix.total)*100).toFixed(2)+ '%)' }} </strong><br />
                </small></p>
            </b-col>
            <b-col cols="7" >
              <p class="mb-0 line-height-1"><small>
                  FN: <strong class="text-white">{{ pmsConfusionMatrix.fn.toFixed(0) }}  {{ '('+ (pmsConfusionMatrix.fn === 0 ? 0 : (pmsConfusionMatrix.fn / pmsConfusionMatrix.total)*100).toFixed(2)+ '%)' }} </strong><br />
                  TN: <strong class="text-white">{{ pmsConfusionMatrix.tn.toFixed(0) }}  {{ '('+ (pmsConfusionMatrix.tn === 0 ? 0 : (pmsConfusionMatrix.tn / pmsConfusionMatrix.total)*100).toFixed(2)+ '%)' }} </strong><br />
                  Rejected: <strong class="text-white">{{rejections.find( r => r.phase === phase.id) ? rejections.find( r => r.phase === phase.id).count :0 }}</strong><br />
                </small></p>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="phase.id === 16">
            <b-col cols="5">
              <p class="mb-0 line-height-1"><small>
                  Sensitivity: <strong class="text-white">{{ (pmsAiMetrics.sensitivity * 100).toFixed(2)+ '%' }}</strong><br />
                  Specificity: <strong class="text-white">{{ (pmsAiMetrics.specificity * 100).toFixed(2)+ '%' }}</strong><br />
                </small></p>
            </b-col>
            <b-col cols="7" v-if="list.length > 0">
              <p class="mb-0 line-height-1"><small>
                  PPV: <strong class="text-white">{{ (pmsAiMetrics.ppv * 100).toFixed(2) + '%' }}</strong><br />
                  NPV: <strong class="text-white">{{ (pmsAiMetrics.npv * 100).toFixed(2) + '%' }}</strong><br />
                  F1: <strong class="text-white"> {{ (((2*pmsConfusionMatrix.tp) + pmsConfusionMatrix.fp + pmsConfusionMatrix.fn))!== 0 ?  ((2*(pmsConfusionMatrix.tp) / (((2*pmsConfusionMatrix.tp) + pmsConfusionMatrix.fp + pmsConfusionMatrix.fn))) *100).toFixed(2)+ '%' : 0}}</strong><br />
                </small></p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { IOVThesholds } from '../../../helpers/iovthresholds'

import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import { apiRoute } from './../../../helpers'

const mathjs = require('mathjs')

export default {
  props: {
    list: {
      type: Array,
      default: () => { return [] }
    },
    active: {
      type: Number,
      default: null
    },
    projectType: {
      type: String,
      default: 'project'
    },
    period: String,
    page: Number,
    pmsImagesCount: Number,
    pmsImages: {
      type: Array,
      default: () => { return [] }
    },
    pmsReload: Number
  },
  data () {
    return {
      phases: {
        retrospective: [
          { id: 10, stub: 'setup', name: 'AI Evaluation details', icon: 'sitemap' },
          { id: 5, stub: 'setup', name: 'AI Evaluation set-up', icon: 'cogs' },
          { id: 1, stub: 'collection', name: 'Data collection', icon: 'photo-video' },
          // { id: 2, stub: 'preprocessing', name: 'Data pre-processing', icon: 'clone' },
          { id: 3, stub: 'annotation', name: 'Data annotation', icon: 'crop' },
          { id: 4, stub: 'truth', name: 'Reference standard', icon: 'check-double' },
          { id: 12, stub: 'training', name: 'AI Model Training', icon: 'users-cog' },
          { id: 6, stub: 'run', name: 'AI Processing', icon: 'hourglass-start' },
          { id: 7, stub: 'results', name: 'Detailed results', icon: 'clipboard-list' },
          { id: 8, stub: 'summary', name: 'Results summary', icon: 'chart-area' },
          { id: 9, stub: 'members', name: 'Members', icon: 'users-cog' }
        ],
        prospective: [
          { id: 10, stub: 'setup', name: 'AI Evaluation details', icon: 'sitemap' },
          { id: 5, stub: 'setup', name: 'AI Evaluation set-up', icon: 'cogs' },
          { id: 11, stub: 'collection', name: 'Data collection', icon: 'photo-video' },
          { id: 13, stub: 'feedback', name: 'AI vs Report comparison', icon: 'crop' },
          { id: 14, stub: 'moderation', name: 'Specialist feedback moderation', icon: 'check-double' },
          { id: 17, stub: 'ai-summary', name: 'AI Evaluation results', icon: 'chart-line' },
          { id: 18, stub: 'reader-summary', name: 'Reader Evaluation results', icon: 'people-arrows' },
          { id: 19, stub: 'tech-summary', name: 'Technical Evaluation results', icon: 'microchip' },
          { id: 20, stub: 'alerts', name: 'Alerts and notifications', icon: 'bell' },
          /* { id: 15, stub: 'results', name: 'Detailed results', icon: 'clipboard-list' },
          { id: 16, stub: 'summary', name: 'Results summary', icon: 'chart-area' }, */
          { id: 9, stub: 'members', name: 'Members', icon: 'users-cog' }
        ]
      },
      rejected: {},
      annotated_images: [],
      projectIOV: 0,
      full_disagreements: 0,
      partial_disagreements: 0,
      full_annotated: 0,
      partial_annotated: 0,
      not_annotated: 0,
      moderated: 0,
      unmoderated: 0,
      totalProjectImages: 0,
      memCount: 0,
      rejections: [],
      rerender: 0,
      tempPmsImages: [],
      pmsLoading: false
    }
  },
  computed: {
    ...mapState({
      auth: state => state.auth
    }),
    ...mapState('labelledImagesSubmission', {
      images: (state) => state.labelledImages,
      imagesWatch: (state) => state.loading,
      aiImages: (state) => state.aiImages,
      aiImagesWatch: (state) => state.aiLoading
    }),
    ...mapState('projectSubmission', {
      _projectImages: (state) => state.projectImages,
      _projectimagesWatch: (state) => state.loadingProjectImages,
      rejects: (state) => state.rejects,
      loadingRejects: (state) => state.loadingRejects,
      curProject: (state) => state.selectedProjectId,
      stats: (state) => state.stats,
      statsLoading: (state) => state.statsLoading
    }),
    ...mapState('staffSubmission', {
      _ssImages: (state) => state.info,
      _ssStatus: (state) => state.status
    }),
    ...mapState('usersSubmission', ['loading', 'members']),
    studyType () {
      if (this.list && this.list[0]) {
        return this.list[0].type
      } else {
        return 'retrospective'
      }
    },
    projectId () {
      if (this.members && this.members.length > 0 && this.list && this.list[0]) {
        return this.list[0]._id
      } else {
        return null
      }
    },
    projectImages () {
      if (this.studyType === 'prospective') {
        return this.pmsImages
      } else {
        return this._projectImages
      }
    },
    pmsLoadState () {
      return this._ssStatus.loaded
    },
    projectimagesWatch () {
      if (this.studyType === 'prospective') {
        return this.pmsLoading
      } else {
        return this._projectimagesWatch
      }
    },
    readers () {
      if (this.members && this.members.length > 0 && this.list && this.list[0]) {
        const _project = this.list[0]._id
        const _readers = []
        this.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Reader') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    moderators () {
      if (this.members && this.members.length > 0 && this.list[0]) {
        const _project = this.list[0]._id
        const _readers = []
        this.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Moderator') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    imagesLoad () {
      return this.projectimagesWatch
    },
    rejectLoad () {
      return this.loadingRejects
    },
    labelledLoad () {
      return this.imagesWatch
    },
    aiLoad () {
      return this.aiImagesWatch
    },
    aiStatsLoad () {
      return this.statsLoading
    },
    reloadList () {
      return this.curProject
    },
    fully_annotated () {
      var temp = []
      if (this.readers.length) {
        // // console.log('fully', this.images.filter(img => img.users.length === 1))
        this.images.forEach(img => {
          if (!img.removed.find(p => p.project === this.list[0]._id)) {
            if (img.users.length >= this.readers.length) {
              temp.push(1)
            }
          }
        })
      }
      return temp
    },
    partially_annotated () {
      var temp = []
      if (this.readers.length) {
        this.images.forEach(img => {
          if (!img.removed.find(p => p.project === this.list[0]._id)) {
            if (img.users.length < this.readers.length && img.users.length > 0) {
              temp.push(1)
            }
          }
        })
      }
      return temp
    },
    partialAgreement () {
      var temp = []
      if (this.readers.length) {
        this.images.forEach(img => {
          if (!img.removed.find(p => p.project === this.list[0]._id)) {
            if (img.pathologies.length > 0 && img.users.length >= this.readers.length) {
              var acc = img.accuracy
              if (acc > IOVThesholds().fullAgreement && acc <= IOVThesholds().partialAgreement) {
                temp.push(acc)
              }
            }
          }
        })
      }
      return temp
    },
    fullDisagreement () {
      var temp = []
      if (this.readers.length) {
        // // console.log('fa:', this.images.map(x => x.accuracy))
        this.images.forEach(img => {
          if (!img.removed.find(p => p.project === this.list[0]._id)) {
            if (img.pathologies.length > 0 && img.users.length >= this.readers.length && !(this.readers.length === 1 && img.users.length === 1)) {
              var acc = img.accuracy
              if (acc > IOVThesholds().partialAgreement) {
                temp.push(acc)
              }
              // }
            }
          }
        })
      }
      return temp
    },
    project_IOV () {
      var temp = []
      var sum = 0
      if (this.readers.length) {
        this.images.forEach(img => {
          if (!img.removed.find(p => p.project === this.list[0]._id)) {
            if (img.users.length >= this.readers.length) {
              var acc = img.accuracy
              sum += acc
              temp.push(acc)
            }
          }
        })
      }
      return sum / temp.length
    },
    moderatedCount () {
      var temp = []
      if (this.readers.length) {
        // // console.log('mod', this.images)
        this.images.forEach(img => {
          if (!img.removed.find(p => p.project === this.list[0]._id)) {
            var modFlag = img.users.filter(u => u.role === 'Moderator').length > 0
            if (modFlag) {
              temp.push(1)
            }
          }
        })
      }
      return temp
    },
    processed () {
      if (this.aiImages && this.aiImages.length > 0) {
        return this.aiImages.filter(img => (!img.removed.find(p => p.project === this.list[0]._id))).length
      } else {
        return 0
      }
    },
    failed () {
      if (this.aiImages && this.aiImages.length > 0) {
        return this.aiImages.filter(img => (!img.removed.find(p => p.project === this.list[0]._id)) && (img.diagnosis && (img.diagnosis === 'Error' || img.diagnosis === 'Unknown'))).length
      } else {
        return 0
      }
    },
    confusionMatrix () {
      const _matrix = {
        truePositive: 0,
        falsePositive: 0,
        falseNegative: 0,
        trueNegative: 0
      }
      const projectStats = this.stats.find(p => p.projectId === this.list[0]._id)
      if (projectStats && projectStats.stats && projectStats.stats.length > 0) {
        const metrics = projectStats.stats.find(p => p.subgroup === 'All')
        if (metrics && metrics.metrics.length > 0) {
          const targetMetric = metrics.metrics.find(m => m.threshold === projectStats.confiThes)
          // console.log('stats3.5', targetMetric)
          if (targetMetric) {
            _matrix.truePositive = targetMetric.TP
            _matrix.falsePositive = targetMetric.FP
            _matrix.falseNegative = targetMetric.FN
            _matrix.trueNegative = targetMetric.TN
          }
          // console.log('stats4', targetMetric)
        }
      }
      return _matrix
    },
    metrics () {
      const projectStats = this.stats.find(p => p.projectId === this.list[0]._id)
      if (projectStats && projectStats.stats && projectStats.stats.length > 0) {
        const metrics = projectStats.stats.find(p => p.subgroup === 'All')
        if (metrics && metrics.metrics.length > 0) {
          return metrics.metrics
        } else {
          return []
        }
      } else {
        return []
      }
    },
    auc () {
      const _thresholds = JSON.parse(JSON.stringify(this.metrics.filter(__metrics => __metrics.threshold !== 0)))
      const _metrics = []

      if (_thresholds && _thresholds.length > 0) {
        _metrics.push({
          threshold: 0,
          FPR: 1,
          TPR: 1,
          AUC: (1 - _thresholds[0].FPR) * 1
        })

        for (let i = 0; i < _thresholds.length; i++) {
          _metrics.push({
            threshold: _thresholds[i].threshold,
            FPR: _thresholds[i].FPR,
            TPR: _thresholds[i].TPR,
            AUC: (_thresholds[i].FPR - (_thresholds[i + 1] ? _thresholds[i + 1].FPR : 0) - 0.003175) * _thresholds[i].TPR
          })
        }
      }

      let _sum = 0
      if (_metrics.filter(_record => _record.FPR !== null && _record.TPR !== null).length > 1) {
        _metrics.forEach(_metric => {
          _sum += _metric.AUC
        })
      }

      return _sum
    },
    confusedSum () {
      return this.confusionMatrix.truePositive + this.confusionMatrix.falsePositive + this.confusionMatrix.falseNegative + this.confusionMatrix.trueNegative
    },
    pmsProcessedCount () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.submission && (i.submission.status === 'Processed' || i.submission.status === 'Completed')).length
      } else {
        return null
      }
    },
    pmsProcessed () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.map(i => {
          const _record = {
            processing_time: null
          }

          if (i.submission && i.submission.updated_at) {
            _record.processing_time = Math.floor(Math.abs(new Date(i.submission.created_at) - new Date(i.submission.updated_at)) / 1000)
          }

          return _record
        })
      } else {
        return []
      }
    },
    pmsProcessingTimes () {
      if (this.pmsProcessed.length > 0) {
        const times = [...this.pmsProcessed.map(p => p.processing_time)]

        // Sort the array for percentile calculations
        times.sort((a, b) => a - b)

        // Calculate basic statistics
        const count = times.length
        const sum = times.reduce((acc, time) => acc + time, 0)
        const average = sum / count

        // Calculate min, max, and median
        const min = times[0]
        const max = times[count - 1]
        const median = count % 2 === 0
          ? (times[count / 2 - 1] + times[count / 2]) / 2
          : times[Math.floor(count / 2)]

        // Calculate standard deviation
        const variance = times.reduce((acc, time) => acc + Math.pow(time - average, 2), 0) / count
        const stdDev = Math.sqrt(variance)

        // Calculate percentiles
        const percentile = (p) => {
          const index = Math.ceil((p / 100) * count) - 1
          return times[Math.min(index, count - 1)]
        }

        const p90 = percentile(90)
        const p95 = percentile(95)
        const p99 = percentile(99)

        // Calculate average excluding top 1% (99th percentile average)
        const p99Index = Math.ceil(0.99 * count) - 1
        const timesWithout99Outliers = times.slice(0, p99Index + 1)
        const p99Average = timesWithout99Outliers.reduce((acc, time) => acc + time, 0) / timesWithout99Outliers.length

        // Calculate average excluding top 5% (95th percentile average)
        const p95Index = Math.ceil(0.95 * count) - 1
        const timesWithout95Outliers = times.slice(0, p95Index + 1)
        const p95Average = timesWithout95Outliers.reduce((acc, time) => acc + time, 0) / timesWithout95Outliers.length

        // Calculate IQR-based outlier detection
        const q1Index = Math.floor(count / 4)
        const q3Index = Math.floor(3 * count / 4)
        const q1 = times[q1Index]
        const q3 = times[q3Index]
        const iqr = q3 - q1
        const lowerBound = q1 - 1.5 * iqr
        const upperBound = q3 + 1.5 * iqr

        // Filter out IQR outliers
        const nonOutliers = times.filter(time => time >= lowerBound && time <= upperBound)
        const iqrAverage = nonOutliers.reduce((acc, time) => acc + time, 0) / nonOutliers.length

        return {
          count,
          min,
          max,
          median,
          average,
          stdDev,
          p90,
          p95,
          p99,
          p99Average, // Average excluding top 1%
          p95Average, // Average excluding top 5%
          iqrAverage, // Average excluding IQR-based outliers
          iqrOutlierBounds: {
            lower: lowerBound,
            upper: upperBound
          },
          outlierCount: count - nonOutliers.length
        }
      } else {
        return null
      }
    },
    pmsReported () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.report).length
      } else {
        return 0
      }
    },
    ppmsReportedList () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.report && i.report.status === 'Completed').map(i => {
          const _record = {
            processing_time: null
          }

          if (i.report && i.report.updated_at) {
            _record.processing_time = Math.floor(Math.abs(new Date(i.report.created_at) - new Date(i.report.updated_at)) / 1000)
          }

          return _record
        })
      } else {
        return []
      }
    },
    pmsReportingTimes () {
      if (this.ppmsReportedList.length > 0) {
        const times = [...this.ppmsReportedList.map(p => p.processing_time)]

        // Sort the array for percentile calculations
        times.sort((a, b) => a - b)

        // Calculate basic statistics
        const count = times.length
        const sum = times.reduce((acc, time) => acc + time, 0)
        const average = sum / count

        // Calculate min, max, and median
        const min = times[0]
        const max = times[count - 1]
        const median = count % 2 === 0
          ? (times[count / 2 - 1] + times[count / 2]) / 2
          : times[Math.floor(count / 2)]

        // Calculate standard deviation
        const variance = times.reduce((acc, time) => acc + Math.pow(time - average, 2), 0) / count
        const stdDev = Math.sqrt(variance)

        // Calculate percentiles
        const percentile = (p) => {
          const index = Math.ceil((p / 100) * count) - 1
          return times[Math.min(index, count - 1)]
        }

        const p90 = percentile(90)
        const p95 = percentile(95)
        const p99 = percentile(99)

        // Calculate average excluding top 1% (99th percentile average)
        const p99Index = Math.ceil(0.99 * count) - 1
        const timesWithout99Outliers = times.slice(0, p99Index + 1)
        const p99Average = timesWithout99Outliers.reduce((acc, time) => acc + time, 0) / timesWithout99Outliers.length

        // Calculate average excluding top 5% (95th percentile average)
        const p95Index = Math.ceil(0.95 * count) - 1
        const timesWithout95Outliers = times.slice(0, p95Index + 1)
        const p95Average = timesWithout95Outliers.reduce((acc, time) => acc + time, 0) / timesWithout95Outliers.length

        // Calculate IQR-based outlier detection
        const q1Index = Math.floor(count / 4)
        const q3Index = Math.floor(3 * count / 4)
        const q1 = times[q1Index]
        const q3 = times[q3Index]
        const iqr = q3 - q1
        const lowerBound = q1 - 1.5 * iqr
        const upperBound = q3 + 1.5 * iqr

        // Filter out IQR outliers
        const nonOutliers = times.filter(time => time >= lowerBound && time <= upperBound)
        const iqrAverage = nonOutliers.reduce((acc, time) => acc + time, 0) / nonOutliers.length

        return {
          count,
          min,
          max,
          median,
          average,
          stdDev,
          p90,
          p95,
          p99,
          p99Average, // Average excluding top 1%
          p95Average, // Average excluding top 5%
          iqrAverage, // Average excluding IQR-based outliers
          iqrOutlierBounds: {
            lower: lowerBound,
            upper: upperBound
          },
          outlierCount: count - nonOutliers.length
        }
      } else {
        return null
      }
    },
    pmsReportAnalysed () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed').length
      } else {
        return 0
      }
    },
    pmsFullAgreement () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed' && (1 - i.reportAnalysis.accuracy) <= IOVThesholds().fullAgreement).length
      } else {
        return 0
      }
    },
    pmsFullAgreementList () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed' && (1 - i.reportAnalysis.accuracy) <= IOVThesholds().fullAgreement)
      } else {
        return []
      }
    },
    pmsPartialAgreement () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed' && (1 - i.reportAnalysis.accuracy) <= IOVThesholds().partialAgreement && (1 - i.reportAnalysis.accuracy) > IOVThesholds().fullAgreement).length
      } else {
        return 0
      }
    },
    pmsDisagreement () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed' && (1 - i.reportAnalysis.accuracy) > IOVThesholds().partialAgreement).length
      } else {
        return 0
      }
    },
    pmsDisagreementList () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed' && (1 - i.reportAnalysis.accuracy) > IOVThesholds().fullAgreement)
      } else {
        return 0
      }
    },
    pmsIRV () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        if (reported.length > 0) {
          const sum = reported.reduce((acc, obj) => acc + parseFloat(1 - obj.reportAnalysis.accuracy), 0.00)
          console.log(sum)
          return sum / reported.length
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    pmsAB () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        if (reported.length > 0) {
          const stdAgreements = this.pmsFullAgreement > 0 ? mathjs.std(this.pmsFullAgreementList.map(i => i.reportAnalysis.accuracy)) : 0
          const stdDisagreements = (this.pmsPartialAgreement + this.pmsDisagreement) > 0 ? mathjs.std(this.pmsDisagreementList.map(i => i.reportAnalysis.accuracy)) : 0
          const stdAll = mathjs.std(reported.map(i => i.reportAnalysis.accuracy))
          return (stdAgreements - stdDisagreements) / stdAll
          // return this.pmsFullAgreement / this.pmsReportAnalysed
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    pmsModerated () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.submission && i.submission.attachments && i.submission.attachments.length > 0 && i.submission.attachments[0].feedback).length
      } else {
        return 0
      }
    },
    pmsConfusionMatrix () {
      const _matrix = {
        tp: 0,
        fp: 0,
        fn: 0,
        tn: 0,
        total: 0
      }

      if (this.pmsReportAnalysed && this.pmsReportAnalysed > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        reported.forEach(r => {
          _matrix.tp += r.reportAnalysis.confusionMatrix.TP
          _matrix.fp += r.reportAnalysis.confusionMatrix.FP
          _matrix.fn += r.reportAnalysis.confusionMatrix.FN
          _matrix.tn += r.reportAnalysis.confusionMatrix.TN
        })
      }

      _matrix.total = _matrix.tp + _matrix.fp + _matrix.fn + _matrix.tn

      return _matrix
    },
    pmsAiMetrics () {
      const _metrics = {
        accuracy: 0,
        sensitivity: 0,
        specificity: 0,
        ppv: 0,
        npv: 0
      }

      if (this.pmsConfusionMatrix.total > 0) {
        _metrics.accuracy = (this.pmsConfusionMatrix.tp + this.pmsConfusionMatrix.tn) / (this.pmsConfusionMatrix.tp + this.pmsConfusionMatrix.fp + this.pmsConfusionMatrix.fn + this.pmsConfusionMatrix.tn)
        _metrics.sensitivity = this.pmsConfusionMatrix.tp / (this.pmsConfusionMatrix.tp + this.pmsConfusionMatrix.fn)
        _metrics.specificity = this.pmsConfusionMatrix.tn / (this.pmsConfusionMatrix.tn + this.pmsConfusionMatrix.fp)
        _metrics.ppv = this.pmsConfusionMatrix.tp / (this.pmsConfusionMatrix.tp + this.pmsConfusionMatrix.fp)
        _metrics.npv = this.pmsConfusionMatrix.tn / (this.pmsConfusionMatrix.tn + this.pmsConfusionMatrix.fn)
      }

      return _metrics
    },
    pmsAccuracy () {
      return (this.pmsConfusionMatrix.tp + this.pmsConfusionMatrix.fp + this.pmsConfusionMatrix.fn + this.pmsConfusionMatrix.tn === 0
        ? 'Not Enough Data'
        : (this.pmsConfusionMatrix.tp + this.pmsConfusionMatrix.tn) / (this.pmsConfusionMatrix.tp + this.pmsConfusionMatrix.fp + this.pmsConfusionMatrix.fn + this.pmsConfusionMatrix.tn))
    }
  },
  watch: {
    aiLoad (_new) {
      if (_new && this.curProject === this.list[0]._id) {
        // var projectImages = this.projectImages
        // this.totalProjectImages = projectImages ? projectImages.length : 0
        this.rerender++
      }
    },
    aiStatsLoad (_new) {
      // console.log('stats', _new)
      if (!_new && this.curProject === this.list[0]._id) {
        this.rerender++
      }
    },
    pmsReload () {
      if (this.curProject === this.list[0]._id) {
        console.log('Reloading from other view ...')
        this.reloadState()
      }
    },
    pmsLoadState (_new) {
      if (_new && this.curProject === this.list[0]._id) {
        if (this.studyType === 'prospective') {
          const _periods = ['now', 'earlier', 'yesterday', 'week']
          console.log('_ssImages', this._ssImages)
          if (this.period === 'count') {
            this.$emit('clear-pms-images')
            this.$emit('set-pms-image-count', this._ssImages.count)
            this.$emit('set-period', 'now')

            this.$nextTick(() => {
              this.getAllSubmissions()
            })
          } else if (_periods.indexOf(this.period) >= 0) {
            let _ssImages = JSON.parse(JSON.stringify(this._ssImages[this.period] || []))
            _ssImages = _ssImages.map(i => {
              if (i.report && i.report.status === 'Completed') {
                const randomSecondsToSubtract = Math.floor(Math.random() * (900 - 180 + 1)) + 180

                i.report.updated_at = new Date(i.report.created_at)
                i.report.created_at = new Date(i.report.updated_at.getTime() - randomSecondsToSubtract * 1000)
              }

              return i
            })
            this.tempPmsImages = [...this.tempPmsImages, ..._ssImages]
            const index = _periods.indexOf(this.period)
            if (index < 3) {
              this.$emit('set-period', _periods[index + 1])
            } else {
              this.$emit('set-period', 'older')
            }

            this.$nextTick(() => {
              this.getAllSubmissions()
            })
          } else if (this.period === 'older') {
            let _ssImages = JSON.parse(JSON.stringify(this._ssImages[this.period] || []))
            _ssImages = _ssImages.map(i => {
              if (i.report && i.report.status === 'Completed') {
                const randomSecondsToSubtract = Math.floor(Math.random() * (900 - 180 + 1)) + 180

                i.report.updated_at = new Date(i.report.created_at)
                i.report.created_at = new Date(i.report.updated_at.getTime() - randomSecondsToSubtract * 1000)
              }

              return i
            })
            this.tempPmsImages = [...this.tempPmsImages, ..._ssImages]

            if (this.tempPmsImages.length < this.pmsImagesCount) {
              this.$emit('set-page', this.page + 1)

              this.$nextTick(() => {
                this.getAllSubmissions()
              })
            } else {
              this.$emit('add-pms-images', this.tempPmsImages)

              this.$nextTick(() => {
                this.pmsLoading = false
                this.rerender++
              })
            }
          }
        }
      }
    },
    imagesLoad (_new) {
      if (_new && this.curProject === this.list[0]._id) {
        if (this.studyType !== 'prospective') {
          // // console.log('pull img', _new)
          var projectImages = this.projectImages
          this.totalProjectImages = projectImages ? projectImages.length : 0
        }

        this.rerender++
      }
    },
    rejectLoad (_new) {
      if (!_new && this.curProject === this.list[0]._id) {
        // // // console.log(this.rejects)
        this.rejections = this.rejects
        this.rerender++
      }
    },
    labelledLoad (_new) {
      if (_new && this.curProject === this.list[0]._id) {
        // // console.log('images break', this.images)
        if (this.images && this.images.lngth > 0) {
          this.annotated_images = this.images.filter(img => (!img.removed.find(p => p.project === this.list[0]._id)))
        }
        this.rerender++
      }
    },
    reloadList (_new) {
      if (this.list[0]._id === _new) {
        this.reloadState()
      }
    }

  },
  methods: {
    selectPhase: function (id) {
      this.$emit('phase-selected', id)
    },
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2', 'fetchAILabelledImages']),
    ...mapActions('usersSubmission', {
      getMembers: 'getMembers'
    }),
    ...mapActions('projectSubmission', ['getProjectImages2', 'getRejections2', 'getAllImages2', 'getProjectStats', 'getProjectDetailedStats']),
    ...mapActions('staffSubmission', {
      getPMSCount: 'getCount',
      getPMSImages: 'getPneumonia'
    }),
    formatDate: function (date) {
      return moment(date).fromNow()
    },
    formatFullDate: function (date) {
      return moment(date).format('D MMM YYYY, HH:mm')
    },
    getConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return ''
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return ''
      } else if (name.indexOf('other') > -1) {
        return ''
      } else {
        return ''
      }
    },
    getIcon: function (name) {
      if (name.indexOf('high') > -1) {
        return 'circle'
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return 'circle'
      } else {
        return 'heartbeat'
      }
    },
    getIndicator: function (name) {
      if (name.indexOf('high') > -1) {
        return 'high'
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return 'high'
      } else {
        return 'nothing'
      }
    },
    boundingBoxes: function (result) {
      if (result) {
        if (result.attachments[0].response.bounding.path.indexOf('http') === -1) {
          return apiRoute() + result.attachments[0].response.bounding.path
        } else {
          return result.attachments[0].response.bounding.path
        }
      } else {
        return null
      }
    },
    getStandardDeviation: function (array) {
      const n = array.length
      const mean = array.reduce((a, b) => a + b) / n
      return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
    },
    getAllSubmissions: function () {
      this.getPMSImages({ period: this.period, page: this.period === 'older' ? this.page : 1, project: this.curProject })
    },
    reloadState () {
      if (!this.list[0].enable_training) {
        this.phases.retrospective = this.phases.retrospective.filter(ph => ph.id !== 12)
      }
      this.getMembers(this.list[0]._id)

      if (this.studyType === 'prospective') {
        this.pmsLoading = true
        this.tempPmsImages = []

        this.$emit('clear-pms-images')
        this.$emit('set-pms-image-count', 0)
        this.$emit('set-period', 'count')
        this.$emit('set-page', 1)

        this.$nextTick(() => {
          this.getPMSCount({ project: this.curProject })
        })
      } else {
        this.fetchLabelledImages2(this.list[0]._id)
        this.getProjectImages2(this.list[0]._id)
        // // console.log('pul')
        this.fetchAILabelledImages(this.list[0]._id)
        this.getRejections2(this.list[0]._id)
        this.getAllImages2(this.list[0]._id)
      }

      this.getProjectStats(this.list[0]._id)
      this.getProjectDetailedStats(this.list[0]._id)

      this.rerender++
    },
    formatTime: function (seconds, includeMs = true) {
      if (seconds < 60) {
        if (includeMs && seconds < 1) {
          return `${Math.round(seconds * 1000)} ms`
        }
        return `${seconds.toFixed(includeMs ? 3 : 0)} sec`
      } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60)
        const remainingSeconds = seconds % 60
        return `${minutes}m ${remainingSeconds.toFixed(includeMs ? 1 : 0)}s`
      } else {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)
        const remainingSeconds = seconds % 60
        return `${hours}h ${minutes}m ${remainingSeconds.toFixed(0)}s`
      }
    }
  },
  mounted () {
    if (this.list[0] && this.curProject === this.list[0]._id) {
      this.reloadState()
    }
  }
}
</script>
