<template>
  <div ref="footerLock">
    <b-col cols="12">
      <b-row>
        <b-col cols="8" align-self="baseline" class="pr-2">
          <b-overlay :show="true" rounded="sm" shown="true" opacity="0.0" no-center class="boundingboxs">
            <img ref="annotatedImage" @load="imgLoaded" :src="getImageUrl(selectedImage)" class="img-fluid"
              :style="'width: 100%; filter: brightness(' + brightness + '%) contrast(' + contrast + '%) saturate(' + saturate + '%) sepia(' + sepia + '%) hue-rotate(' + hue + 'deg) invert(' + invert + '%);'" />
            <template #overlay>
              <canvas class="img fluid img-large boundingboxs" ref="canvas" :width="canvasWidth" :height="canvasHeight" style="width: 100%; height: 100%" />
            </template>
          </b-overlay>
        </b-col>
        <b-col cols="4" class="img_details">
          <b-row>
            <b-col align-self="stretch">
              <div class="enhance-form px-3 pt-3 pb-1 mb-1">
                <b-button class="btn btn-yellow rounded-pill float-right" @click="closeBottom()">
                  <font-awesome-icon class="fa color-navy" icon="times" />
                </b-button>
                <b-button pill class="btn-blue mr-2 float-right" v-if="!fullscreenprop" @click="fullscreen()"> <font-awesome-icon icon="expand"  /></b-button>
                <h5 class="color-white">Image Details </h5>
                <p class="mb-0">Created on: {{selectedImage.submission.created_at | moment('YYYY-MM-DD HH:mm') }}</p>
                <p class="mb-0">Identifier / Name: {{ imgName(selectedImage) }}</p>
                <!-- <p v-if="isImageSet">Sequence: {{ getImage(selectedImage).image_set.sequence }}</p> -->
                <p class="mb-0" v-if="detailsChache.iou !== null && detailsChache.iou >= 0">Image / Slice IOV: {{ (Math.round(detailsChache.iou * 10000) / 100).toFixed(2) }}%</p>
                <p class="mb-0" v-if="detailsChache.aveIou !== null && detailsChache.aveIou >= 0">Study IOV: {{ (Math.round(detailsChache.aveIou * 10000) / 100).toFixed(2) }}%</p>
                <p class="mb-0" v-if="detailsChache.aiIov !== null && detailsChache.aiIov >= 0">AI Accuracy: {{ (Math.round(detailsChache.aiIov * 10000) / 100).toFixed(2) }}%</p>
                <p class="mt-2">
                  <a href="#" @click.prevent="metadataOpen = !metadataOpen">
                    <font-awesome-icon icon="chevron-right" class="mr-2 color-white" v-if="!metadataOpen" />
                    <font-awesome-icon icon="chevron-down" class="mr-2 color-white" v-else />
                    <strong class="color-white">Metadata</strong>
                  </a>
                </p>
                <div class="mb-3" v-if="metadataOpen">
                  <div v-for="(md, index) in detailsChache.meta" :key="index">
                    <div v-for="_md in md.values" :key="_md._id">
                      <p class="mb-0">{{_md.name +" : " +_md.value}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="enhance-form px-3 pt-3 pb-1 mb-1" v-if="selectedImage.report">
                <h5 class="color-white">Report </h5>
                <p class="mb-0">Created on: {{ selectedImage.report.created_at | moment('YYYY-MM-DD HH:mm') }}</p>
                <p class="mb-0">Created by: {{ selectedImage.report.reporter.investigatorCode }}</p>
                <p class="mb-0">Result type: {{ selectedImage.report.resultType }}</p>
                <p class="mb-0">Status: {{ selectedImage.report.status }}</p>
                <p class="mt-2">
                  <a href="#" @click.prevent="reportOpen = !reportOpen">
                    <font-awesome-icon icon="chevron-right" class="mr-2 color-white" v-if="!reportOpen" />
                    <font-awesome-icon icon="chevron-down" class="mr-2 color-white" v-else />
                    <strong class="color-white">Details</strong>
                  </a>
                </p>
                <div class="mb-3" v-if="reportOpen">
                  <b-textarea :disabled="true" rows="15" :value="selectedImage.report.conclusion" style="font-size: 90%"></b-textarea>
                </div>
              </div>
              <div class="enhance-form px-3 pt-3 pb-1 mb-1" v-if="selectedImage.submission && phaseData >= 13">
                <b-button class="btn btn-yellow rounded-pill float-right px-2" @click="showAiPathologies = !showAiPathologies">
                  <font-awesome-icon class="fa color-white" icon="eye-slash" v-if="showAiPathologies" />
                  <font-awesome-icon class="fa color-white" icon="eye" v-else />
                </b-button>
                <h5 class="color-white">AI Analysis </h5>
                <p class="mb-0">Created on: {{ selectedImage.submission.created_at | moment('YYYY-MM-DD HH:mm') }}</p>
                <p class="mb-0">Status: {{ selectedImage.submission.status }}</p>
                <p class="mt-2">
                  <a href="#" @click.prevent="aiAnalysisOpen = !aiAnalysisOpen">
                    <font-awesome-icon icon="chevron-right" class="mr-2 color-white" v-if="!aiAnalysisOpen" />
                    <font-awesome-icon icon="chevron-down" class="mr-2 color-white" v-else />
                    <strong class="color-white">Details</strong>
                  </a>
                </p>
                <div class="mb-3" v-if="aiAnalysisOpen">
                  <div v-for="pathology in aiPathologies.filter(p => p.ai.predicted_diagnosis !== 'Cardio' && p.ai.predicted_diagnosis !== 'Thoracic')" :key="selectedImage.submission._id + '_' + pathology.ai._id">
                    <b-row class="mb-1">
                      <b-col cols="1">
                        <font-awesome-icon icon="circle" class="font-size-16" :style="{ color: pathology.project && pathology.project.color ? pathology.project.color :'' }"></font-awesome-icon>
                      </b-col>
                      <b-col cols="2">{{ (pathology.ai.confidence * 100).toFixed(2) }}%</b-col>
                      <b-col class="pl-1">{{ pathology.project ? pathology.project.label : pathology.ai.predicted_diagnosis }}</b-col>
                    </b-row>
                  </div>
                  <h6 class="color-white mt-3">Conclusion </h6>
                  <p class="line-height-125">{{ selectedImage.analysis.conclusion }}</p>
                </div>
              </div>
              <div class="enhance-form px-3 pt-3 pb-1 mb-1" v-if="selectedImage.reportAnalysis && selectedImage.reportAnalysis.status === 'Completed' && phaseData >= 13">
                <h5 class="color-white">AI vs Report Analysis </h5>
                <p class="mb-0">Created on: {{ selectedImage.reportAnalysis.created_at | moment('YYYY-MM-DD HH:mm') }}</p>
                <p class="mb-0">AI / Radiology Report Variability: {{ ((1 - selectedImage.reportAnalysis.accuracy) * 100).toFixed(2) }}%</p>
                <p class="mt-2">
                  <a href="#" @click.prevent="reportAnalysisOpen = !reportAnalysisOpen">
                    <font-awesome-icon icon="chevron-right" class="mr-2 color-white" v-if="!reportAnalysisOpen" />
                    <font-awesome-icon icon="chevron-down" class="mr-2 color-white" v-else />
                    <strong class="color-white">Details</strong>
                  </a>
                </p>
                <div class="mb-3" v-if="reportAnalysisOpen">
                  <div v-for="pathology in selectedImage.reportAnalysis.aiPathologies" :key="selectedImage.reportAnalysis._id + '_' + pathology._id">
                    <b-row class="mb-1">
                      <b-col cols="1">
                        <font-awesome-icon icon="check-circle" class="color-yellow font-size-16" v-if="pathology.confirmed"></font-awesome-icon>
                        <font-awesome-icon icon="dot-circle" class="color-yellow font-size-16" v-else-if="pathology.partiallyConfirmed"></font-awesome-icon>
                        <font-awesome-icon icon="times-circle" class="color-white font-size-16" v-else></font-awesome-icon>
                      </b-col>
                      <b-col class="pl-1">{{ pathology.name }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" class="line-height-1">
                        <small>{{ pathology.notes }}</small>
                      </b-col>
                    </b-row>
                  </div>
                  <div v-for="pathology in selectedImage.reportAnalysis.falseNegatives.filter(fn => fn.confirmed || fn.partiallyConfirmed)" :key="selectedImage.reportAnalysis._id + '_' + pathology._id">
                    <b-row class="mb-1">
                      <b-col cols="1">
                        <font-awesome-icon icon="times-circle" class="color-red font-size-16" v-if="pathology.confirmed"></font-awesome-icon>
                        <font-awesome-icon icon="dot-circle" class="color-red font-size-16" v-else-if="pathology.partiallyConfirmed"></font-awesome-icon>
                        <font-awesome-icon icon="check-circle" class="color-white font-size-16" v-else></font-awesome-icon>
                      </b-col>
                      <b-col class="pl-1">{{ pathology.name }}</b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" class="line-height-1">
                        <small>{{ pathology.notes }}</small>
                      </b-col>
                    </b-row>
                  </div>
                  <div v-if="selectedImage.reportAnalysis.aiDiagnosis">
                    <h6 class="color-white">
                      <font-awesome-icon icon="check-circle" class="color-yellow font-size-16 mr-2" v-if="selectedImage.reportAnalysis.aiDiagnosis.confirmed"></font-awesome-icon>
                      <font-awesome-icon icon="dot-circle" class="color-yellow font-size-16 mr-2" v-else-if="selectedImage.reportAnalysis.aiDiagnosis.partiallyConfirmed"></font-awesome-icon>
                      <font-awesome-icon icon="times-circle" class="color-red font-size-16 mr-2" v-else></font-awesome-icon>
                      Diagnosis
                    </h6>
                    <p class="line-height-125">{{ selectedImage.reportAnalysis.aiDiagnosis.notes }}</p>
                  </div>
                  <h6 class="color-white">Conclusion </h6>
                  <p class="line-height-125">{{ selectedImage.reportAnalysis.conclusion }}</p>
                </div>
              </div>
              <div class="enhance-form px-3 pt-3 pb-1 mb-1" v-if="selectedImage.submission && selectedImage.submission.attachments[0] && selectedImage.submission.attachments[0].feedback && phaseData >= 14">
                <b-button class="btn btn-yellow rounded-pill float-right px-2" @click="showFeedbackPathologies = !showFeedbackPathologies">
                  <font-awesome-icon class="fa color-white" icon="eye-slash" v-if="showFeedbackPathologies" />
                  <font-awesome-icon class="fa color-white" icon="eye" v-else />
                </b-button>
                <h5 class="color-white">AI Moderation / Feedback </h5>
                <p class="mb-0">Created on: {{ selectedImage.submission.attachments[0].feedback.created_at | moment('YYYY-MM-DD HH:mm') }}</p>
                <p class="mb-0">Moderated by: {{ selectedImage.submission.attachments[0].feedback.created_by.personal.fullname }}</p>
                <p class="mb-0">AI / Moderation Variability: {{ ((1 - aiFeedbackAccuarcy) * 100).toFixed(2) }}%</p>
                <p class="mt-2">
                  <a href="#" @click.prevent="moderationOpen = !moderationOpen">
                    <font-awesome-icon icon="chevron-right" class="mr-2 color-white" v-if="!moderationOpen" />
                    <font-awesome-icon icon="chevron-down" class="mr-2 color-white" v-else />
                    <strong class="color-white">Details</strong>
                  </a>
                </p>
                <div class="mb-3" v-if="moderationOpen">
                  <div v-for="pathology in feedbackPathologies.ai.filter(p => p.predicted_diagnosis !== 'Thoracic' && p.predicted_diagnosis !== 'Cardio')" :key="selectedImage.submission.attachments[0].feedback._id + '_' + pathology._id">
                    <b-row class="mb-1">
                      <b-col cols="1">
                        <font-awesome-icon icon="check-circle" class="color-green font-size-16" v-if="pathology.feedback"></font-awesome-icon>
                        <font-awesome-icon icon="times-circle" class="color-red font-size-16" v-else></font-awesome-icon>
                      </b-col>
                      <b-col class="pl-1">{{ formatPathologyName(pathology.predicted_diagnosis) }}</b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <!-- v-if="[1, 2, 3, 4, 10, 11].includes(selected) -->
              <b-form class="enhance-form px-3 pt-3 pb-1">
                <h6 class="color-white mb-3">Enhance image</h6>
                <b-form-group class="mb-0">
                  <b-input-group>
                    <template v-slot:prepend>
                      <b-input-group-text class="bg-transparent border-0">
                            <font-awesome-icon class="fa color-yellow"  icon="sun"  />
                      </b-input-group-text>
                    </template>
                    <b-form-input v-model="brightness" type="range" min="0" max="200"
                      class="bg-transparent border-0 outline-removed"></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group class="mb-0">
                  <b-input-group>
                    <template v-slot:prepend>
                      <b-input-group-text class="bg-transparent border-0">
                            <font-awesome-icon class="fa color-yellow"  icon="adjust"  />
                      </b-input-group-text>
                    </template>
                    <b-form-input v-model="contrast" type="range" min="0" max="200"
                      class="bg-transparent border-0 outline-removed"></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group class="mb-0">
                  <b-input-group>
                    <template v-slot:prepend>
                      <b-input-group-text class="bg-transparent border-0">
                            <font-awesome-icon class="fa color-yellow"  icon="tint"  />
                      </b-input-group-text>
                    </template>
                    <b-form-input v-model="saturate" type="range" min="0" max="200"
                      class="bg-transparent border-0 outline-removed"></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group class="mb-0">
                  <b-input-group>
                    <template v-slot:prepend>
                      <b-input-group-text class="bg-transparent border-0">
                            <font-awesome-icon class="fa color-yellow"  icon="camera"  />
                      </b-input-group-text>
                    </template>
                    <b-form-input v-model="sepia" type="range" min="0" max="100"
                      class="bg-transparent border-0 outline-removed"></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group>
                  <b-input-group>
                    <template v-slot:prepend>
                      <b-input-group-text class="bg-transparent border-0">
                            <font-awesome-icon class="fa color-yellow"  icon="fill"  />
                      </b-input-group-text>
                    </template>
                    <b-form-input v-model="invert" type="range" min="0" max="100"
                      class="bg-transparent border-0 outline-removed"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-form>
              <div class="mt-1" v-if="(current_role === 'Administrator' ||  current_role === 'Data Scientist')">
                <b-form class="enhance-form px-3 pt-3 pb-1">
                  <h6 class="color-white mb-3">Reject image</h6>
                  <b-row class="px-3 mb-3">
                    <b-input v-model="reason" placeholder="Reject Image Reason"
                    :state="reason!==''"
                    ></b-input>
                  </b-row>
                  <b-row class="px-3 mb-2" align-h="around">
                    <b-button pill class="btn-yellow" :disabled="reason===''" @click=rejectImage()>Reject</b-button>
                    <b-button pill class="btn-blue" @click="reason=''">Cancel</b-button>
                  </b-row>
                </b-form>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { apiRoute } from '../../../../helpers'

export default {
  props: {
    fullscreenprop: Boolean,
    clientWidth: Number
  },
  data () {
    return {
      // filter: brightness(' + brightness + '%) contrast(' + contrast + '%) saturate(' + saturate + '%) sepia(' + sepia + '%) hue-rotate(' + hue + 'deg) invert(' + invert + '%);'
      currentImageIndex: 0,
      canvasWidth: 0,
      canvasHeight: 0,
      ratio: 1,
      brightness: 100,
      contrast: 100,
      saturate: 100,
      sepia: 0,
      invert: 0,
      hue: 0,
      reason: '',
      rejecting: false,
      userChache: [],
      detailsChache: [],
      imageHeight: 100,
      windowOpt: [],
      selectedWindow: '',
      selectedWindowDisplayed: '',
      metadataOpen: false,
      reportOpen: false,
      aiAnalysisOpen: false,
      reportAnalysisOpen: false,
      moderationOpen: false,
      showAiPathologies: true,
      showFeedbackPathologies: false,
      showTogglePressed: false,
      statsOpen: {}
    }
  },
  components: {},
  computed: {
    ...mapState('BottomSheetPms', {
      selectedImage: (state) => state.image,
      phaseData: (state) => state.phase,
      currentProject: (state) => state.project
    }),
    ...mapState('usersSubmission', ['loading', 'members']),
    ...mapState('auth', ['user']),
    current_role () {
      const currentMem = this.members.find(p => p._id === this.user.id)
      // // console.log('member restrict', currentMem)
      if (currentMem) {
        // console.log('member restrict', currentMem, this.phaseData.projectId)
        const currentPro = currentMem.projectRole.find(p => p.project === this.phaseData.projectId)
        if (currentPro) {
          // console.log('member restrict', currentPro.role)
          return currentPro.role
        } else {
          // console.log('member restrict', currentPro)
          return 'Administrator'
        }
      } else {
        return 'View Only'
      }
    },
    isImageSet () {
      return this.image && this.image.length >= 3 && this.image[2].image_set && this.image[2].image_set.name && this.image[2].otherImages && this.image[2].otherImages.length > 0
    },
    getRoute () {
      return apiRoute()
    },
    imageChange () {
      return this.selectedImage
    },
    imageIndex () {
      return this.currentImageIndex
    },
    setCols () {
      if (this.clientWidth >= 1800) {
        return 1
      } else {
        return 2
      }
    },
    windowChange () {
      return this.selectedWindow
    },
    aiPathologies () {
      if (this.selectedImage && this.selectedImage.submission && this.selectedImage.submission.attachments && this.selectedImage.submission.attachments.length > 0) {
        const _response = this.selectedImage.submission.attachments[0].response
        if (_response && _response.labels && _response.labels.length > 0) {
          const _pathologies = []
          _response.labels[0].pathologies.forEach(pathology => {
            const _updated = this.pathologyList.find(_pathology => {
              const __pathology = _pathology.label.replace(/[^a-zA-Z]/g, '').toLowerCase()
              return __pathology === pathology.predicted_diagnosis.toLowerCase()
            })

            if (_updated) {
              if (!_updated.color) {
                _updated.color = '#012a32'
              }
              _pathologies.push({
                ai: pathology,
                project: _updated
              })
            } else {
              _pathologies.push({
                ai: pathology,
                project: null
              })
            }
          })
          return _pathologies
        } else {
          return []
        }
      } else {
        return []
      }
    },
    pathologyList () {
      if (this.currentProject && this.currentProject.pathologies && this.currentProject.pathologies.length > 0) {
        return this.currentProject.pathologies
      } else {
        return []
      }
    },
    feedbackPathologies () {
      const submission = this.selectedImage.submission

      if (submission && submission.attachments && submission.attachments[0] && submission.attachments[0].feedback) {
        const _aiPathologies = submission.attachments[0].response.labels[0].pathologies
        const _feedbackPathologies = submission.attachments[0].feedback.labels[0].pathologies

        const _pathologies = []
        _aiPathologies.forEach(p => {
          const _feedback = _feedbackPathologies.find(f => f.pathology_id === p._id)
          const _copy = JSON.parse(JSON.stringify(p))
          _copy.feedback = _feedback ? _feedback.concordance : undefined
          _pathologies.push(_copy)
        })

        const _other = []
        return {
          ai: _pathologies,
          other: _other
        }
      } else {
        return {
          ai: [],
          other: []
        }
      }
    },
    aiFeedbackAccuarcy () {
      if (this.feedbackPathologies.ai.length > 0) {
        const _correct = this.feedbackPathologies.ai.filter(p => p.feedback && p.predicted_diagnosis !== 'Thoracic' && p.predicted_diagnosis !== 'Cardio')
        return _correct.length / this.feedbackPathologies.ai.filter(p => p.predicted_diagnosis !== 'Thoracic' && p.predicted_diagnosis !== 'Cardio').length
      } else {
        return null
      }
    }
  },
  watch: {
    showAiPathologies () {
      if (this.showFeedbackPathologies && !this.showTogglePressed) {
        this.showTogglePressed = true
        this.showFeedbackPathologies = false
      }

      this.drawImageAndBoxes()
      this.$nextTick(() => {
        this.showTogglePressed = false
      })
    },
    showFeedbackPathologies () {
      if (this.showAiPathologies && !this.showTogglePressed) {
        this.showTogglePressed = true
        this.showAiPathologies = false
      }

      this.drawImageAndBoxes()

      this.$nextTick(() => {
        this.showTogglePressed = false
      })
    }
  },
  methods: {
    ...mapActions('projectSubmission', ['removeImageFromProject']),
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2']),
    ...mapActions('BottomSheet', {
      bsclear: 'clear'
    }),
    keyScrollImg: function (event) {
      // event.preventDefault()
      // document.activeElement.blur()
      document.getElementById('image-scroll-bar').blur()
      if (this.imgSet) {
        const _index = this.getSelectedIndex(this.selectedImage)
        if (event.which === 38) {
          if (_index > 0) {
            this.selectedImage = this.imgSet[_index - 1].imgId
            this.currentImageIndex = this.getSelectedIndex(this.selectedImage)
          }
        } else if (event.which === 40) {
          // // console.log(this.getSelectedIndex(this.selectedImage) + 1)
          if (_index < this.imgSet.length - 1) {
            this.selectedImage = this.imgSet[_index + 1].imgId
            this.currentImageIndex = this.getSelectedIndex(this.selectedImage)
          }
        }
      }
    },
    preventKeyBoardScroll: function (event) {
      // console.log('prevent')
      var keys = [33, 34, 35, 37, 38, 39, 40]
      if (keys.includes(event.keyCode)) {
        // console.log('prevent1')
        event.preventDefault()
        return false
      }
    },
    getSelectedIndex (id) {
      // console.log(id)
      return this.imgSet.findIndex(s => s.imgId === id)
    },
    getIdentifier: function (id) {
      const _image = this.getImage(id)
      if (_image) {
        // // console.log('image', _image)
        if (this.phaseData.phase === 1) {
          return _image.details.image_set.identifier
        } else {
          return _image.details.image_set.identifier
        }
      } else {
        return 'Unknown'
      }
    },
    getImage: function (id) {
      const _others = this.imgSet
      var _other = []
      if (_others) {
        _other = _others.find(_image => _image.imgId === id)
      } else {
        _other = { imgId: this.phaseData.imageID }
      }
      if (_other) {
        // // console.log('other', _other)
        return _other
      } else {
        return null
      }
    },
    getImageUrl: function (img) {
      if (img.submission && img.submission.attachments && img.submission.attachments.length > 0) {
        if (img.submission.attachments[0].path.indexOf('http') < 0) {
          return apiRoute() + img.submission.attachments[0].path
        } else {
          return img.submission.attachments[0].path
        }
      } else {
        return ''
      }
    },
    async rejectImage () {
      var _removals = []
      _removals.push(this.phaseData.imageID)
      // // // console.log(this.reason)
      // if (this.imgSet.length > 0) {
      //   this.imgSet.forEach(img => {
      //     _removals.push(img.imgId)
      //   })
      // }
      var payload = {
        reason: this.reason,
        p_id: this.phaseData.projectId,
        removalIds: _removals,
        phase: this.phaseData.phase
      }
      // // console.log(payload)
      await this.removeImageFromProject(payload)
      this.fetchLabelledImages2(this.phaseData.projectId)
      this.bsclear()
    },
    toggleUserBoundingBoxes (user) {
      user.showBoundingBoxes = !user.showBoundingBoxes
      this.drawImageAndBoxes()
    },
    toggleBoundingBox (boundingBox) {
      boundingBox.showBoundingBox = !boundingBox.showBoundingBox
      this.drawImageAndBoxes()
    },
    toggleStats (key) {
      this.$set(this.statsOpen, key, !this.statsOpen[key])
    },
    formatPathologyName (pathology) {
      const _updated = this.pathologyList.map(p => p.label).find(_pathology => {
        const __pathology = _pathology.replace(/[^a-zA-Z]/g, '').toLowerCase()
        return __pathology === pathology.toLowerCase()
      })

      if (_updated) {
        return _updated
      } else {
        return pathology
      }
    },
    drawImageAndBoxes () {
      const bbColours = ['#013220', '#012a32', '#012144', '#001956', '#001167', '#000879', '#00008b', '#2a0c74', '#55175d', '#802246', '#aa2e2e', '#d43917', '#ff4500', '#e13916', '#c32e2b', '#a52241', '#871757', '#690c6c', '#4b0082']

      const canvas = this.$refs.canvas
      const ctx = canvas.getContext('2d')
      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      var _title

      if (this.aiPathologies && this.showAiPathologies) {
        this.aiPathologies.forEach(pathology => {
          const _colour = pathology.project ? pathology.project.color : bbColours.shift()
          const _name = pathology.project ? pathology.project.label : pathology.ai.predicted_diagnosis
          var _pathology = _name.toUpperCase()
          if (_name.toLowerCase() === 'cardio' || _name.toLowerCase() === 'thoracic') {
            ctx.shadowOffsetX = 2
            ctx.shadowOffsetY = 2
            ctx.shadowBlur = 4
            ctx.shadowColor = _colour
            ctx.fillStyle = ctx.strokeStyle = _colour
            ctx.fillStyle = _colour

            ctx.lineWidth = 2
            const fontSize = Math.floor(this.canvasWidth * 0.01367)
            ctx.font = fontSize + 'px "Lato"'
            _title = ctx.measureText(_pathology)

            ctx.beginPath()
            ctx.moveTo(pathology.ai.bounding_box.x * this.ratio, pathology.ai.bounding_box.y * this.ratio + _title.fontBoundingBoxAscent + _title.fontBoundingBoxDescent)
            ctx.lineTo(pathology.ai.bounding_box.x * this.ratio, pathology.ai.bounding_box.y * this.ratio - _title.fontBoundingBoxDescent)
            ctx.moveTo(pathology.ai.bounding_box.x * this.ratio + pathology.ai.bounding_box.w * this.ratio, pathology.ai.bounding_box.y * this.ratio + _title.fontBoundingBoxAscent + _title.fontBoundingBoxDescent)
            ctx.lineTo(pathology.ai.bounding_box.x * this.ratio + pathology.ai.bounding_box.w * this.ratio, pathology.ai.bounding_box.y * this.ratio - _title.fontBoundingBoxDescent)
            ctx.moveTo(pathology.ai.bounding_box.x * this.ratio, pathology.ai.bounding_box.y * this.ratio)
            ctx.lineTo(pathology.ai.bounding_box.x * this.ratio + pathology.ai.bounding_box.w * this.ratio, pathology.ai.bounding_box.y * this.ratio)
            ctx.stroke()

            ctx.shadowOffsetX = 0
            ctx.shadowOffsetY = 0
            ctx.shadowBlur = 0

            ctx.fillStyle = ctx.strokeStyle = _colour
            if (_pathology.toLowerCase() === 'cardio') {
              const _newPathology = _pathology + ', CTR: ' + (pathology.ai.confidence * 100).toFixed(2) + '%'
              ctx.fillStyle = ctx.strokeStyle = _colour
              ctx.fillText(_newPathology,
                pathology.ai.bounding_box.x * this.ratio + 5,
                pathology.ai.bounding_box.y * this.ratio + _title.fontBoundingBoxAscent + 1,
                pathology.ai.bounding_box.w * this.ratio)
            } else if (_pathology.toLowerCase() === 'thoracic') {
              const _newPathology = _pathology
              ctx.fillStyle = ctx.strokeStyle = _colour
              ctx.fillText(_newPathology,
                pathology.ai.bounding_box.x * this.ratio + 5,
                pathology.ai.bounding_box.y * this.ratio + _title.fontBoundingBoxAscent + 1,
                pathology.ai.bounding_box.w * this.ratio)
            } else {
              ctx.fillText(_pathology, pathology.ai.bounding_box.x * this.ratio + 5,
                pathology.ai.bounding_box.y * this.ratio + _title.fontBoundingBoxAscent - 1,
                pathology.ai.bounding_box.w * this.ratio)
            }
          } else {
            if (pathology.ai.polygon && pathology.ai.polygon.length > 1) {
              let minPoint = { x: this.canvasWidth, y: this.canvasHeight }
              ctx.lineWidth = 2
              ctx.strokeStyle = pathology.project.color
              ctx.fillStyle = pathology.project.color + '33'
              // console.log('AI colours', ctx.strokeStyle, ctx.fillStyle)
              ctx.beginPath()
              ctx.moveTo(pathology.ai.polygon[0].x * this.ratio, pathology.ai.polygon[0].y * this.ratio)
              for (let j = 1; j < pathology.ai.polygon.length; j++) {
                ctx.lineTo(pathology.ai.polygon[j].x * this.ratio, pathology.ai.polygon[j].y * this.ratio)
                if (pathology.ai.polygon[j].x * this.ratio < minPoint.x) {
                  minPoint = {
                    x: pathology.ai.polygon[j].x * this.ratio,
                    y: pathology.ai.polygon[j].y * this.ratio
                  }
                }
              }
              ctx.closePath()
              ctx.stroke()
              ctx.fill()

              const fontSize = Math.floor(this.canvasWidth * 0.009) // 0.01367)
              ctx.font = fontSize + 'px "Lato"'
              _title = ctx.measureText(_name)

              ctx.shadowOffsetX = 0
              ctx.shadowOffsetY = 0
              ctx.shadowBlur = 0

              const offsetX = _title.width // Math.floor(Math.random() * (this.canvasWidth / 6 - 15)) + 15
              const offsetY = 20 * this.ratio // Math.floor(Math.random() * (boundingBox.coordinates.h / 2 + 14)) + 15
              const startingX = pathology.ai.window ? (pathology.ai.window.x * this.ratio) : minPoint.x // boundingBox.coordinates.x * this.ratio + (boundingBox.coordinates.w * this.ratio / 2)
              const startingY = pathology.ai.window ? ((pathology.ai.window.y + pathology.ai.window.h / 2) * this.ratio) : minPoint.y // boundingBox.coordinates.y * this.ratio + (boundingBox.coordinates.h * this.ratio / 2)
              let endingX = startingX - offsetX
              if (endingX < _title.width) {
                endingX = startingX + offsetX
              }
              let endingY = startingY - offsetY
              if (endingY < _title.fontBoundingBoxAscent) {
                endingY = startingY + offsetY
              }
              ctx.moveTo(startingX, startingY)
              ctx.lineTo(endingX, endingY)
              ctx.closePath()
              ctx.stroke()
              ctx.fillStyle = ctx.strokeStyle = pathology.project.color // '#ffffff'
              ctx.fillText(_name, endingX, endingY)
            } else {
              ctx.lineWidth = 2
              ctx.strokeStyle = _colour
              ctx.strokeRect(pathology.ai.bounding_box.x * this.ratio, pathology.ai.bounding_box.y * this.ratio, pathology.ai.bounding_box.w * this.ratio, pathology.ai.bounding_box.h * this.ratio)
              const fontSize = Math.floor(this.canvasWidth * 0.01367)
              ctx.font = fontSize + 'px "Lato"'
              _title = ctx.measureText(_name)
              ctx.fillStyle = _colour
              ctx.fillRect(pathology.ai.bounding_box.x * this.ratio, pathology.ai.bounding_box.y * this.ratio, pathology.ai.bounding_box.w * this.ratio, _title.fontBoundingBoxAscent + _title.fontBoundingBoxDescent)
              ctx.fillStyle = ctx.strokeStyle = '#ffffff'
              // ctx.strokeStyle = '#ffffff'
              ctx.fillText(_name, pathology.ai.bounding_box.x * this.ratio, pathology.ai.bounding_box.y * this.ratio + _title.fontBoundingBoxAscent - 1, pathology.ai.bounding_box.w * this.ratio)
            }
          }
        })
      } else if (this.feedbackPathologies.ai.length > 0 && this.showFeedbackPathologies) {
        this.feedbackPathologies.ai.filter(p => p.feedback && p.predicted_diagnosis !== 'Thoracic' && p.predicted_diagnosis !== 'Cardio').forEach(pathology => {
          const _colour = pathology.project ? pathology.project.color : bbColours.shift()
          const _name = this.formatPathologyName(pathology.predicted_diagnosis)
          var _pathology = _name.toUpperCase()
          if (_name.toLowerCase() === 'cardio' || _name.toLowerCase() === 'thoracic') {
            ctx.shadowOffsetX = 2
            ctx.shadowOffsetY = 2
            ctx.shadowBlur = 4
            ctx.shadowColor = _colour
            ctx.fillStyle = ctx.strokeStyle = _colour
            ctx.fillStyle = _colour

            ctx.lineWidth = 2
            const fontSize = Math.floor(this.canvasWidth * 0.01367)
            ctx.font = fontSize + 'px "Lato"'
            _title = ctx.measureText(_pathology)

            ctx.beginPath()
            ctx.moveTo(pathology.ai.bounding_box.x * this.ratio, pathology.ai.bounding_box.y * this.ratio + _title.fontBoundingBoxAscent + _title.fontBoundingBoxDescent)
            ctx.lineTo(pathology.ai.bounding_box.x * this.ratio, pathology.ai.bounding_box.y * this.ratio - _title.fontBoundingBoxDescent)
            ctx.moveTo(pathology.ai.bounding_box.x * this.ratio + pathology.ai.bounding_box.w * this.ratio, pathology.ai.bounding_box.y * this.ratio + _title.fontBoundingBoxAscent + _title.fontBoundingBoxDescent)
            ctx.lineTo(pathology.ai.bounding_box.x * this.ratio + pathology.ai.bounding_box.w * this.ratio, pathology.ai.bounding_box.y * this.ratio - _title.fontBoundingBoxDescent)
            ctx.moveTo(pathology.ai.bounding_box.x * this.ratio, pathology.ai.bounding_box.y * this.ratio)
            ctx.lineTo(pathology.ai.bounding_box.x * this.ratio + pathology.ai.bounding_box.w * this.ratio, pathology.ai.bounding_box.y * this.ratio)
            ctx.stroke()

            ctx.shadowOffsetX = 0
            ctx.shadowOffsetY = 0
            ctx.shadowBlur = 0

            ctx.fillStyle = ctx.strokeStyle = _colour
            if (_pathology.toLowerCase() === 'cardio') {
              const _newPathology = _pathology + ', CTR: ' + (pathology.ai.confidence * 100).toFixed(2) + '%'
              ctx.fillStyle = ctx.strokeStyle = _colour
              ctx.fillText(_newPathology,
                pathology.ai.bounding_box.x * this.ratio + 5,
                pathology.ai.bounding_box.y * this.ratio + _title.fontBoundingBoxAscent + 1,
                pathology.ai.bounding_box.w * this.ratio)
            } else if (_pathology.toLowerCase() === 'thoracic') {
              const _newPathology = _pathology
              ctx.fillStyle = ctx.strokeStyle = _colour
              ctx.fillText(_newPathology,
                pathology.ai.bounding_box.x * this.ratio + 5,
                pathology.ai.bounding_box.y * this.ratio + _title.fontBoundingBoxAscent + 1,
                pathology.ai.bounding_box.w * this.ratio)
            } else {
              ctx.fillText(_pathology, pathology.ai.bounding_box.x * this.ratio + 5,
                pathology.ai.bounding_box.y * this.ratio + _title.fontBoundingBoxAscent - 1,
                pathology.ai.bounding_box.w * this.ratio)
            }
          } else {
            if (pathology.polygon && pathology.polygon.length > 1) {
              let minPoint = { x: this.canvasWidth, y: this.canvasHeight }
              ctx.lineWidth = 2
              ctx.strokeStyle = pathology.project.color
              ctx.fillStyle = pathology.project.color + '33'
              // console.log('AI colours', ctx.strokeStyle, ctx.fillStyle)
              ctx.beginPath()
              ctx.moveTo(pathology.polygon[0].x * this.ratio, pathology.polygon[0].y * this.ratio)
              for (let j = 1; j < pathology.polygon.length; j++) {
                ctx.lineTo(pathology.polygon[j].x * this.ratio, pathology.polygon[j].y * this.ratio)
                if (pathology.polygon[j].x * this.ratio < minPoint.x) {
                  minPoint = {
                    x: pathology.polygon[j].x * this.ratio,
                    y: pathology.polygon[j].y * this.ratio
                  }
                }
              }
              ctx.closePath()
              ctx.stroke()
              ctx.fill()

              const fontSize = Math.floor(this.canvasWidth * 0.009) // 0.01367)
              ctx.font = fontSize + 'px "Lato"'
              _title = ctx.measureText(_name)

              ctx.shadowOffsetX = 0
              ctx.shadowOffsetY = 0
              ctx.shadowBlur = 0

              const offsetX = _title.width // Math.floor(Math.random() * (this.canvasWidth / 6 - 15)) + 15
              const offsetY = 20 * this.ratio // Math.floor(Math.random() * (boundingBox.coordinates.h / 2 + 14)) + 15
              const startingX = pathology.window ? (pathology.window.x * this.ratio) : minPoint.x // boundingBox.coordinates.x * this.ratio + (boundingBox.coordinates.w * this.ratio / 2)
              const startingY = pathology.window ? ((pathology.window.y + pathology.window.h / 2) * this.ratio) : minPoint.y // boundingBox.coordinates.y * this.ratio + (boundingBox.coordinates.h * this.ratio / 2)
              let endingX = startingX - offsetX
              if (endingX < _title.width) {
                endingX = startingX + offsetX
              }
              let endingY = startingY - offsetY
              if (endingY < _title.fontBoundingBoxAscent) {
                endingY = startingY + offsetY
              }
              ctx.moveTo(startingX, startingY)
              ctx.lineTo(endingX, endingY)
              ctx.closePath()
              ctx.stroke()
              ctx.fillStyle = ctx.strokeStyle = pathology.project.color // '#ffffff'
              ctx.fillText(_name, endingX, endingY)
            } else {
              ctx.lineWidth = 2
              ctx.strokeStyle = _colour
              ctx.strokeRect(pathology.bounding_box.x * this.ratio, pathology.bounding_box.y * this.ratio, pathology.bounding_box.w * this.ratio, pathology.bounding_box.h * this.ratio)
              const fontSize = Math.floor(this.canvasWidth * 0.01367)
              ctx.font = fontSize + 'px "Lato"'
              _title = ctx.measureText(_name)
              ctx.fillStyle = _colour
              ctx.fillRect(pathology.bounding_box.x * this.ratio, pathology.bounding_box.y * this.ratio, pathology.bounding_box.w * this.ratio, _title.fontBoundingBoxAscent + _title.fontBoundingBoxDescent)
              ctx.fillStyle = ctx.strokeStyle = '#ffffff'
              // ctx.strokeStyle = '#ffffff'
              ctx.fillText(_name, pathology.bounding_box.x * this.ratio, pathology.bounding_box.y * this.ratio + _title.fontBoundingBoxAscent - 1, pathology.bounding_box.w * this.ratio)
            }
          }
        })
      }
    },
    imgLoaded () {
      if (!this.firstLoaded) {
        const _imageHeight = this.$refs.annotatedImage.height
        // console.log('Image height', _imageHeight)
        this.imageHeight = _imageHeight
      }

      this.canvasWidth = this.$refs.annotatedImage.width
      this.canvasHeight = this.$refs.annotatedImage.height
      this.ratio = this.$refs.annotatedImage.width / this.$refs.annotatedImage.naturalWidth

      this.$nextTick(() => {
        this.drawImageAndBoxes()
      })
    },
    fullscreen () {
      var url = window.location.host
      var payload = {
        phaseData: this.phaseData
      }
      var _payload = btoa(JSON.stringify(payload))
      window.open('https://' + url + '/fullscreen/?payload=' + _payload)// + '&img=' + this.image + '&phase' + this.phaseData)
    },
    closeBottom () {
      this.$emit('close-bottom')
    },
    imgName (img) {
      if (img.submission) {
        if (img.submission && img.submission.patient) {
          return img.submission.patient.identifier
        } else if (img.submission && img.submission.study_id) {
          return img.submission.study_id
        } else if (img.submission && img.submission.attachments && img.submission.attachments.length > 0) {
          return img.submission.attachments[0].name
        } else {
          return false
        }
      } else {
        return 'Unknown'
      }
    }
  }
}
</script>

<style>
img.labelled {
  border: 3px solid #28a745;
  pointer-events: none;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.2);
}

.pathology-list {
  display: "flex";
  justify-content: space-between;
  align-items: center;

  list-style: none;
  padding-left: 0;
  width: "100%";
}

.accordion-heading {
  font-weight: 600;
}

.annotator {
  border-bottom: 1;
  background-color: #10293d;
}

.scrollable2 {
  overflow-y: scroll;
  overflow-x: hidden;
}

.imageSetContainer {
  overflow-y: hidden;
  overflow-x: hidden;
}

.vranger {
  /* justify-content: start; */
  z-index: 9999;
  background-color: transparent;
  padding: 5px !important;
  position: absolute;
  top: 0px;
  right: 0.4rem;
  width: 16vw;
  height: 25px;
  /* margin-top: 55px; */
  transform: translateX(8vw) translateY(25vh) rotate(90deg);
  /* -moz-transform: translateX(30vh) rotate(90deg); */
  overflow-y: visible;
  overflow-x: visible
 }

 .vranger::-webkit-slider-runnable-track {
  background: #081624;
}
.vranger::-moz-range-track {
  background: #081624;
}
.vranger::-ms-track {
  background: #081624;
}

/* .boundingboxs .position-absolute{
    top: unset!important;
    left: unset!important;
    transform: none!important;
} */
</style>
