<template>
  <b-card no-body class="all-images mt-2 h-100 bg-dark-navy custom border-0">
    <template v-slot:header>
      <b-row class=" mb-0">
        <b-col cols="8">
          <font-awesome-icon :icon="getIcon()" size="lg" class="mr-1"></font-awesome-icon> {{ getPhase() }}
        </b-col>
        <b-col cols="4" align-self="center" class="text-right">
          <h6 class="color-white pt-0 mb-0">{{ projects.find((project) => project._id === projectId).name }}</h6>
        </b-col>
      </b-row>
    </template>
    <b-card-body class="h-100 p-0">
      <div class="px-3 py-2">
        <b-row align-h="between" class="pb-0 mb-0">
          <b-col align-self="end" cols="9">
            <h6 class="text-white ml-2 mt-2">AI Performance</h6>
          </b-col>
        </b-row>
        <hr class="mt-0 border-info" />
        <b-row>
          <b-col cols="6">
            <b-card no-body class="bg-dark-navy custom border-1 text-center">
              <b-card-header class="px-3"><strong class="text-white">AI Performance</strong></b-card-header>
              <b-row class="mt-3 mb-1">
                <b-col cols="3">
                  <b>Date</b>
                </b-col>
                <b-col class="text-left line-height-125">
                  <b>Alert / Notification Description</b>
                </b-col>
              </b-row>
              <hr class="mt-0 border-info" />
              <b-row class="mt-0">
                <b-col cols="3">
                  <font-awesome-icon icon="times-circle" class="color-red font-size-16 mr-2"></font-awesome-icon>
                  <b>2024-11-14 11:37</b>
                </b-col>
                <b-col class="text-left line-height-125">
                  AI Sensitivity / Specificity reduced to below the set minimum threshold level.
                </b-col>
              </b-row>
              <b-row class="mt-0">
                <b-col cols="3">
                  <font-awesome-icon icon="check-circle" class="color-green font-size-16 mr-2"></font-awesome-icon>
                  <b>2024-11-17 09:51</b>
                </b-col>
                <b-col class="text-left line-height-125">
                  AI Sensitivity / Specificity restored to above the set minimum threshold level.
                </b-col>
              </b-row>
              <b-row class="mt-0">
                <b-col cols="3">
                  <font-awesome-icon icon="times-circle" class="color-red font-size-16 mr-2"></font-awesome-icon>
                  <b>2024-11-18 14:09</b>
                </b-col>
                <b-col class="text-left line-height-125">
                  AI Sensitivity / Specificity reduced to below the set minimum threshold level.
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card no-body class="bg-dark-navy custom border-1 text-center">
              <b-card-header class="px-3"><strong class="text-white">AI / Radiology Report Disagreements</strong></b-card-header>
              <b-row class="mt-3 mb-1">
                <b-col cols="3">
                  <b>Date</b>
                </b-col>
                <b-col class="text-left line-height-125">
                  <b>Alert / Notification Description</b>
                </b-col>
              </b-row>
              <hr class="mt-0 border-info" />
              <b-row class="mt-0">
                <b-col cols="3">
                  <font-awesome-icon icon="times-circle" class="color-red font-size-16 mr-2"></font-awesome-icon>
                  <b>2024-11-18 14:09</b>
                </b-col>
                <b-col class="text-left line-height-125">
                  AI / Radiology Report Disagreement Rate increased above the set maximum threshold.
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row align-h="between" class="pb-0 mb-0">
          <b-col align-self="end" cols="9">
            <h6 class="text-white ml-2 mt-2">Reader Performance</h6>
          </b-col>
        </b-row>
        <hr class="mt-0 border-info" />
        <b-row>
          <b-col cols="6">
            <b-card no-body class="bg-dark-navy custom border-1 text-center">
              <b-card-header class="px-3"><strong class="text-white">Automation Bias</strong></b-card-header>
              <b-row class="mt-3 mb-1">
                <b-col cols="3">
                  <b>Date</b>
                </b-col>
                <b-col class="text-left line-height-125">
                  <b>Alert / Notification Description</b>
                </b-col>
              </b-row>
              <hr class="mt-0 border-info" />
              <b-row>
                <b-col>No Alerts / Notifications Available</b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card no-body class="bg-dark-navy custom border-1 text-center">
              <b-card-header class="px-3"><strong class="text-white">Specialist Feedback / Moderation</strong></b-card-header>
              <b-row class="mt-3 mb-1">
                <b-col cols="3">
                  <b>Date</b>
                </b-col>
                <b-col class="text-left line-height-125">
                  <b>Alert / Notification Description</b>
                </b-col>
              </b-row>
              <hr class="mt-0 border-info" />
              <b-row>
                <b-col>No Alerts / Notifications Available</b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row align-h="between" class="pb-0 mb-0">
          <b-col align-self="end" cols="9">
            <h6 class="text-white ml-2 mt-2">Technical Performance</h6>
          </b-col>
        </b-row>
        <hr class="mt-0 border-info" />
        <b-row>
          <b-col cols="6">
            <b-card no-body class="bg-dark-navy custom border-1 text-center">
              <b-card-header class="px-3"><strong class="text-white">AI Coverage</strong></b-card-header>
              <b-row class="mt-3 mb-1">
                <b-col cols="3">
                  <b>Date</b>
                </b-col>
                <b-col class="text-left line-height-125">
                  <b>Alert / Notification Description</b>
                </b-col>
              </b-row>
              <hr class="mt-0 border-info" />
              <b-row>
                <b-col>No Alerts / Notifications Available</b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card no-body class="bg-dark-navy custom border-1 text-center">
              <b-card-header class="px-3"><strong class="text-white">Technical Performance</strong></b-card-header>
              <b-row class="mt-3 mb-1">
                <b-col cols="3">
                  <b>Date</b>
                </b-col>
                <b-col class="text-left line-height-125">
                  <b>Alert / Notification Description</b>
                </b-col>
              </b-row>
              <hr class="mt-0 border-info" />
              <b-row class="mt-0">
                <b-col cols="3">
                  <font-awesome-icon icon="times-circle" class="color-red font-size-16 mr-2"></font-awesome-icon>
                  <b>2025-02-21 10:25</b>
                </b-col>
                <b-col class="text-left line-height-125">
                  AI Processing Time exceeded the set maximum threshold level.
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-card-body>
  </b-card>
</template>

<style>
.scrollable {
  overflow-y: auto;
}
</style>

<script>
import { mapState, mapActions } from 'vuex'
import { IOVThesholds } from './../../../../helpers/iovthresholds'

const mathjs = require('mathjs')

export default {
  name: 'SummaryResults',
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    selected: {
      type: Number,
      default: null
    },
    projectId: {
      type: String,
      default: null
    },
    pmsImages: {
      type: Array,
      default: () => { return [] }
    }
  },
  data () {
    return {
      filterChoice: '',
      phases: {
        retrospective: [
          { id: 10, stub: 'setup', name: 'Project Details', icon: 'sitemap' },
          { id: 5, stub: 'setup', name: 'Project set-up', icon: 'cogs' },
          { id: 1, stub: 'collection', name: 'Data collection', icon: 'photo-video' },
          { id: 2, stub: 'preprocessing', name: 'Data pre-processing', icon: 'clone' },
          { id: 3, stub: 'annotation', name: 'Data annotation', icon: 'crop' },
          { id: 4, stub: 'truth', name: 'Reference standard', icon: 'check-double' },
          { id: 6, stub: 'run', name: 'AI Processing', icon: 'hourglass-start' },
          { id: 7, stub: 'results', name: 'Detailed results', icon: 'clipboard-list' },
          { id: 8, stub: 'summary', name: 'Results summary', icon: 'chart-area' },
          { id: 9, stub: 'members', name: 'Members', icon: 'users-cog' }
        ],
        prospective: [
          { id: 10, stub: 'setup', name: 'AI Evaluation details', icon: 'sitemap' },
          { id: 5, stub: 'setup', name: 'AI Evaluation set-up', icon: 'cogs' },
          { id: 11, stub: 'collection', name: 'Data collection', icon: 'photo-video' },
          { id: 13, stub: 'feedback', name: 'AI vs Report comparison', icon: 'crop' },
          { id: 14, stub: 'moderation', name: 'Specialist feedback moderation', icon: 'check-double' },
          { id: 17, stub: 'ai-summary', name: 'AI Evaluation results', icon: 'chart-line' },
          { id: 18, stub: 'reader-summary', name: 'Reader Evaluation results', icon: 'people-arrows' },
          { id: 19, stub: 'tech-summary', name: 'Technical Evaluation results', icon: 'microchip' },
          { id: 20, stub: 'alerts', name: 'Alerts and notifications', icon: 'bell' },
          /* { id: 15, stub: 'results', name: 'Detailed results', icon: 'clipboard-list' },
          { id: 16, stub: 'summary', name: 'Results summary', icon: 'chart-area' }, */
          { id: 9, stub: 'members', name: 'Members', icon: 'users-cog' }
        ]
      },
      expand: false,
      options: {
        chart: {
          height: 300,
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '24px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '12px'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.4,
            opacityTo: 0,
            stops: [0, 60, 100]
          }
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          labels: {
            show: false
          }
        },
        yaxis: {
          min: 0,
          max: 1,
          tickAmount: 5,
          decimalsInFloat: 2,
          labels: {
            style: {
              colors: ['#ffffff'],
              fontFamily: 'Lato',
              fontSize: '10px'
            },
            formatter: function (val) { return val.toFixed(2) }
          }
        }
      },
      pieOptions: {
        chart: {
          type: 'donut'
        },
        title: {
          text: 'Product Trends by Month',
          floating: true,
          align: 'left',
          offsetY: 0,
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '20px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          floating: true,
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '10px'
          }
        },
        legend: {
          position: 'left',
          offsetY: 50,
          fontFamily: 'Lato',
          fontSize: '12px',
          labels: {
            colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
          }
        },
        dataLabels: {
          style: {
            fontFamily: 'Lato, sans-serif',
            fontSize: '8px'
          }
        },
        colors: ['#28a745', '#ffc107', '#dc3545', '#17a2b8', '#2673bc'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 100
            },
            legend: {
              position: 'top'
            }
          }
        }]
      },
      barOptions: {
        chart: {
          type: 'bar',
          height: 100,
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left',
          floating: true,
          margin: 0,
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '14px'
          }
        },
        fill: {
          opacity: 1
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          floating: true,
          offsetY: 9,
          fontFamily: 'Lato',
          fontSize: '12px',
          labels: {
            colors: '#ffffff'
          }
        }
      },
      bubbleOptions: {
        chart: {
          type: 'bubble',
          height: 320,
          toolbar: {
            show: false
          }
        },
        colors: ['#28a745', '#ffc107', '#dc3545', '#17a2b8', '#2673bc'],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          bubble: {
            zScaling: true,
            minBubbleRadius: 2,
            maxBubbleRadius: 50
          }
        },
        title: {
          text: 'Automation Bias',
          align: 'left',
          floating: true,
          margin: 0,
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '20px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          floating: true,
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '10px'
          }
        },
        fill: {
          opacity: 0.8
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          tickAmount: 12,
          min: -0.5,
          max: 10,
          type: 'category',
          labels: {
            show: false
          },
          axisBorder: {
            show: true,
            color: '#14304c'
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          min: -5,
          max: 105,
          labels: {
            show: false
          },
          axisBorder: {
            show: true,
            color: '#14304c'
          }
        },
        legend: {
          position: 'left',
          offsetY: 50,
          fontFamily: 'Lato',
          fontSize: '12px',
          labels: {
            colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
          }
        },
        tooltip: {
          theme: true,
          x: {
            show: false
          }
        }
      },
      radarOptions: {
        chart: {
          type: 'radar',
          height: 320,
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#28a745', '#ffc107', '#dc3545', '#17a2b8', '#2673bc'],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          radar: {
            size: 135,
            polygons: {
              strokeColors: '#14304c',
              fill: {
                colors: ['#081624', '#081624']
              }
            }
          }
        },
        title: {
          text: 'Automation Bias',
          align: 'left',
          floating: true,
          margin: 0,
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '20px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          floating: true,
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '10px'
          }
        },
        stroke: {
          width: 2
        },
        fill: {
          opacity: 0.2
        },
        markers: {
          size: 4,
          hover: {
            size: 8
          }
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          categories: ['Complete', 'High', 'Average', 'Normal', 'Low', 'None'],
          labels: {
            show: true,
            style: {
              fontFamily: 'Lato',
              fontSize: '12px'
            }
          },
          axisBorder: {
            show: false,
            color: '#14304c'
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          stepSize: 20,
          show: false,
          labels: {
            show: false
          },
          axisBorder: {
            show: false,
            color: '#14304c'
          },
          axisTicks: {
            show: false,
            color: '#14304c'
          }
        },
        legend: {
          position: 'left',
          offsetY: 50,
          fontFamily: 'Lato',
          fontSize: '12px',
          labels: {
            colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
          }
        }
      },
      curveOptions: {
        chart: {
          height: 300,
          type: 'area',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#28a745', '#ffc107', '#dc3545', '#17a2b8', '#2673bc'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          curve: 'smooth'
        },
        title: {
          text: 'Product Trends by Month',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '24px'
          }
        },
        subtitle: {
          text: 'Product Trends by Month',
          align: 'left',
          style: {
            color: '#ffffff',
            fontFamily: 'Lato',
            fontSize: '12px'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.4,
            opacityTo: 0,
            stops: [0, 60, 100]
          }
        },
        grid: {
          show: false,
          xaxis: {
            lines: {
              show: false
            }
          }
        },
        xaxis: {
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          tickAmount: 5,
          decimalsInFloat: 2,
          labels: {
            show: false,
            style: {
              colors: ['#ffffff'],
              fontFamily: 'Lato',
              fontSize: '10px'
            },
            formatter: function (val) { return val.toFixed(2) }
          }
        },
        legend: {
          position: 'left',
          offsetY: 50,
          fontFamily: 'Lato',
          fontSize: '12px',
          labels: {
            colors: ['#ffffff', '#ffffff', '#ffffff', '#ffffff']
          }
        },
        tooltip: {
          theme: true,
          x: {
            show: false
          }
        }
      },
      imgAiData: [],
      imgData: [],
      proImg: [],
      fa: 0,
      pd: 0,
      fd: 0,
      conReader: 0,
      disReader: 0,
      conMod: 0,
      disMod: 0,
      accuracy: [],
      rejections: [],
      trueP: 0,
      falseP: 0,
      falseN: 0,
      IOV: 0,
      notAnnotated: 0,
      partiallyAnnotated: 0,
      fullyAnnotated: 0,
      moderated: 0,
      processed: 0,
      failProcessed: 0,
      aiMetrics: [],
      tsChoice: 0.5,
      tabIndex: 0
    }
  },
  computed: {
    ...mapState({
      projects: (state) => state.projectSubmission.projects,
      userProjects: (state) => state.projectSubmission.projects
    }),
    ...mapState('labelledImagesSubmission', {
      images: (state) => state
    }),
    ...mapState('projectSubmission', {
      _project: (state) => state
    }),
    ...mapState('usersSubmission', { members: (state) => state }),
    pmsIOV () {
      return IOVThesholds()
    },
    pmsAccThreshold () {
      return {
        high: 0.9,
        medium: 0.8,
        low: 0.6
      }
    },
    pmsReported () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.report).length
      } else {
        return 0
      }
    },
    pmsReportAnalysed () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed').length
      } else {
        return 0
      }
    },
    pmsNotReported () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.length - this.pmsReported
      } else {
        return 0
      }
    },
    pmsNotAnalysed () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsReported - this.pmsReportAnalysed
      } else {
        return 0
      }
    },
    pmsFullAgreement () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed' && (1 - i.reportAnalysis.accuracy) <= IOVThesholds().fullAgreement).length
      } else {
        return 0
      }
    },
    pmsFullAgreementList () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed' && (1 - i.reportAnalysis.accuracy) <= IOVThesholds().fullAgreement)
      } else {
        return []
      }
    },
    pmsPartialAgreement () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed' && (1 - i.reportAnalysis.accuracy) <= IOVThesholds().partialAgreement && (1 - i.reportAnalysis.accuracy) > IOVThesholds().fullAgreement).length
      } else {
        return 0
      }
    },
    pmsDisagreement () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed' && (1 - i.reportAnalysis.accuracy) > IOVThesholds().partialAgreement).length
      } else {
        return 0
      }
    },
    pmsDisagreementList () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed' && (1 - i.reportAnalysis.accuracy) > IOVThesholds().fullAgreement)
      } else {
        return 0
      }
    },
    pmsModerated () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        return this.pmsImages.filter(i => i.submission && i.submission.attachments && i.submission.attachments.length > 0 && i.submission.attachments[0].feedback).length
      } else {
        return 0
      }
    },
    pmsIRV () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        if (reported.length > 0) {
          const sum = reported.reduce((acc, obj) => acc + parseFloat(1 - obj.reportAnalysis.accuracy), 0.00)
          console.log(sum)
          return sum / reported.length
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    pmsAB () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        if (reported.length > 0) {
          const stdAgreements = this.pmsFullAgreement > 0 ? mathjs.std(this.pmsFullAgreementList.map(i => i.reportAnalysis.accuracy)) : 0
          const stdDisagreements = (this.pmsPartialAgreement + this.pmsDisagreement) > 0 ? mathjs.std(this.pmsDisagreementList.map(i => i.reportAnalysis.accuracy)) : 0
          const stdAll = mathjs.std(reported.map(i => i.reportAnalysis.accuracy))
          return (stdAgreements - stdDisagreements) / stdAll
          // return this.pmsFullAgreement / this.pmsReportAnalysed
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    pmsReaderList () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        if (reported.length > 0) {
          const _readers = reported.map(i => i.report.reporter.investigatorCode)
          return Array.from(new Set(_readers)).sort()
        } else {
          return []
        }
      } else {
        return []
      }
    },
    pmsIRVPerReader () {
      if (this.pmsReaderList && this.pmsReaderList.length > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        const _series = []
        // const _ranges = [[0.0, 0.2], [0.2, 0.4], [0.4, 0.6], [0.6, 0.8], [0.8, 1]]
        const _ranges = [[0.0, 0.1], [0.1, 0.3], [0.3, 0.5], [0.5, 0.7], [0.7, 0.9], [0.9, 1]]
        this.pmsReaderList.forEach(r => {
          const _accuracies = reported.filter(i => i.report.reporter.investigatorCode === r).map(i => (1 - i.reportAnalysis.accuracy))
          const _data = this.calculateMedianAndCountInRange(_accuracies, _ranges).filter(r => r[0] !== null)
          if (_data && _data.length > 0) {
            _series.push({
              name: r,
              data: _data
            })
          }
        })

        return _series
      } else {
        return []
      }
    },
    pmsRadarPerReader () {
      if (this.pmsReaderList && this.pmsReaderList.length > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        const _series = []
        const _ranges = [[0.0, 0.1], [0.1, 0.3], [0.3, 0.5], [0.5, 0.7], [0.7, 0.9], [0.9, 1]]
        this.pmsReaderList.forEach(r => {
          const _accuracies = reported.filter(i => i.report.reporter.investigatorCode === r).map(i => (1 - i.reportAnalysis.accuracy))
          const _data = this.calculateAverageAndMaxInRange(_accuracies, _ranges)
          if (_data && _data.length > 0) {
            const _sum = _data.reduce((acc, i) => acc + i[0], 0)
            if (_sum > 0) {
              _series.push({
                name: r,
                data: _data.map(d => d[0])
              })
            }
          }
        })

        return _series
      } else {
        return []
      }
    },
    pmsCurvePerReader () {
      if (this.pmsReaderList && this.pmsReaderList.length > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        const _series = []
        const _ranges = [[0.0, 0.1], [0.1, 0.3], [0.3, 0.5], [0.5, 0.7], [0.7, 0.9], [0.9, 1]]
        this.pmsReaderList.forEach(r => {
          const _accuracies = reported.filter(i => i.report.reporter.investigatorCode === r).map(i => (1 - i.reportAnalysis.accuracy))
          const _data = this.calculateAverageAndCountInRange(_accuracies, _ranges)
          if (_data && _data.length > 0) {
            const _sum = _data.reduce((acc, i) => acc + i[0], 0)
            if (_sum > 0) {
              _series.push({
                name: r,
                data: _data.map(d => d[0])
              })
            }
          }
        })

        return _series
      } else {
        return []
      }
    },
    pmsReceiveTimeline () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        if (this.pmsImages.length > 0) {
          const result = this.pmsImages.reduce((acc, item) => {
            const date = new Date(item.submission.created_at) // Ensure it's a Date object
            if (!isNaN(date.getTime())) { // Check if date is valid
              if (!acc.min || date < acc.min) {
                acc.min = date
              }
              if (!acc.max || date > acc.max) {
                acc.max = date
              }
            }
            return acc
          }, { min: null, max: null })
          const _difference = Math.floor((result.max.getTime() - result.min.getTime()) / 1000 / 60)
          console.log('Difference', _difference)

          const _metrics = []
          this.pmsImages.forEach(r => {
            const _date = this.roundToNearestInterval(Math.round(_difference / 1000), new Date(r.submission.created_at))

            const _record = _metrics.length > 0 ? _metrics.find(m => { return m.date.getTime() === _date.getTime() }) : null
            if (_record) {
              _record.received += 1
              _record.reported += r.report ? 1 : 0
              _record.analysed += r.reportAnalysis ? 1 : 0
              _record.moderated += r.submission.attachments[0].feedback ? 1 : 0
              _record.processed += r.submission.attachments[0].response ? 1 : 0
            } else {
              const __record = {
                date: _date,
                received: 1,
                reported: r.report ? 1 : 0,
                analysed: r.reportAnalysis ? 1 : 0,
                moderated: r.submission.attachments[0].feedback ? 1 : 0,
                processed: r.submission.attachments[0].response ? 1 : 0
              }

              _metrics.push(__record)
            }
          })

          _metrics.sort((a, b) => {
            return a.date - b.date // Compare the dates
          })
          return _metrics
        } else {
          return []
        }
      } else {
        return []
      }
    },
    pmsSSMetrics () {
      if (this.pmsImages && this.pmsImages.length > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        if (reported.length > 0) {
          const result = reported.reduce((acc, item) => {
            const date = new Date(item.reportAnalysis.created_at) // Ensure it's a Date object
            if (!isNaN(date.getTime())) { // Check if date is valid
              if (!acc.min || date < acc.min) {
                acc.min = date
              }
              if (!acc.max || date > acc.max) {
                acc.max = date
              }
            }
            return acc
          }, { min: null, max: null })
          const _difference = Math.floor((result.max.getTime() - result.min.getTime()) / 100000 / 600)

          const _metrics = []
          reported.forEach(r => {
            const _date = this.roundToNearestInterval(Math.round(_difference / 15), new Date(r.reportAnalysis.created_at))

            const _record = _metrics.length > 0 ? _metrics.find(m => { return m.date.getTime() === _date.getTime() }) : null
            if (_record) {
              _record.TP += r.reportAnalysis.confusionMatrix.TP
              _record.FP += r.reportAnalysis.confusionMatrix.FP
              _record.FN += r.reportAnalysis.confusionMatrix.FN
              _record.TN += r.reportAnalysis.confusionMatrix.TN

              _record.sensitivity = _record.TP / (_record.TP + _record.FN)
              _record.specificity = _record.TN / (_record.TN + _record.FP)
              _record.ppv = _record.TP / (_record.TP + _record.FP)
              _record.npv = _record.TN / (_record.TN + _record.FN)
            } else {
              const __record = {
                date: _date,
                TP: r.reportAnalysis.confusionMatrix.TP,
                FP: r.reportAnalysis.confusionMatrix.FP,
                FN: r.reportAnalysis.confusionMatrix.FN,
                TN: r.reportAnalysis.confusionMatrix.TN
              }

              __record.sensitivity = __record.TP / (__record.TP + __record.FN)
              __record.specificity = __record.TN / (__record.TN + __record.FP)
              __record.ppv = __record.TP / (__record.TP + __record.FP)
              __record.npv = __record.TN / (__record.TN + __record.FN)

              _metrics.push(__record)
            }
          })

          _metrics.sort((a, b) => {
            return a.date - b.date // Compare the dates
          })
          return _metrics
        } else {
          return []
        }
      } else {
        return []
      }
    },
    pmsConfusionMatrix () {
      const _matrix = {
        tp: 0,
        fp: 0,
        fn: 0,
        tn: 0
      }

      if (this.pmsReportAnalysed && this.pmsReportAnalysed > 0) {
        const reported = this.pmsImages.filter(i => i.reportAnalysis && i.reportAnalysis.status === 'Completed')
        reported.forEach(r => {
          _matrix.tp += r.reportAnalysis.confusionMatrix.TP
          _matrix.fp += r.reportAnalysis.confusionMatrix.FP
          _matrix.fn += r.reportAnalysis.confusionMatrix.FN
          _matrix.tn += r.reportAnalysis.confusionMatrix.TN
        })
      }

      return _matrix
    },
    pmsAccuracy () {
      return (this.pmsConfusionMatrix.tp + this.pmsConfusionMatrix.fp + this.pmsConfusionMatrix.fn + this.pmsConfusionMatrix.tn === 0
        ? 'Not Enough Data'
        : (this.pmsConfusionMatrix.tp + this.pmsConfusionMatrix.tn) / (this.pmsConfusionMatrix.tp + this.pmsConfusionMatrix.fp + this.pmsConfusionMatrix.fn + this.pmsConfusionMatrix.tn))
    },
    thesholds () {
      return Array.from({ length: 21 }, (_, i) => parseFloat((i * 0.05).toFixed(2)))
    },
    getAi () {
      return this.images.aiLoaded
    },
    getAiStats () {
      return this._project.statsLoading
    },
    project () {
      const filteredProject = this.userProjects.find(
        (project) => project._id === this.projectId
      )
      // // // // console.log('filtered project', filteredProject)
      return filteredProject
    },
    imagesLoaded () {
      return this.images.loaded
    },
    // computeCharts () {
    //   variabilityOptions
    //   readerOptions
    //   moderatorOptions
    //   cmOptions
    //   aucOptions
    //   prOptions
    // },
    variabilityOptions () {
      // if (this.currentProject.studyType === 'Retrospective') {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['Full Agreement', 'Partial Disagreement', 'Full Disagreement']
      _options.colors = ['#28a745', '#ffc107', '#dc3545']
      _options.title.text = !this.pmsIRV ? 'Not Enough Data' : (this.pmsIRV * 100).toFixed(2) + '%'
      _options.subtitle.text = 'Ai vs Reader Variability'

      return _options
      // }
    },
    variabilitySeries () {
      return [this.pmsFullAgreement, this.pmsPartialAgreement, this.pmsDisagreement]
    },
    readerOptions () {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['Reported', 'Not Yes Reported', 'Not Yet Analysed', 'Report Analysed']
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = !this.pmsReportAnalysed ? 'Not Enough Data' : (this.pmsReportAnalysed / this.pmsReported * 100).toFixed(2) + '%'
      _options.subtitle.text = 'Reports Analysed'

      return _options
    },
    readerSeries () {
      return [this.pmsReported, this.pmsNotReported, this.pmsNotAnalysed, this.pmsReportAnalysed]
    },
    moderatorOptions () {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['Moderated', 'Rejected']
      _options.colors = ['#28a745', '#dc3545']
      _options.title.text = !this.pmsIRV ? 'Not Enough Data' : (this.pmsAB).toFixed(2)
      _options.subtitle.text = 'Automation Bias'
      return _options
    },
    moderatorSeries () {
      return [this.moderated, this.rejections.reduce((a, b) => a + b.count, 0)]
    },
    abOptions () {
      const _options = JSON.parse(JSON.stringify(this.bubbleOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = !this.pmsIRV ? 'Not Enough Data' : this.pmsAB.toFixed(2)
      _options.subtitle.text = 'Automation Bias Factor'

      return _options
    },
    abSeries () {
      return this.pmsIRVPerReader
    },
    rdOptions () {
      const _options = JSON.parse(JSON.stringify(this.radarOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = 'Agreement Factor'
      _options.subtitle.text = 'With AI Detections in Reports'

      return _options
    },
    rdSeries () {
      return this.pmsRadarPerReader
    },
    countOptions () {
      const _options = JSON.parse(JSON.stringify(this.curveOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = 'Agreement Trend'
      _options.subtitle.text = 'With AI Detections in Reports'

      return _options
    },
    countSeries () {
      return this.pmsCurvePerReader
    },
    receiveOptions () {
      const _options = JSON.parse(JSON.stringify(this.curveOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = 'Timeline Overview'
      _options.subtitle.text = 'Studies going through workflow'

      return _options
    },
    receiveSeries () {
      return [
        { name: 'Received', data: this.pmsReceiveTimeline.map(i => i.received) },
        { name: 'Processed', data: this.pmsReceiveTimeline.map(i => i.processed) },
        { name: 'Reported', data: this.pmsReceiveTimeline.map(i => i.reported) },
        { name: 'Analysed', data: this.pmsReceiveTimeline.map(i => i.analysed) },
        { name: 'Moderated', data: this.pmsReceiveTimeline.map(i => i.moderated) }
      ]
    },
    ssOptions () {
      const _options = JSON.parse(JSON.stringify(this.curveOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      _options.title.text = 'AI Performance'
      _options.subtitle.text = 'Timeline Metrics based on Radiology Report Analysis'
      _options.legend.position = 'bottom'
      _options.legend.offsetY = 0
      _options.colors = ['#dc3545', '#28a745', '#ffc107', '#17a2b8', '#2673bc']

      return _options
    },
    ssSeries () {
      return [
        { name: 'Minimum Threshold', data: new Array(this.pmsSSMetrics.length).fill(0.60) },
        { name: 'Sensitivity', data: this.pmsSSMetrics.map(i => i.sensitivity) },
        { name: 'Specificity', data: this.pmsSSMetrics.map(i => i.specificity) }
      ]
    },
    pvOptions () {
      const _options = JSON.parse(JSON.stringify(this.curveOptions))
      // _options.colors = ['#28a745', '#dc3545', '#ffc107']
      delete _options.title
      delete _options.subtitle.text
      _options.legend.position = 'bottom'
      _options.legend.offsetY = 0
      _options.colors = ['#dc3545', '#17a2b8', '#2673bc']

      return _options
    },
    pvSeries () {
      return [
        { name: 'Minimum Threshold', data: new Array(this.pmsSSMetrics.length).fill(0.60) },
        { name: 'Positive Predictive Value', data: this.pmsSSMetrics.map(i => i.ppv) },
        { name: 'Negative Predictive Value', data: this.pmsSSMetrics.map(i => i.npv) }
      ]
    },
    dataOptions () {
      const _options = JSON.parse(JSON.stringify(this.barOptions))
      _options.title.text = undefined

      return _options
    },
    dataSeries () {
      return [{
        name: 'Received, not Reported',
        data: [this.pmsImages.length - this.pmsReported]
      }, {
        name: 'Reported, not Analysed',
        data: [this.pmsReported - this.pmsReportAnalysed]
      }, {
        name: 'Rejected on Reporting',
        data: [0]
      }, {
        name: 'Report Analysed',
        data: [this.pmsReportAnalysed - this.pmsModerated]
      }, {
        name: 'Report Rejected',
        data: [0]
      }, {
        name: 'Moderated',
        data: [this.pmsModerated]
      }, {
        name: 'Rejected on Moderation',
        data: [0]
      }]
    },
    failed () {
      if (this.imgAiData && this.imgAiData.length > 0) {
        return this.imgAiData.filter(img => (!img.removed.find(p => p.project === this.list[0]._id)) && (img.diagnosis && (img.diagnosis === 'Error' || img.diagnosis === 'Unknown'))).length
      } else {
        return 0
      }
    },
    cmOptions () {
      const _options = JSON.parse(JSON.stringify(this.pieOptions))
      _options.labels = ['True Positive', 'False Positive', 'False Negative', 'True Negative']
      _options.title.text = (this.pmsAccuracy * 100).toFixed(2) + '%'
      _options.subtitle.text = 'Accuracy of AI'

      return _options
    },
    cmSeries () {
      return [this.pmsConfusionMatrix.tp, this.pmsConfusionMatrix.fp, this.pmsConfusionMatrix.fn, this.pmsConfusionMatrix.tn]
    },
    auc () {
      const _thresholds = JSON.parse(JSON.stringify(this.metrics.filter(__metrics => __metrics.threshold !== 0)))
      const _metrics = []

      if (_thresholds && _thresholds.length > 0) {
        _metrics.push({
          threshold: 0,
          FPR: 1,
          TPR: 1,
          AUC: (1 - _thresholds[0].FPR) * 1
        })

        for (let i = 0; i < _thresholds.length; i++) {
          _metrics.push({
            threshold: _thresholds[i].threshold,
            FPR: _thresholds[i].FPR,
            TPR: _thresholds[i].TPR,
            AUC: (_thresholds[i].FPR - (_thresholds[i + 1] ? _thresholds[i + 1].FPR : 0) - 0.003175) * _thresholds[i].TPR
          })
        }
      }

      let _sum = 0
      if (_metrics.filter(_record => _record.FPR !== null && _record.TPR !== null).length > 1) {
        _metrics.forEach(_metric => {
          _sum += _metric.AUC
        })
      }

      return _sum
    },
    aucOptions () {
      const _options = JSON.parse(JSON.stringify(this.options))
      _options.xaxis.type = 'numeric'
      _options.xaxis.min = 0
      _options.xaxis.max = 1
      _options.xaxis.categories = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95]
      _options.title.text = this.auc.toFixed(4)
      _options.subtitle.text = 'Area Under Curve'

      return _options
    },
    aucSeries () {
      const _sorted = this.metrics.slice()
      _sorted.sort((a, b) => { return a.FRP - b.FRP })

      return [{
        data: _sorted.map(__metrics => { return { x: __metrics.FPR, y: (__metrics.TPR > 1 ? __metrics.TPR : __metrics.TPR) } })
      }]
    },
    prOptions () {
      const _options = JSON.parse(JSON.stringify(this.options))
      _options.xaxis.type = 'numeric'
      _options.xaxis.min = 0
      _options.xaxis.max = 1
      _options.xaxis.categories = [0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95]
      _options.title.text = this.metrics.find(__metrics => __metrics.threshold === 0.8) ? this.metrics.find(__metrics => __metrics.threshold === 0.8).accuracy.toFixed(4) : 0
      _options.subtitle.text = 'Precision-Recall Curve'

      return _options
    },
    prSeries () {
      return [{
        data: this.metrics.map(__metrics => { return { x: __metrics.precision !== null ? __metrics.precision : 0, y: __metrics.recall !== null ? __metrics.recall : 0 } })
      }]
    },
    rejectWatch () {
      return !this._project.loadingRejects
    },
    readers () {
      if (this.members && this.members.members && this.members.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Reader') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    moderators () {
      if (this.members && this.members.members && this.members.members.length > 0 && this.project) {
        const _project = this.project._id
        const _readers = []
        this.members.members.forEach(_member => {
          const _role = _member.projectRole.find(_role => _role.project === _project)
          if (_role && _role.role === 'Moderator') {
            _readers.push(_member)
          }
        })

        return _readers
      } else {
        return []
      }
    },
    projectImagesLoaded () {
      return !this._project.loadingProjectImages
    },
    confusionMatrix () {
      const _at50 = this.aiMetrics.find(_record => _record.threshold === this.tsChoice)

      if (_at50) {
        return {
          truePositive: _at50.TP,
          falsePositive: _at50.FP,
          falseNegative: _at50.FN,
          trueNegative: _at50.TN
        }
      } else {
        return {
          truePositive: 0,
          falsePositive: 0,
          falseNegative: 0,
          trueNegative: 0
        }
      }
    },
    metrics () {
      return this.aiMetrics
    },
    dataLoaded () {
      return (this.getAi && this.projectImagesLoaded && this.imagesLoaded && this.rejectWatch)
    },
    thesholdChange () {
      return this.tsChoice
    },
    aiStatsLoading () {
      return this._project.aiStatsLoading
    },
    filterWatch () {
      return this.filterChoice
    }
  },
  mounted () {
    // // // console.log('ab')
    this.imgAiData = this.images.aiImages.filter(img => (!img.removed.find(p => p.project === this.project._id)))
    this.proImg = this._project.projectImages
    this.imgData = this.images.labelledImages.filter(img => (!img.removed.find(p => p.project === this.project._id)))
    this.rejections = this._project.rejects
    if (this._project && this._project.stats) {
      const curIndex = this._project.stats.findIndex(p => p.projectId === this.projectId)
      // console.log('ai ret', curIndex)
      if (curIndex > -1) {
        this.tsChoice = this._project.stats[curIndex].confiThes
      }
    }
    this.getChartData()
    this.calcMetrics()
  },
  methods: {
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2', 'fetchAILabelledImages']),
    ...mapActions('projectSubmission', ['getProjectImages2', 'getRejections2', 'updateConfidence']),
    hasGroup (img, val) {
      var _filter = this.project.subgroups.find(sg => sg.name === val).definiation
      // // console.log(_filter)
      var includeInFilter = false
      Object.keys(_filter).forEach(_key => {
        if (_key === 'Pathologies') {
          _filter[_key].forEach(path => {
            if (img.pathologies.has(path)) {
              includeInFilter = true
            }
          })
        } else if (_key === 'Tags') {
          _filter[_key].forEach(tag => {
            // // console.log(img.data.details)
            if (img.tags.has(tag)) {
              includeInFilter = true
            }
          })
        } else if (_key === 'Diagnosis') {
          _filter[_key].forEach(dia => {
            if (img.diagnoses.has(dia)) {
              includeInFilter = true
            }
          })
        } else if ((img.metadata && img.metadata[0] && img.metadata[0] && img.metadata[0].values.find(_meta => _meta.name === _key) && _filter[_key].includes(img.metadata[0].values.find(_meta => _meta.name === _key).value))) {
          includeInFilter = true
        }
      })
      // // // console.log('sg', inc ludeInFilter)
      return includeInFilter
    },
    getChartData () {
      if (this.getAi && this.projectImagesLoaded && this.imagesLoaded && this.rejectWatch && !this.getAiStats) {
        // // console.log(this.getAi)
        // // console.log(this.projectImagesLoaded)
        // // console.log(this.imagesLoaded)
        // // console.log(this.rejectWatch)
        this.moderated = 0
        this.fullyAnnotated = 0
        this.partiallyAnnotated = 0
        this.moderated = 0
        this.fa = 0
        this.pd = 0
        this.fd = 0
        var runningAcc = 0
        var totalAcc = 0
        let _imgData
        if (this.filterChoice !== 'All' && this.filterChoice !== '') {
          _imgData = this.imgData.filter(img => this.hasGroup(img, this.filterChoice))
        } else {
          _imgData = this.imgData
        }
        _imgData.forEach(img => {
          if (!img.removed.find(p => p.project === this.project._id)) {
            if (img.users.find(us => us.role === 'Moderator')) {
              // // console.log('mod')
              this.moderated += 1
              this.fullyAnnotated += 1
            } else if (img.users.length < this.readers.length && img.users.length > 0) {
              this.partiallyAnnotated += 1
            } else if (img.users.length > 0 && img.users.length >= this.readers.length) {
              this.fullyAnnotated += 1
            }
            var acc
            if (img.accuracy) {
              acc = img.accuracy
            } else {
              acc = 0
            }
            if (this.readers.length) {
              runningAcc += acc
              totalAcc += 1
              // // console.log('acc', acc)
              if (acc <= IOVThesholds().fullAgreement) {
                this.fa++
              } else if (acc >= IOVThesholds().fullAgreement && acc <= IOVThesholds().partialAgreement) {
                this.pd++
              } else {
                this.fd++
              }
            }
          }
        })
        // console.log('iov check', runningAcc, totalAcc)
        this.IOV = runningAcc / totalAcc
        this.notAnnotated = this.proImg.length - this.fullyAnnotated - this.partiallyAnnotated
      }
      // // // console.log('chart')
    },
    getPhase: function () {
      if (this.projectId) {
        const _project = this.list.find(_project => _project._id === this.projectId)
        const _phase = this.phases[_project.type.toLowerCase()].find(_phase => _phase.id === this.selected)
        return _phase.name
      } else {
        return ''
      }
    },
    getIcon: function () {
      if (this.projectId) {
        const _project = this.list.find(_project => _project._id === this.projectId)
        // // console.log('_phase', _project.type.toLowerCase())
        const _phase = this.phases[_project.type.toLowerCase()].find(_phase => _phase.id === this.selected)
        console.log(_project, _phase)
        return _phase.icon
      } else {
        return ''
      }
    },
    calcMetrics: function () {
      if (this.getAi && this.projectImagesLoaded && this.imagesLoaded && this.rejectWatch && !this.getAiStats) {
        const projectStats = this._project.stats.find(p => p.projectId === this.projectId)
        // console.log('ai2', projectStats, this.projectId)
        let met
        if (this.filterChoice === '' || this.filterChoice === 'All') {
          met = projectStats.stats.find(p => p.subgroup === 'All')
        } else {
          met = projectStats.stats.find(p => p.subgroup === this.filterChoice)
        }
        // console.log('ai3', met)
        if (met) {
          this.aiMetrics = met.metrics
        }
      }
    },
    calculateMedianAndCountInRange: function (numbers, ranges) {
      return ranges.map(range => {
        // Filter the array based on the current range
        const filteredNumbers = numbers.filter(num => num >= range[0] && num <= range[1])

        // Count the number of elements in the filtered subset
        const count = filteredNumbers.length

        // Calculate the median
        if (count === 0) {
          return [null, null, count]
        }

        const sortedNumbers = [...filteredNumbers].sort((a, b) => a - b)
        let median
        if (count % 2 === 1) {
          median = sortedNumbers[Math.floor(count / 2)]
        } else {
          const midIndex = Math.floor(count / 2)
          median = (sortedNumbers[midIndex - 1] + sortedNumbers[midIndex]) / 2
        }

        const std = mathjs.std(sortedNumbers)

        return [std * 100, median * 100, count]
      })
    },
    calculateAverageAndMaxInRange: function (numbers, ranges) {
      return ranges.map(range => {
        // Filter the array based on the current range
        const filteredNumbers = numbers.filter(num => num >= range[0] && num <= range[1])

        // Count the number of elements in the filtered subset
        const count = filteredNumbers.length

        // Calculate the median
        if (count === 0) {
          return [0, (range[0] + range[1]) / 2]
        }

        const sortedNumbers = [...filteredNumbers].sort((a, b) => a - b)
        const std = mathjs.std(sortedNumbers)

        return [Math.round(std * 100), (range[0] + range[1]) / 2]
      })
    },
    calculateAverageAndCountInRange: function (numbers, ranges) {
      return ranges.map(range => {
        // Filter the array based on the current range
        const filteredNumbers = numbers.filter(num => num >= range[0] && num <= range[1])

        // Count the number of elements in the filtered subset
        const count = filteredNumbers.length

        // Calculate the median
        if (count === 0) {
          return [0, (range[0] + range[1]) / 2]
        }

        return [count, (range[0] + range[1]) / 2]
      })
    },
    roundToNearestInterval: function (interval, date) {
      // Extract hours, minutes, seconds from the current date
      let hours = Math.floor(date.getHours())
      const minutes = date.getMinutes()

      // Calculate the rounded minute based on the interval
      let roundedMinutes = Math.round((minutes + (interval - minutes % interval)) / interval) * interval
      const _skipDate = roundedMinutes >= (24 * 60)

      // Ensure the number of minutes is within bounds (0-60)
      if (roundedMinutes >= 60) {
        const _iterations = Math.floor(roundedMinutes / 60)
        for (let i = 0; i < _iterations; i++) {
          roundedMinutes -= 60 // If it's over 60, increase hours and reset to 0
        }
      }

      if (hours > 24) {
        hours -= 24
      }

      // Create a new date with the rounded time
      const roundedDate = new Date(date)
      roundedDate.setHours(_skipDate ? 0 : hours)
      roundedDate.setMinutes(roundedMinutes)
      roundedDate.setSeconds(0) // Set seconds to 0
      roundedDate.setMilliseconds(0)

      return roundedDate
    }
  },
  watch: {
    getAi (_new) {
      if (_new) {
        this.imgAiData = this.images.aiImages.filter(img => (!img.removed.find(p => p.project === this.project._id)))
        this.getChartData()
        this.calcMetrics()
      }
    },
    projectImagesLoaded (_new) {
      if (_new) {
        this.proImg = this._project.projectImages
        this.getChartData()
        this.calcMetrics()
      }
    },
    imagesLoaded (_new) {
      if (_new) {
        this.imgData = this.images.labelledImages.filter(img => (!img.removed.find(p => p.project === this.project._id)))
        this.getChartData()
        this.calcMetrics()
      }
    },
    // add stats watcher
    rejectWatch (_new) {
      if (_new) {
        this.rejections = this._project.rejects
        this.getChartData()
        this.calcMetrics()
      }
    },
    thesholdChange (_new) {
      this.rejections = this._project.rejects
      this.getChartData()
      this.calcMetrics()
      this.updateConfidence({ confiThes: this.tsChoice, projectId: this.projectId })
    },
    filterWatch (_new) {
      this.rejections = this._project.rejects
      this.getChartData()
      this.calcMetrics()
    },
    aiStatsLoading (_new) {
      if (!_new) {
        this.rejections = this._project.rejects
        this.getChartData()
        this.calcMetrics()
      }
    },
    dataLoaded (_new) {
      // // console.log(_new)
    }
  }
}
</script>

<style>
.bottom-to-top {
  writing-mode: vertical-lr;
  text-orientation: mixed;
}
</style>
