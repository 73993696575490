<template>
  <div ref="footerLock">
    <b-col cols="12">
      <b-row cols="12">
        <!-- style="height:700px; " -->
        <b-col :cols="isImageSet ? '7' : '8'" align-self="baseline" class="pr-2">
          <b-overlay :show="true" rounded="sm" shown="true" opacity="0.0" no-center class="boundingboxs">
            <img ref="annotatedImage" @load="imgLoaded" :src="getImageUrl(selectedImage)" class="img-fluid"
              :style="'width: 100%; filter: brightness(' + brightness + '%) contrast(' + contrast + '%) saturate(' + saturate + '%) sepia(' + sepia + '%) hue-rotate(' + hue + 'deg) invert(' + invert + '%);'" />
            <template #overlay>
              <canvas class="img fluid img-large boundingboxs" ref="canvas" :width="canvasWidth" :height="canvasHeight"
                style="width: 100%; height: 100%" />
            </template>
          </b-overlay>
        </b-col>
        <b-col v-if="isImageSet" :cols="setCols">
          <b-row v-if="imgSet[0].details.image_set.window && getProjectExtra.grouping !== 'series' && getProjectExtra.grouping !== 'sets'" class="mb-2">
            <b-col class="">
              <b-dropdown  class="btn btn-sm btn-yellow rounded-pill" :text="selectedWindowDisplayed" variant="warn">
                <b-dropdown-text v-for="(win) in windowOpt" :key="win" @click="selectedWindow = win">{{ win }}</b-dropdown-text>
              </b-dropdown>
            </b-col>
          </b-row>
          <b-row class="imageSetContainer">
            <b-col cols="1" class="px-0">
              <b-form-input type="range" id="image-scroll-bar" v-model="currentImageIndex" min="0" :max="this.imgSet.length -1" orient="vertical" class="m-0 p-0 vranger"/>
            </b-col>
            <b-col cols="11" class="pl-0 pr-1 scrollable2" :style="'height: ' + imageHeight.toString() *4/5 + 'px !important'" id="img_set">
              <ul class="list-unstyled heatmap-list" v-if="phaseData.phase === 1">
                <li class="col-12 pl-2 pr-1 mb-1" v-for="_image in imgSet" :key="_image.imgId" :id="_image.imgId">
                  <a href="#" @click.prevent="selectedImage = _image.imgId; currentImageIndex = getSelectedIndex( _image.imgId) ">
                    <img :src="getImageUrl(_image.imgId) + '?thumb=Yes'" class="img-fluid" :class="{ 'active': selectedImage === _image.imgId, 'labelled': _image.labelled }" />
                  </a>
                  <p class="text-center mb-0 line-height-1" :class="selectedImage === _image.imgId ? 'text-warning' : ''"><small>{{ getIdentifier(_image.imgId) }}</small></p>
                </li>
              </ul>
              <ul class="list-unstyled heatmap-list ml-2" v-if="phaseData.phase !== 1">
                <li class="col-12 pl-2 pr-1 mb-1" v-for="_image in imgSet" :key="_image.imgId" :id="_image.imgId">
                  <a href="#" @click.prevent="selectedImage = _image.imgId; currentImageIndex = getSelectedIndex( _image.imgId) ">
                    <img :src="getImageUrl(_image.imgId) + '?thumb=Yes'" class="img-fluid" :class="{ 'active': selectedImage === _image.imgId, 'labelled': _image.labelled }" />
                  </a>
                  <p class="text-center mb-0 line-height-1" :class="selectedImage === _image.imgId ? 'text-warning' : ''"><small>{{ getIdentifier(_image.imgId) }}</small></p>
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-col>
        <b-col :cols="isImageSet && setCols === 2 ? '3' : '4'" class="img_details">
          <b-row>
            <b-col align-self="stretch">
              <div class="enhance-form px-3 pt-3 pb-1 mb-1">
                <b-button pill class="btn-blue ml-2 float-right" v-if="!fullscreenprop" @click="fullscreen()"> <font-awesome-icon icon="expand"  /></b-button>
                <h5 class="color-white">Image Details </h5>
                <p class="mb-0">Created on: {{detailsChache.created | moment('YYYY-MM-DD HH:mm') }}</p>
                <p class="mb-0">Identifier / Name: {{ detailsChache.imgName }}</p>
                <!-- <p v-if="isImageSet">Sequence: {{ getImage(selectedImage).image_set.sequence }}</p> -->
                <p class="mb-0" v-if="detailsChache.iou !== null && detailsChache.iou >= 0">Image / Slice IOV: {{ (Math.round(detailsChache.iou * 10000) / 100).toFixed(2) }}%</p>
                <p class="mb-0" v-if="detailsChache.aveIou !== null && detailsChache.aveIou >= 0">Study IOV: {{ (Math.round(detailsChache.aveIou * 10000) / 100).toFixed(2) }}%</p>
                <p class="mb-0" v-if="detailsChache.aiIov !== null && detailsChache.aiIov >= 0">AI Accuracy: {{ (Math.round(detailsChache.aiIov * 10000) / 100).toFixed(2) }}%</p>
                <p class="mt-2">
                  <a href="#" @click.prevent="metadataOpen = !metadataOpen">
                    <font-awesome-icon icon="chevron-right" class="mr-2 color-white" v-if="!metadataOpen" />
                    <font-awesome-icon icon="chevron-down" class="mr-2 color-white" v-else />
                    <strong class="color-white">Metadata</strong>
                  </a>
                </p>
                <div class="mb-3" v-if="metadataOpen">
                  <div v-for="(md, index) in detailsChache.meta" :key="index">
                    <div v-for="_md in md.values" :key="_md._id">
                      <p class="mb-0">{{_md.name +" : " +_md.value}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- v-if="[1, 2, 3, 4, 10, 11].includes(selected) -->
              <b-col class="enhance-form px-3 pt-3 pb-1 mb-1 " v-if="users && users.length > 0">
                <h5 class="color-white">Annotators</h5>
                <ul class="list-group">
                  <li v-for="user in userChache" :key="user.id" class="list-group-item annotator mb-1 ">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <span class="accordion-heading" @click="toggleUserBoundingBoxes(user)" v-if="user.boundingBoxes && user.boundingBoxes.length > 0">
                          <b-button class="mb-1 mx-1pb-1"
                            :style="{ 'background-color': user.boundingBoxes[0] === 'Normal' ? '' :  user.boundingBoxes[0].colour, 'border-color': user.boundingBoxes[0] === 'Normal' ? '' : user.boundingBoxes[0].colour }"></b-button>
                          {{ user.name }}
                        </span>
                        <span class="accordion-heading" v-else>
                          <b-button class="mb-1 mx-1pb-1"></b-button>
                          {{ user.name }}
                        </span>
                      </div>
                      <div>
                        <button @click="toggleUserBoundingBoxes(user)" class="btn" >
                            <font-awesome-icon v-if="user.showBoundingBoxes" class="fa color-yellow" icon="chevron-up"  />
                            <font-awesome-icon v-else class="fa color-yellow" icon="chevron-down"  />
                        </button>
                      </div>
                    </div>
                    <div>
                      <div>
                        <p class="mb-0" v-if="user.name !== 'AI'">Diagnoses: {{ user.diagnoses.length > 0 ? user.diagnoses.join(', ') : 'Normal'  }}</p>
                        <p class="mb-0" v-else>Diagnoses: {{ user.diagnoses.length > 0 ? user.diagnoses.join(', ') : 'Normal'  }}</p>
                      </div>
                      <div>
                        <p class="mb-0" v-if="user.name !== 'AI'">Tags: {{ user.tags.length > 0 ? user.tags.join(', ') : 'None' }}</p>
                        <p class="mb-0 d-none" v-else>Score: {{ (user.tags.length > 0 ? user.tags.join(', ') : 'None') +'%' }}</p>
                      </div>
                    </div>
                    <div class="mt-2" v-if="user.showBoundingBoxes">
                      <ul class="pathology-list">
                        <li v-for="(boundingBox, $index) in user.boundingBoxes" :key="$index">
                          <b-row class="mb-0">
                            <b-col cols="2">
                              <button @click="toggleBoundingBox(boundingBox)" class="btn py-0"  v-if="user.boundingBoxes[0] !== 'Normal'">
                                <font-awesome-icon class="fa color-yellow"  v-if="boundingBox.showBoundingBox" icon="eye"  />
                                <font-awesome-icon class="fa color-yellow"  v-else icon="eye-slash"  />
                              </button>
                            </b-col>
                            <b-col :cols="boundingBox.stats && boundingBox.stats.length > 0 ? 7 : 10">
                              {{ user.name !== 'AI' ?  boundingBox.pathology : boundingBox.pathology + ' (AIC: ' + (Math.round(boundingBox.confidence *10000) / 100).toFixed(2) + '%)'}}
                            </b-col>
                            <b-col cols="3" class="p-0" v-if="boundingBox.stats && boundingBox.stats.length > 0">
                              <a class="float-right mr-2" href="#" @click.prevent="toggleStats(user.id + $index)" v-if="boundingBox.stats && boundingBox.stats.length > 0">
                                <small>
                                  <strong class="color-white">Stats</strong>
                                  <font-awesome-icon icon="chevron-left" class="ml-1 color-white" v-if="!statsOpen[user.id + $index]" />
                                  <font-awesome-icon icon="chevron-down" class="ml-1 color-white" v-else />
                                </small>
                              </a>
                            </b-col>
                          </b-row>
                          <b-row v-if="boundingBox.stats && boundingBox.stats.length > 0 && statsOpen[user.id + $index]">
                            <b-col cols="12">
                              <span v-for="plane in boundingBox.stats" :key="$index + plane.view">
                                <small><b>{{ plane.view }}:</b> Area: {{ plane.area.toLocaleString(undefined, { maximumFractionDigits: 2 }) + plane.areaUnit }}, {{ plane.modalityUnit }} mean: {{ plane.mean.toLocaleString(undefined, { maximumFractionDigits: 2 }) }}</small><br />
                              </span>
                              <hr class="mt-1 mb-0" v-if="boundingBox.other && Array.isArray(boundingBox.other) && boundingBox.other.length > 0" />
                              <span v-for="(other, $_index) in boundingBox.other" :key="$index + $_index">
                                <small><b>{{ other.view }}:</b> {{ other.type }}: {{ other.stats.length.toLocaleString(undefined, { maximumFractionDigits: 2 }) + other.stats.unit }}</small><br />
                              </span>
                            </b-col>
                          </b-row>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </b-col>
              <b-form class="enhance-form px-3 pt-3 pb-1">
                <h6 class="color-white mb-3">Enhance image</h6>
                <b-form-group class="mb-0">
                  <b-input-group>
                    <template v-slot:prepend>
                      <b-input-group-text class="bg-transparent border-0">
                            <font-awesome-icon class="fa color-yellow"  icon="sun"  />
                      </b-input-group-text>
                    </template>
                    <b-form-input v-model="brightness" type="range" min="0" max="200"
                      class="bg-transparent border-0 outline-removed"></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group class="mb-0">
                  <b-input-group>
                    <template v-slot:prepend>
                      <b-input-group-text class="bg-transparent border-0">
                            <font-awesome-icon class="fa color-yellow"  icon="adjust"  />
                      </b-input-group-text>
                    </template>
                    <b-form-input v-model="contrast" type="range" min="0" max="200"
                      class="bg-transparent border-0 outline-removed"></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group class="mb-0">
                  <b-input-group>
                    <template v-slot:prepend>
                      <b-input-group-text class="bg-transparent border-0">
                            <font-awesome-icon class="fa color-yellow"  icon="tint"  />
                      </b-input-group-text>
                    </template>
                    <b-form-input v-model="saturate" type="range" min="0" max="200"
                      class="bg-transparent border-0 outline-removed"></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group class="mb-0">
                  <b-input-group>
                    <template v-slot:prepend>
                      <b-input-group-text class="bg-transparent border-0">
                            <font-awesome-icon class="fa color-yellow"  icon="camera"  />
                      </b-input-group-text>
                    </template>
                    <b-form-input v-model="sepia" type="range" min="0" max="100"
                      class="bg-transparent border-0 outline-removed"></b-form-input>
                  </b-input-group>
                </b-form-group>
                <b-form-group>
                  <b-input-group>
                    <template v-slot:prepend>
                      <b-input-group-text class="bg-transparent border-0">
                            <font-awesome-icon class="fa color-yellow"  icon="fill"  />
                      </b-input-group-text>
                    </template>
                    <b-form-input v-model="invert" type="range" min="0" max="100"
                      class="bg-transparent border-0 outline-removed"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-form>
              <div class="mt-1" v-if="(current_role === 'Administrator' ||  current_role === 'Data Scientist')">
                <b-form class="enhance-form px-3 pt-3 pb-1">
                  <h6 class="color-white mb-3">Reject image</h6>
                  <b-row class="px-3 mb-3">
                    <b-input v-model="reason" placeholder="Reject Image Reason"
                    :state="reason!==''"
                    ></b-input>
                  </b-row>
                  <b-row class="px-3 mb-2" align-h="around">
                    <b-button pill class="btn-yellow" :disabled="reason===''" @click=rejectImage()>Reject</b-button>
                    <b-button pill class="btn-blue" @click="reason=''">Cancel</b-button>
                  </b-row>
                </b-form>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { apiRoute } from '../../../../helpers'

export default {
  props: {
    fullscreenprop: Boolean,
    clientWidth: Number
  },
  data () {
    return {
      // filter: brightness(' + brightness + '%) contrast(' + contrast + '%) saturate(' + saturate + '%) sepia(' + sepia + '%) hue-rotate(' + hue + 'deg) invert(' + invert + '%);'
      currentImageIndex: 0,
      canvasWidth: 0,
      canvasHeight: 0,
      ratio: 1,
      brightness: 100,
      contrast: 100,
      saturate: 100,
      sepia: 0,
      invert: 0,
      hue: 0,
      reason: '',
      rejecting: false,
      selectedImage: null,
      userChache: [],
      detailsChache: [],
      imageHeight: 100,
      windowOpt: [],
      selectedWindow: '',
      selectedWindowDisplayed: '',
      metadataOpen: false,
      statsOpen: {},
      project: { extra: { grouping: 'study' } }
    }
  },
  components: {},
  computed: {
    ...mapState('BottomSheet', {
      image: (state) => state.image,
      users: (state) => state.annotations,
      phaseData: (state) => state.phase,
      imgSet: (state) => state.set,
      windowOptions: (state) => state.windowOptions
      // projects: (state) => state.projectSubmission.projects,
    }),
    ...mapState('usersSubmission', ['loading', 'members']),
    ...mapState('projectSubmission', ['projects']),
    ...mapState('auth', ['user']),
    getProjectExtra () {
      console.log('get pro', this.projects)
      console.log('get pro', this.phaseData.projectId)
      const _p = this.projects.find(p => p._id === this.phaseData.projectId)
      console.log('extra', _p.extra)
      if (_p && _p.extra && _p.extra.grouping) {
        return _p.extra
      } else {
        return { grouping: '' }
      }
      // return this.projects[0].extra
    },
    current_role () {
      const currentMem = this.members.find(p => p._id === this.user.id)
      // // console.log('member restrict', currentMem)
      if (currentMem) {
        // console.log('member restrict', currentMem, this.phaseData.projectId)
        const currentPro = currentMem.projectRole.find(p => p.project === this.phaseData.projectId)
        if (currentPro) {
          // console.log('member restrict', currentPro.role)
          return currentPro.role
        } else {
          // console.log('member restrict', currentPro)
          return 'Administrator'
        }
      } else {
        return 'View Only'
      }
    },
    isImageSet () {
      return this.image && this.image.length >= 3 && this.image[2].image_set && this.image[2].image_set.name && this.image[2].otherImages && this.image[2].otherImages.length > 0
    },
    getRoute () {
      return apiRoute()
    },
    imageChange () {
      return this.selectedImage
    },
    imageIndex () {
      return this.currentImageIndex
    },
    setCols () {
      if (this.clientWidth >= 1800) {
        return 1
      } else {
        return 2
      }
    },
    windowChange () {
      return this.selectedWindow
    }
  },
  watch: {
    imageIndex (_new) {
      // // console.log(document.activeElement)
      // console.log(_new)
      document.activeElement.blur()
      document.getElementById('image-scroll-bar').blur()
      if (this.imgSet && _new && this.getSelectedIndex(this.selectedImage) !== _new) {
        this.selectedImage = this.imgSet[_new].imgId
      }
    },
    imageChange (_new) {
      var imgObj = {}
      if (_new) {
        imgObj = this.imgSet.find(is => is.imgId === _new)
        this.userChache = imgObj.boxes.sort((a, b) => a.name.localeCompare(b.name))
        this.detailsChache = imgObj.details
        this.drawImageAndBoxes()
        // console.log(_new)
        if (imgObj.details.image_set && imgObj.details.image_set.window) {
          this.selectedWindowDisplayed = imgObj.details.image_set.window
          this.selectedWindow = ''
        }
        // location.href = '#' + _new
        const targetTop = this.getSelectedIndex(_new)
        if (document.getElementById(_new)) {
          const thumbHeight = document.getElementById(_new) ? document.getElementById(_new).offsetHeight : 0
          var margin = parseInt(window.getComputedStyle(document.getElementById(_new)).marginBottom)
          const desiredY = targetTop === 0 ? 0 : (targetTop - 1) * (thumbHeight + margin)
          const element = document.getElementById('img_set')
          element.scroll(0, desiredY)
        }
        // element.scrollIntoView({ block: 'nearest', inline: 'nearest' })
      }
    },
    windowChange (_new, _old) {
      if (_new !== '') {
        var targetIndex = this.imgSet.findIndex(i => i.details.image_set.window === this.selectedWindow)
        this.currentImageIndex = targetIndex
        this.selectedImage = this.imgSet[targetIndex].imgId
        document.activeElement.blur()
      }
    }
  },
  methods: {
    ...mapActions('projectSubmission', ['removeImageFromProject']),
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2']),
    ...mapActions('BottomSheet', {
      bsclear: 'clear'
    }),
    keyScrollImg: function (event) {
      // event.preventDefault()
      // document.activeElement.blur()
      document.getElementById('image-scroll-bar').blur()
      if (this.imgSet) {
        const _index = this.getSelectedIndex(this.selectedImage)
        if (event.which === 38) {
          if (_index > 0) {
            this.selectedImage = this.imgSet[_index - 1].imgId
            this.currentImageIndex = this.getSelectedIndex(this.selectedImage)
          }
        } else if (event.which === 40) {
          // // console.log(this.getSelectedIndex(this.selectedImage) + 1)
          if (_index < this.imgSet.length - 1) {
            this.selectedImage = this.imgSet[_index + 1].imgId
            this.currentImageIndex = this.getSelectedIndex(this.selectedImage)
          }
        }
      }
    },
    preventKeyBoardScroll: function (event) {
      // console.log('prevent')
      var keys = [33, 34, 35, 37, 38, 39, 40]
      if (keys.includes(event.keyCode)) {
        // console.log('prevent1')
        event.preventDefault()
        return false
      }
    },
    getSelectedIndex (id) {
      // console.log(id)
      return this.imgSet.findIndex(s => s.imgId === id)
    },
    getIdentifier: function (id) {
      const _image = this.getImage(id)
      if (_image) {
        // // console.log('image', _image)
        if (this.phaseData.phase === 1) {
          return _image.details.image_set.identifier
        } else {
          return _image.details.image_set.identifier
        }
      } else {
        return 'Unknown'
      }
    },
    getImage: function (id) {
      const _others = this.imgSet
      var _other = []
      if (_others) {
        _other = _others.find(_image => _image.imgId === id)
      } else {
        _other = { imgId: this.phaseData.imageID }
      }
      if (_other) {
        // // console.log('other', _other)
        return _other
      } else {
        return null
      }
    },
    getImageUrl: function (id) {
      if (this.phaseData.phase === 1) {
        const _image = this.getImage(id)
        if (_image) {
          return this.getRoute + '/image/' + _image.imgId
        } else {
          return null
        }
      } else {
        const _image = this.getImage(id)
        if (_image) {
          return this.getRoute + '/image/' + _image.imgId
        } else {
          return null
        }
      }
    },
    async rejectImage () {
      var _removals = []
      _removals.push(this.phaseData.imageID)
      // // // console.log(this.reason)
      // if (this.imgSet.length > 0) {
      //   this.imgSet.forEach(img => {
      //     _removals.push(img.imgId)
      //   })
      // }
      var payload = {
        reason: this.reason,
        p_id: this.phaseData.projectId,
        removalIds: _removals,
        phase: this.phaseData.phase
      }
      // // console.log(payload)
      await this.removeImageFromProject(payload)
      this.fetchLabelledImages2(this.phaseData.projectId)
      this.bsclear()
    },
    toggleUserBoundingBoxes (user) {
      user.showBoundingBoxes = !user.showBoundingBoxes
      this.drawImageAndBoxes()
    },
    toggleBoundingBox (boundingBox) {
      boundingBox.showBoundingBox = !boundingBox.showBoundingBox
      this.drawImageAndBoxes()
    },
    toggleStats (key) {
      this.$set(this.statsOpen, key, !this.statsOpen[key])
    },
    drawImageAndBoxes () {
      // this.getProjectExtra()
      const canvas = this.$refs.canvas
      const ctx = canvas.getContext('2d')
      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      var _title
      // Draw bounding boxes
      if (this.userChache && this.userChache.length > 0) {
        for (const user of this.userChache) {
          if (user.showBoundingBoxes) {
            if (!(user.name === 'AI')) {
              for (const boundingBox of user.boundingBoxes) {
                if (boundingBox.showBoundingBox) {
                  if (boundingBox.polygon && boundingBox.polygon.length > 1) {
                    let minPoint = { x: this.canvasWidth, y: this.canvasHeight }
                    // console.log('min point', minPoint)
                    ctx.lineWidth = 2
                    ctx.strokeStyle = boundingBox.colour
                    ctx.fillStyle = boundingBox.colour + '33'
                    ctx.beginPath()
                    ctx.moveTo(boundingBox.polygon[0].x * this.ratio, boundingBox.polygon[0].y * this.ratio)
                    for (let j = 1; j < boundingBox.polygon.length; j++) {
                      ctx.lineTo(boundingBox.polygon[j].x * this.ratio, boundingBox.polygon[j].y * this.ratio)
                      if (boundingBox.polygon[j].x * this.ratio < minPoint.x) {
                        minPoint = {
                          x: boundingBox.polygon[j].x * this.ratio,
                          y: boundingBox.polygon[j].y * this.ratio
                        }
                      }
                    }
                    ctx.closePath()
                    ctx.stroke()
                    ctx.fill()

                    const _ratio = this.ratio > 1 ? 0.0125 : 0.0165
                    const fontSize = Math.floor(this.canvasWidth * _ratio)
                    ctx.font = fontSize + 'px "Lato"'
                    _title = ctx.measureText(boundingBox.pathology)

                    ctx.shadowOffsetX = 0
                    ctx.shadowOffsetY = 0
                    ctx.shadowBlur = 0

                    const offsetX = _title.width // Math.floor(Math.random() * (this.canvasWidth / 6 - 15)) + 15
                    const offsetY = 30 * this.ratio // Math.floor(Math.random() * (boundingBox.coordinates.h / 2 + 14)) + 15
                    let startingX = boundingBox.window ? (boundingBox.window.x * this.ratio) : minPoint.x // boundingBox.coordinates.x * this.ratio + (boundingBox.coordinates.w * this.ratio / 2)
                    const startingY = boundingBox.window ? ((boundingBox.window.y + boundingBox.window.h / 2) * this.ratio) : minPoint.y // boundingBox.coordinates.y * this.ratio + (boundingBox.coordinates.h * this.ratio / 2)
                    let endingX = startingX - offsetX
                    if (endingX < _title.width) {
                      startingX = boundingBox.window ? ((boundingBox.window.x + boundingBox.window.w) * this.ratio) : (minPoint.x + boundingBox.window.w * this.ratio)
                      endingX = startingX + offsetX
                    }
                    let endingY = startingY - offsetY
                    if (endingY < _title.fontBoundingBoxAscent) {
                      endingY = startingY + offsetY
                    }
                    ctx.moveTo(startingX, startingY)
                    ctx.lineTo(endingX, endingY)
                    ctx.closePath()
                    ctx.stroke()
                    ctx.fillStyle = ctx.strokeStyle = boundingBox.colour // '#ffffff'
                    ctx.fillText(boundingBox.pathology, endingX, endingY)

                    if (boundingBox.other && Array.isArray(boundingBox.other) && boundingBox.other.length > 0) {
                      if (boundingBox.stats && Array.isArray(boundingBox.stats) && boundingBox.stats.length === 1) {
                        const lines = boundingBox.other.filter(_line => _line.view === boundingBox.stats[0].view)
                        ctx.strokeStyle = boundingBox.colour

                        // console.log('debug2', lines)
                        lines.forEach(_line => {
                          const _starting = {
                            x: _line.points[0][0],
                            y: _line.points[0][1]
                          }
                          const _ending = {
                            x: _line.points[1][0],
                            y: _line.points[1][1]
                          }
                          ctx.beginPath()
                          ctx.moveTo(_starting.x * this.ratio, _starting.y * this.ratio)
                          ctx.lineTo(_ending.x * this.ratio, _ending.y * this.ratio)
                          ctx.closePath()
                          ctx.stroke()
                          ctx.beginPath()
                          ctx.arc(_starting.x * this.ratio, _starting.y * this.ratio, 3, 0, 2 * Math.PI, false)
                          ctx.stroke()
                          ctx.beginPath()
                          ctx.arc(_ending.x * this.ratio, _ending.y * this.ratio, 3, 0, 2 * Math.PI, false)
                          ctx.stroke()
                          if (_line.points.length === 4 && _line.type === 'Bidirectional') {
                            const __starting = {
                              x: _line.points[2][0],
                              y: _line.points[2][1]
                            }
                            const __ending = {
                              x: _line.points[3][0],
                              y: _line.points[3][1]
                            }
                            ctx.beginPath()
                            ctx.moveTo(__starting.x * this.ratio, __starting.y * this.ratio)
                            ctx.lineTo(__ending.x * this.ratio, __ending.y * this.ratio)
                            ctx.closePath()
                            ctx.stroke()
                            ctx.beginPath()
                            ctx.arc(__starting.x * this.ratio, __starting.y * this.ratio, 3, 0, 2 * Math.PI, false)
                            ctx.stroke()
                            ctx.beginPath()
                            ctx.arc(__ending.x * this.ratio, __ending.y * this.ratio, 3, 0, 2 * Math.PI, false)
                            ctx.stroke()
                          }
                        })
                      } else {
                        const lines = boundingBox.other.filter(_line => _line.view === 'Axial')
                        ctx.strokeStyle = boundingBox.colour
                        lines.forEach(_line => {
                          ctx.beginPath()
                          ctx.moveTo(_line.points[0][0] * this.ratio, _line.points[0][1] * this.ratio)
                          ctx.lineTo(_line.points[1][0] * this.ratio, _line.points[1][1] * this.ratio)
                          ctx.closePath()
                          ctx.stroke()
                          ctx.beginPath()
                          ctx.arc(_line.points[0][0] * this.ratio, _line.points[0][1] * this.ratio, 3, 0, 2 * Math.PI, false)
                          ctx.stroke()
                          ctx.beginPath()
                          ctx.arc(_line.points[1][0] * this.ratio, _line.points[1][1] * this.ratio, 3, 0, 2 * Math.PI, false)
                          ctx.stroke()
                        })
                      }
                    }
                  } else if (boundingBox.coordinates && boundingBox.coordinates.x) {
                    ctx.strokeStyle = boundingBox.colour
                    ctx.lineWidth = 2
                    ctx.strokeRect(boundingBox.coordinates.x * this.ratio,
                      boundingBox.coordinates.y * this.ratio,
                      boundingBox.coordinates.w * this.ratio,
                      boundingBox.coordinates.h * this.ratio)

                    const fontSize = Math.floor(this.canvasWidth * 0.01367)
                    ctx.font = fontSize + 'px "Lato"'
                    _title = ctx.measureText(boundingBox.pathology)
                    ctx.fillStyle = boundingBox.colour
                    ctx.fillRect(boundingBox.coordinates.x * this.ratio,
                      boundingBox.coordinates.y * this.ratio,
                      boundingBox.coordinates.w * this.ratio,
                      (_title.fontBoundingBoxAscent + _title.fontBoundingBoxDescent))

                    ctx.shadowOffsetX = 0
                    ctx.shadowOffsetY = 0
                    ctx.shadowBlur = 0

                    ctx.fillStyle = ctx.strokeStyle = '#ffffff'
                    ctx.fillText(boundingBox.pathology,
                      boundingBox.coordinates.x * this.ratio,
                      boundingBox.coordinates.y * this.ratio + _title.fontBoundingBoxAscent - 1,
                      boundingBox.coordinates.w * this.ratio)
                  }

                  if (boundingBox.window && boundingBox.window.x) {
                    ctx.strokeStyle = boundingBox.colour + '22'
                    ctx.lineWidth = 2
                    ctx.strokeRect(boundingBox.window.x * this.ratio,
                      boundingBox.window.y * this.ratio,
                      boundingBox.window.w * this.ratio,
                      boundingBox.window.h * this.ratio)
                  }
                }
              }
            } else {
              for (const boundingBox of user.boundingBoxes) {
                if (boundingBox.showBoundingBox) {
                  var _colour = boundingBox.colour
                  var _pathology = boundingBox.pathology.toUpperCase()
                  if (_pathology.toLowerCase() === 'apchest' || _pathology.toLowerCase() === 'heart') {
                    ctx.shadowOffsetX = 2
                    ctx.shadowOffsetY = 2
                    ctx.shadowBlur = 4
                    ctx.shadowColor = _colour
                    ctx.fillStyle = ctx.strokeStyle = _colour
                    ctx.fillStyle = _colour

                    ctx.lineWidth = 2
                    const fontSize = Math.floor(this.canvasWidth * 0.01367)
                    ctx.font = fontSize + 'px "Lato"'
                    _title = ctx.measureText(_pathology)

                    ctx.beginPath()
                    ctx.moveTo(boundingBox.coordinates.x * this.ratio, boundingBox.coordinates.y * this.ratio + _title.fontBoundingBoxAscent + _title.fontBoundingBoxDescent)
                    ctx.lineTo(boundingBox.coordinates.x * this.ratio, boundingBox.coordinates.y * this.ratio - _title.fontBoundingBoxDescent)
                    ctx.moveTo(boundingBox.coordinates.x * this.ratio + boundingBox.coordinates.w * this.ratio, boundingBox.coordinates.y * this.ratio + _title.fontBoundingBoxAscent + _title.fontBoundingBoxDescent)
                    ctx.lineTo(boundingBox.coordinates.x * this.ratio + boundingBox.coordinates.w * this.ratio, boundingBox.coordinates.y * this.ratio - _title.fontBoundingBoxDescent)
                    ctx.moveTo(boundingBox.coordinates.x * this.ratio, boundingBox.coordinates.y * this.ratio)
                    ctx.lineTo(boundingBox.coordinates.x * this.ratio + boundingBox.coordinates.w * this.ratio, boundingBox.coordinates.y * this.ratio)
                    ctx.stroke()

                    ctx.shadowOffsetX = 0
                    ctx.shadowOffsetY = 0
                    ctx.shadowBlur = 0

                    ctx.fillStyle = ctx.strokeStyle = _colour
                    if (_pathology.toLowerCase() === 'apchest') {
                      const _newPathology = _pathology + ', AIC: ' + (boundingBox.confidence * 100).toFixed(2) + '%'
                      ctx.fillStyle = ctx.strokeStyle = _colour
                      ctx.fillText(_newPathology,
                        boundingBox.coordinates.x * this.ratio + 5,
                        boundingBox.coordinates.y * this.ratio + _title.fontBoundingBoxAscent + 1,
                        boundingBox.coordinates.w * this.ratio)
                      // ctx.fillText(boundingBox.pathology,
                      //   boundingBox.coordinates.x + 5,
                      //   boundingBox.coordinates.y + _title.fontBoundingBoxAscent - 1,
                      //   boundingBox.coordinates.w)
                    } else if (_pathology.toLowerCase() === 'heart') {
                      const _newPathology = _pathology + ', CTR'
                      ctx.fillStyle = ctx.strokeStyle = _colour
                      ctx.fillText(_newPathology,
                        boundingBox.coordinates.x * this.ratio + 5,
                        boundingBox.coordinates.y * this.ratio + _title.fontBoundingBoxAscent + 1,
                        boundingBox.coordinates.w * this.ratio)
                    } else {
                      ctx.fillText(_pathology, boundingBox.coordinates.x * this.ratio + 5,
                        boundingBox.coordinates.y * this.ratio + _title.fontBoundingBoxAscent - 1,
                        boundingBox.coordinates.w * this.ratio)
                    }
                  } else {
                    if (boundingBox.polygon && boundingBox.polygon.length > 1) {
                      let minPoint = { x: this.canvasWidth, y: this.canvasHeight }
                      ctx.lineWidth = 2
                      ctx.strokeStyle = boundingBox.colour
                      ctx.fillStyle = boundingBox.colour + '33'
                      // console.log('AI colours', ctx.strokeStyle, ctx.fillStyle)
                      ctx.beginPath()
                      ctx.moveTo(boundingBox.polygon[0].x * this.ratio, boundingBox.polygon[0].y * this.ratio)
                      for (let j = 1; j < boundingBox.polygon.length; j++) {
                        ctx.lineTo(boundingBox.polygon[j].x * this.ratio, boundingBox.polygon[j].y * this.ratio)
                        if (boundingBox.polygon[j].x * this.ratio < minPoint.x) {
                          minPoint = {
                            x: boundingBox.polygon[j].x * this.ratio,
                            y: boundingBox.polygon[j].y * this.ratio
                          }
                        }
                      }
                      ctx.closePath()
                      ctx.stroke()
                      ctx.fill()

                      const fontSize = Math.floor(this.canvasWidth * 0.009) // 0.01367)
                      ctx.font = fontSize + 'px "Lato"'
                      _title = ctx.measureText(boundingBox.pathology)

                      ctx.shadowOffsetX = 0
                      ctx.shadowOffsetY = 0
                      ctx.shadowBlur = 0

                      const offsetX = _title.width // Math.floor(Math.random() * (this.canvasWidth / 6 - 15)) + 15
                      const offsetY = 20 * this.ratio // Math.floor(Math.random() * (boundingBox.coordinates.h / 2 + 14)) + 15
                      const startingX = boundingBox.window ? (boundingBox.window.x * this.ratio) : minPoint.x // boundingBox.coordinates.x * this.ratio + (boundingBox.coordinates.w * this.ratio / 2)
                      const startingY = boundingBox.window ? ((boundingBox.window.y + boundingBox.window.h / 2) * this.ratio) : minPoint.y // boundingBox.coordinates.y * this.ratio + (boundingBox.coordinates.h * this.ratio / 2)
                      let endingX = startingX - offsetX
                      if (endingX < _title.width) {
                        endingX = startingX + offsetX
                      }
                      let endingY = startingY - offsetY
                      if (endingY < _title.fontBoundingBoxAscent) {
                        endingY = startingY + offsetY
                      }
                      ctx.moveTo(startingX, startingY)
                      ctx.lineTo(endingX, endingY)
                      ctx.closePath()
                      ctx.stroke()
                      ctx.fillStyle = ctx.strokeStyle = boundingBox.colour // '#ffffff'
                      ctx.fillText(boundingBox.pathology, endingX, endingY)

                      if (boundingBox.other && Array.isArray(boundingBox.other) && boundingBox.other.length > 0) {
                        const lines = boundingBox.other.filter(_line => _line.view === 'Axial')
                        ctx.strokeStyle = boundingBox.colour
                        lines.forEach(_line => {
                          ctx.beginPath()
                          ctx.moveTo(_line.points[0][0] * this.ratio, _line.points[0][1] * this.ratio)
                          ctx.lineTo(_line.points[1][0] * this.ratio, _line.points[1][1] * this.ratio)
                          ctx.closePath()
                          ctx.stroke()
                          ctx.beginPath()
                          ctx.arc(_line.points[0][0] * this.ratio, _line.points[0][1] * this.ratio, 3, 0, 2 * Math.PI, false)
                          ctx.stroke()
                          ctx.beginPath()
                          ctx.arc(_line.points[1][0] * this.ratio, _line.points[1][1] * this.ratio, 3, 0, 2 * Math.PI, false)
                          ctx.stroke()
                        })
                      }
                    } else {
                      ctx.lineWidth = 2
                      ctx.strokeStyle = _colour
                      ctx.strokeRect(boundingBox.coordinates.x * this.ratio, boundingBox.coordinates.y * this.ratio, boundingBox.coordinates.w * this.ratio, boundingBox.coordinates.h * this.ratio)
                      const fontSize = Math.floor(this.canvasWidth * 0.01367)
                      ctx.font = fontSize + 'px "Lato"'
                      _title = ctx.measureText(_pathology)
                      ctx.fillStyle = _colour
                      ctx.fillRect(boundingBox.coordinates.x * this.ratio, boundingBox.coordinates.y * this.ratio, boundingBox.coordinates.w * this.ratio, _title.fontBoundingBoxAscent + _title.fontBoundingBoxDescent)
                      ctx.fillStyle = ctx.strokeStyle = '#ffffff'
                      // ctx.strokeStyle = '#ffffff'
                      ctx.fillText(_pathology, boundingBox.coordinates.x * this.ratio, boundingBox.coordinates.y * this.ratio + _title.fontBoundingBoxAscent - 1, boundingBox.coordinates.w * this.ratio)
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    imgLoaded () {
      if (!this.firstLoaded) {
        const _imageHeight = this.$refs.annotatedImage.height
        // console.log('Image height', _imageHeight)
        this.imageHeight = _imageHeight
      }

      this.canvasWidth = this.$refs.annotatedImage.width
      this.canvasHeight = this.$refs.annotatedImage.height
      this.ratio = this.$refs.annotatedImage.width / this.$refs.annotatedImage.naturalWidth

      this.$nextTick(() => {
        this.drawImageAndBoxes()
      })
    },
    fullscreen () {
      var url = window.location.host
      var payload = {
        phaseData: this.phaseData
      }
      var _payload = btoa(JSON.stringify(payload))
      window.open('https://' + url + '/fullscreen/?payload=' + _payload)// + '&img=' + this.image + '&phase' + this.phaseData)
    }
  },
  mounted () {
    if (this.imgSet && this.imgSet.length > 0) {
      this.detailsChache = this.imgSet[0]
      this.userChache = this.imgSet[0].boxes
      const _selected = this.imgSet.find(__image => __image.labelled)
      if (_selected && _selected.imgId) {
        this.selectedImage = _selected.imgId
      } else {
        this.selectedImage = this.imgSet[0].imgId
      }
      this.windowOpt = this.windowOptions
      if (this.imgSet[0].details.image_set && this.imgSet[0].details.image_set.window) {
        this.selectedWindowDisplayed = this.imgSet[0].details.image_set.window
      }
      // console.log('windows options', this.windowOpt)
    } else {
      this.detailsChache = this.image[2]
      this.userChache = this.users
      this.selectedImage = this.phaseData.phase.imageID
    }

    // this.drawImageAndBoxes()
    this.brightness = 100
    this.contrast = 100
    this.saturate = 100
    this.sepia = 0
    this.invert = 0
    this.hue = 0

    // this.canvasWidth = image.naturalWidth
    // this.canvasHeight = image.naturalHeight
    // console.log('debug1', this.users)
  },
  beforeMount () {
    /*
    // console.log(this.image)
    this.canvasWidth = this.image[1][0]
    this.canvasHeight = this.image[1][1]
    */
  },
  created () {
    window.addEventListener('keyup', this.keyScrollImg)
    document.addEventListener('keydown', this.preventKeyBoardScroll, false)
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.keyScrollImg)
    document.removeEventListener('keydown', this.preventKeyBoardScroll, false)
  }
}
</script>

<style>
img.labelled {
  border: 3px solid #28a745;
  pointer-events: none;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.2);
}

.pathology-list {
  display: "flex";
  justify-content: space-between;
  align-items: center;

  list-style: none;
  padding-left: 0;
  width: "100%";
}

.accordion-heading {
  font-weight: 600;
}

.annotator {
  border-bottom: 1;
  background-color: #10293d;
}

.scrollable2 {
  overflow-y: scroll;
  overflow-x: hidden;
}

.imageSetContainer {
  overflow-y: hidden;
  overflow-x: hidden;
}

.vranger {
  /* justify-content: start; */
  z-index: 9999;
  background-color: transparent;
  padding: 5px !important;
  position: absolute;
  top: 0px;
  right: 0.4rem;
  width: 16vw;
  height: 25px;
  /* margin-top: 55px; */
  transform: translateX(8vw) translateY(25vh) rotate(90deg);
  /* -moz-transform: translateX(30vh) rotate(90deg); */
  overflow-y: visible;
  overflow-x: visible
 }

 .vranger::-webkit-slider-runnable-track {
  background: #081624;
}
.vranger::-moz-range-track {
  background: #081624;
}
.vranger::-ms-track {
  background: #081624;
}

/* .boundingboxs .position-absolute{
    top: unset!important;
    left: unset!important;
    transform: none!important;
} */
</style>
