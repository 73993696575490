<template>
  <div id="MainView">
    <b-navbar toggleable="lg" type="dark" class="px-4 py-3">
      <b-nav-form class="navy-fields">
        <b-input-group class="rounded-pill">
          <template #prepend>
            <b-input-group-text
              class="rounded-tr-0 rounded-br-0 rounded-pill navy-fields form-control"
            >
              <font-awesome-icon icon="search" />
            </b-input-group-text>
          </template>
          <template #append>
            <b-input-group-text
              class="rounded-tl-0 rounded-bl-0 rounded-pill navy-fields form-control"
            >
            </b-input-group-text>
          </template>
          <b-form-input
            v-model="search"
            placeholder="Search Projects"
            style="width: 25rem"
          ></b-form-input>
        </b-input-group>
      </b-nav-form>
      <b-button v-if="!sortFlag" class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill" @click="toggleSort()">  <font-awesome-icon icon="sort-amount-up"  /> </b-button>
      <b-button v-else class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill" @click="toggleSort()">  <font-awesome-icon icon="sort-amount-down"  /> </b-button>
      <b-button class="ml-3 btn px-3 btn-sm btn-yellow rounded-pill" @click="openProjectSelector()">   <font-awesome-icon icon="filter"  /></b-button>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav class="mr-4">
            <b-nav-item
              href="https://www.edai.africa/contact-us/"
              target="_blank"
              link-classes="btn px-3 btn-sm btn-yellow rounded-pill"
            >
              <font-awesome-icon icon="info" />
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="license text-right">
      License: <b>{{ licenseStatus && licenseStatus.loaded ? 'Loaded' : 'Unknown' }}</b><br />
      Status: <b>{{ licenseStatusDescription }}</b><br />
      DICOM Linked Project: <b>{{ getDicomProjectName }}</b>
    </div>
    <div class="slidein bg-navy px-3 py-2 mt-1" :class="[ openProgress ? 'open': '', openProgressWidth ]">
      <b-row>
        <b-col v-if="uploaderState">
          <h6 class="text-white mb-1">Active uploads</h6>
          <small>
            Image {{ uploaderCurrentImage }} of {{ uploader.files.length }}.
          </small>
          <b-progress height="5px" striped variant="success" class="mb-2"
            :max="uploader.files.length"
            :value="uploaderDone.length" />
          <small>
            Image upload progress: {{ uploadStatus.toFixed(2) }}%.
          </small>
          <b-progress height="5px" striped variant="success"
            :max="100"
            :value="uploadStatus" />
        </b-col>
        <b-col v-if="zipState && !uploaderState && !downloadBatchesState">
          <div v-if="!downloadZipState">
            <div class="d-flex flex-column align-items-center">
              <h6 class="text-white mb-1">Active archive extracting</h6>
              <b-spinner scale="4" class="color-yellow"></b-spinner>
            </div>
          </div>
          <div v-else>
            <h6 class="text-white mb-1">Active archive processing</h6>
            <small>
              Image {{ this.downloadZipCurrent.processed}} of {{  this.downloadZipCurrent.totalFiles }}.
            </small>
            <b-progress height="5px" striped variant="success" class="mb-2"
              :max="this.downloadZipCurrent.totalFiles"
              :value=" this.downloadZipCurrent.processed" />
            <small>
            Archive: 1 of {{  this.zipUploader.files.filter(f => f.status === 'processing').length }}.
            </small>
            <b-progress height="5px" striped variant="success"
              :max=" this.zipUploader.files.filter(f => f.status === 'processing').length"
              :value="1" />
          </div>
        </b-col>
        <b-col v-if="downloadBatchesState">
          <h6 class="text-white mb-1">Active downloads</h6>
          <small>
            {{ downloadStatus.status }}. Image {{ downloadStatus.done }} of {{ downloadStatus.total }}.
          </small>
          <b-progress height="5px" striped variant="success"
            :max="downloadStatus.total + downloadStatus.projects * 3"
            :value="downloadStatus.done" />
        </b-col>
      </b-row>
    </div>
    <div class="px-4 inner-container">
      <!-- v-model="isUpdateModalOpen"
      @hidden="resetModal"
      centered -->
      <b-modal
        v-model="isProjectSelectModalOpen"
        header-close-content="<i class='fa fa-times color-white'></i>"
        hide-footer
      >
        <template #modal-title>
          <b-row class="mx-2">
          <h4 class="mx-1 text-white">Project Selector</h4>
        </b-row>
          <b-row class="mx-2" align-h="around">
        <b-button class="ml-1 btn px-3 btn btn-yellow rounded-pill" @click="selectAll(true)">  Select All </b-button>
        <b-button class="ml-1 btn px-3 btn btn-blue rounded-pill" @click="selectAll(false)">  Deselect All </b-button>
        </b-row>
          <b-form-group :key="projectRender">
            <div
              v-for="project in allProjects"
             :key="project._id">
            <b-form-checkbox
            v-model="displayedProjects[project._id]"
             >
             <p style="color: antiquewhite;">{{project.name}}</p>
            </b-form-checkbox>
            </div>
          </b-form-group>
        </template>
      </b-modal>
      <b-row class="inner-row">
        <b-col cols="4" class="inner-col d-flex flex-column">
          <!-- Accordion -->
          <!--<div style="height: calc(100vh); overflow-y: auto">-->
            <div class="accordion h-100 d-flex flex-column bg-dark-navy" role="tablist">
              <template v-for="project in filterProject">
              <!-- v-if="displayedProjects[project._id]===true"> -->
                <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab" :key="'ch_' + project._id">
                    <!-- v-b-toggle="project._id" -->
                  <b-button block :class="!accordainState[project._id] ? 'collapsed':''" @click="expandAccordain(project._id)">
                    <Strong style="color: antiquewhite;">{{ project.name }}:</Strong> <small class="mx-1"> Created: {{ formatDate(project.date_updated) }}</small>
                    <b-badge v-if="project.busy && project.busy === true" pill class="ml-1 bg-primary color-white font-weight-400">{{project.status}}</b-badge>
                  </b-button>
                </b-card-header>
                <b-collapse :id="project._id" :visible="selected === project._id" role="tabpanel" class="flex-grow-1 overflow-y scroll-overflow h-100" v-model="accordainState[project._id]" :key="'body_' + project._id">
                  <list-view
                    :list="filteredList(project._id)"
                    :active="selected"
                    v-on:phase-selected="selectPhase"
                    :period="period"
                    :page="page"
                    :pmsImagesCount="pmsImagesCount"
                    :pmsImages="pmsImages"
                    :pmsReload="pmsReload"
                    v-on:set-period="setPeriod"
                    v-on:set-page="setPage"
                    v-on:set-pms-image-count="setPmsImageCount"
                    v-on:add-pms-images="addPmsImages"
                    v-on:clear-pms-images="clearPmsImages"
                  ></list-view>
                  <!-- :dates="getListDates(project.id)" -->
                </b-collapse>
              </template>
              <b-card-header header-tag="header" class="p-0 flex-shrink-1" role="tab">
                <b-button v-if=" licenseProjectLimit !== 0 && licenseUserLimit" block class="text-center color-yellow font-weight-900" :class="!accordainState['createNewProject'] ? 'collapsed':''" @click="expandAccordain('createNewProject')">
                  Add New AI Evaluation
                </b-button>
                <p v-else class="text-center color-yellow font-weight-900" >Project license limit reached</p>
                <b-collapse id = 'createNewProject' v-model="accordainState['createNewProject']">
                  <div class="mx-2">
                  <create-project @project-created="closeCreateProject"></create-project>
                  </div>
                </b-collapse>
              </b-card-header>
            </div>
          <!--</div>-->
        </b-col>
        <b-col cols="8" class="inner-col d-flex flex-column">
          <ai-summary-view
            :list="filteredList(projectId)"
            :selected="selected"
            :project-id="projectId"
            v-if="selected && selected === 8"
          ></ai-summary-view>
          <pms-ai-report-summary
            :list="filteredList(projectId)"
            :selected="selected"
            :project-id="projectId"
            :pmsImages="pmsImages"
            v-else-if="selected && selected === 17"
          ></pms-ai-report-summary>
          <pms-alerts-summary
            :list="filteredList(projectId)"
            :selected="selected"
            :project-id="projectId"
            :pmsImages="pmsImages"
            v-else-if="selected && selected === 20"
          ></pms-alerts-summary>
          <pms-reader-summary
            :list="filteredList(projectId)"
            :selected="selected"
            :project-id="projectId"
            :pmsImages="pmsImages"
            v-else-if="selected && selected === 18"
          ></pms-reader-summary>
          <pms-tech-summary
            :list="filteredList(projectId)"
            :selected="selected"
            :project-id="projectId"
            :pmsImages="pmsImages"
            v-else-if="selected && selected === 19"
          ></pms-tech-summary>
          <gallery-view
            v-else-if="selected"
            :list="filteredList(projectId)"
            :selected="selected"
            :project-id="projectId"
            :pmsImages="pmsImages"
            v-on:reload-pms="reloadPms"
            v-on:reload-details="reloadDetails"
            @phase-selected="selectPhase"
          ></gallery-view>
          <nothing-view v-else></nothing-view>
        </b-col>
      </b-row>
    </div>
    <b-modal size="xl" centered v-model="termsModal" header-close-content="">
      <template #modal-title>
        <h4 class="mx-1 text-white">Disclaimer</h4>
      </template>
      <div>
        <b-row>
          <b-col cols="12" lg="3" class="mb-2">
            <h6 class="text-white">PLATFORM<br />DISCLAIMER</h6>
            <p>The information provided by Envisionit Deep AI (“Company”, “we”, “our”, “us”) on RATify AI Assurance Tool Software-as-a-Service (the “Platform”) is for general informational purposes only. All information on the Platform is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Platform.</p> <p>
              Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the Platform or reliance on any information provided on or produced or published by the Platform. Your use of the Platform and your reliance on any information on the Platform is solely at your own risk.</p>
          </b-col>
          <b-col cols="12" lg="3" class="mb-2">
            <h6 class="text-white">PROFESSIONAL<br />DISCLAIMER</h6>
            <p>The services provided on the Platform are for the validation and monitoring of third-party AI solutions based on the information uploaded or provided to the Platform. All findings and/or reports generated by the Platform are based on a algorithmic analysis of the information uploaded or provided on the Platform using machine learning technology.</p><p>
              The Platform therefore does not contain medical advice of any nature. The information is provided for general notification and is not a substitute for professional medical advice. Information produced or published by the Platform is intended to be used and must be used for notification purposes only and must be verified by an appropriately trained medical practitioner. Accordingly, before taking any actions based upon such information or relying on such information, you must consult an appropriately trained medical practitioner.</p>
                <p>The use or reliance of any information contained on this Platform is solely at your own risk.</p>
            </b-col>
          <b-col cols="12" lg="3" class="mb-2">
            <h6 class="text-white">LIMITATION<br />OF LIABILITY</h6>
            <p>
              The customer agrees and acknowledges that the Services do not give specific advice on individual patients and should only be used by authorised users acting within their clinical competencies. The Services are not a substitute for professional medical qualifications and are used at the customer's own risk.
            </p>
            <p>
              For the avoidance of doubt, Envisionit Deep AI is not liable for and does not guarantee compliance with any of the Customer's regulatory or legal obligations, including those which relate to patient care, medical advice, treatment, diagnosis and healthcare.
            </p>
            <p>The Customer assumes sole responsibility for results obtained from the use of the Services and the Documentation by the Customer, and for conclusions drawn from such use. Envisionit Deep AI shall have no liability for any damage caused by errors or omissions in any information or instructions, Images or scripts provided to Envisionit Deep AI by the Customer in connection with the Services, or any actions taken by Envisionit Deep AI at the Customer's direction.</p>
            </b-col>
          <b-col cols="12" lg="3" class="mb-2">
            <h6 class="text-white">&nbsp;<br /><br /></h6>
            <p>All warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by applicable law, excluded from this agreement; and the services are provided to the Customer on an "as is" basis.</p>
         <h6 class="text-white">CONTACT US</h6>
            <p>Should you have any feedback, comments, requests for technical support or other inquiries, please contact us by email:<a href="mailto:info@envisionit.ai">info@envisionit.ai</a>.</p>
          </b-col>
        </b-row>
      </div>
      <div slot="modal-footer" class="d-flex justify-content-between">
        <button class="btn btn-secondary  rounded-pill bg-red mx-1" @click="doLogout()">
          Logout
        </button>
        <button class="btn btn-secondary bg-yellow rounded-pill" @click="termsAccept">
          Accept
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { apiRoute, authHeader } from './../../helpers'
import { mapState, mapActions } from 'vuex'
// import moment from 'moment'
import AiSummaryView from './components/Results/Summary.vue'
import PmsAiReportSummary from './components/PMS/AIvsReportSummary.vue'
import PmsAlertsSummary from './components/PMS/AlertsSummary.vue'
import PmsReaderSummary from './components/PMS/ReaderSummary.vue'
import PmsTechSummary from './components/PMS/TechSummary.vue'
import CreateProject from './components/CreateProject'
import ListView from './components/List'
import GalleryView from './components/GalleryView'
import NothingView from './components/Nothing'

export default {
  components: {
    AiSummaryView,
    PmsAiReportSummary,
    PmsAlertsSummary,
    PmsReaderSummary,
    PmsTechSummary,
    ListView,
    GalleryView,
    NothingView,
    CreateProject
  },
  data () {
    return {
      isCreateProjectModalOpen: false,
      activeAccordion: null,
      current: null,
      interval: null,
      allLoaded: false,
      selected: null,
      projectId: null,
      search: '',
      now: [],
      earlier: [],
      yesterday: [],
      week: [],
      older: [],
      key: 0,
      accordainState: {},
      filterProject: [],
      sortFlag: true,
      projectSelector: -1,
      allProjects: [],
      displayedProjects: {
      },
      tflag: [],
      isProjectSelectModalOpen: false,
      firstPull: true,
      projectRender: 0,
      intervalId: null,
      termsModal: false,
      period: 'count',
      page: 1,
      pmsImagesCount: null,
      pmsImages: [],
      pmsReload: 0,
      batchesInterval: null,
      zipInterval: null,
      uploaderActive: [],
      uploaderDone: []
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
      license: (state) => state.license,
      projects: (state) => state.projectSubmission,
      auth: (state) => state.auth,
      uploader: (state) => state.uploader,
      zipUploader: (state) => state.zipUploader
    }),
    ...mapState('labelledImagesSubmission', {
      _labelledImagesSubmission: (state) => state
    }),
    loadState () {
      return this.projects.loading
    },
    getDicomProjectName () {
      // console.log('projects12', this.projects)
      const _p = this.projects.projects.filter(p => { return p.dicom_enabled === true })
      if (_p.length === 0) {
        return 'None Linked'
      } else {
        return _p[0].name
      }
    },
    downloadZipState () {
      if (this.zipUploader.uploading) {
        const _inProgress = this.zipUploader.files.filter(b => b.status !== 'completed' && b.status !== 'failed')
        // .filter(b => b.status !== 'Completed' && b.status !== 'Failed')
        // console.log('zip upload', _inProgress)
        return _inProgress.length > 0
      } else {
        return false
      }
    },
    downloadZipCurrent () {
      const records = this.zipUploader.files.filter(b => b.status !== 'completed' && b.status !== 'failed')
      if (this.downloadZipState && records.length > 0) {
        return records[0]
      } else {
        return false
      }
    },
    downloadBatchesState () {
      if (this._labelledImagesSubmission.batches && this._labelledImagesSubmission.batches.length > 0) {
        const _inProgress = this._labelledImagesSubmission.batches.filter(b => b.status !== 'Completed' && b.status !== 'Failed')
        return _inProgress.length > 0
      } else {
        return false
      }
    },
    downloadStatus () {
      if (this.downloadBatchesState) {
        const _inProgress = this._labelledImagesSubmission.batches.filter(b => b.status !== 'Completed' && b.status !== 'Failed')
        const _compressing = _inProgress.filter(b => b.status === 'Compressing')
        const _uploading = _inProgress.filter(b => b.status === 'Uploading')
        const _state = {
          projects: _inProgress.length,
          done: _inProgress.reduce((acc, i) => acc + (i.processed_images || 0), 0),
          total: _inProgress.reduce((acc, i) => acc + i.total_images, 0),
          status: _uploading.length > 0 ? 'Uploading' : (_compressing.length > 0 ? 'Compressing' : 'Processing')
        }

        return _state
      } else {
        return null
      }
    },
    uploaderState () {
      return this.uploader.uploading
    },
    zipState () {
      return this.zipUploader.uploading
    },
    uploaderActiveCount () {
      return this.uploaderActive.length
    },
    uploaderCurrentImage () {
      if (this.uploaderState) {
        return this.uploaderDone.length < this.uploader.files.length ? this.uploaderDone.length + 1 : this.uploaderDone.length
      } else {
        return null
      }
    },
    uploadStatus () {
      if (!this.uploaderActive || this.uploaderActive.length <= 0) {
        return 0
      } else {
        if (this.uploaderActiveCount > 0) {
          let sum = 0
          this.uploaderActive.forEach(f => {
            sum += parseFloat(f.progress)
          })

          return sum / this.uploaderActive.length
        } else {
          return 0
        }
      }
    },
    openProgress () {
      // return true
      return this.downloadBatchesState || this.uploaderState || this.zipState
    },
    openProgressWidth () {
      if (this.downloadBatchesState && this.uploaderState) {
        return 'w-50'
      } else if (this.openProgress) {
        return 'w-25'
      } else {
        return ''
      }
    },
    getSearch () {
      return this.search
    },
    selectedData () {
      // // console.log('this is selected', this.selected)
      return this.selected
    },
    sortOrientation () {
      return this.sortFlag
    },
    licenseStatus () {
      return this.license.status
    },
    licenseProjectLimit () {
      if (this.license && this.license.info && this.license.info.availableProjects) {
        return this.license.info.availableProjects
      } else {
        return 0
      }
    },
    licenseUserLimit () {
      if (this.license && this.license.info && this.license.info.availableUsers) {
        // console.log('user id', this.auth.user.id)
        // console.log('user id', this.license.info.licensedUserList.findIndex(u => u._id === this.auth.user.id))
        if (Math.abs(this.license.info.availableUsers) > 0 || this.isLicensedUser) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isLicensedUser () {
      if (this.license && this.license.info && this.license.info.licensedUserList) {
        const i = this.license.info.licensedUserList.findIndex(u => u._id === this.auth.user.id)
        if (i >= 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    licenseStatusDescription () {
      if (this.licenseStatus && this.licenseStatus.loaded) {
        if (this.license && this.license.info) {
          const _info = this.license.info
          if (_info.userOverallocation > 0) {
            if (_info.projectOverallocation > 0) {
              return 'User and Project license overallocation!'
            } else {
              return 'User license overallocation!'
            }
          } else if (_info.projectOverallocation > 0) {
            return 'Project license overallocation!'
          } else {
            if (_info.licensedUsers >= 0 && _info.licensedProjects >= 0) {
              return 'Valid. Max users: ' + (_info.licensedUsers === 0 ? 'Unlimited' : _info.licensedUsers) + ', Max evaluations: ' + (_info.licensedProjects === 0 ? 'Unlimited' : _info.licensedProjects)
            } else {
              return 'Invalid'
            }
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  },
  methods: {
    ...mapActions('labelledImagesSubmission', {
      getDownloadBatches: 'getBatches'
    }),
    ...mapActions('zipUploader', {
      getDownloadZips: 'poll',
      toggleZipUpload: 'setUpload'
    }),
    ...mapActions('staffSubmission', {
      getAllSubmissions: 'getCovid',
      clearSubmissions: 'clear'
    }),
    ...mapActions('license', {
      getLicense: 'get'
    }),
    ...mapActions('projectSubmission', ['getUserProjects', 'selectProject']),
    handleProjectCreated () {
      this.getUserProjects()
    },
    ...mapActions('BottomSheet', {
      bsclear: 'clear'
    }),
    ...mapActions('auth', ['logout']),
    ...mapActions('alert', {
      alertSucces: 'success',
      alertError: 'error'
    }),
    ...mapActions('uploader', {
      clearUploader: 'clear'
    }),
    ...mapActions('labelledImagesSubmission', ['fetchLabelledImages2', 'fetchAILabelledImages', 'fetchLabelledImages2Single', 'fetchAILabelledImagesSingle']),
    ...mapActions('usersSubmission', {
      getMembers: 'getMembers'
    }),
    ...mapActions('projectSubmission', ['getProjectImages2', 'getProjectImages2Single', 'getRejections2', 'getAllImages2', 'getUserProjects', 'getProjectStats', 'getProjectDetailedStats']),
    doLogout: function () {
      this.logout()
      this.$router.push('/login')
    },
    formatDate (dateString) {
      const date = new Date(dateString)
      return date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    },
    selectAll (val) {
      Object.keys(this.displayedProjects).forEach(p => {
        this.displayedProjects[p] = val
      })
      this.projectRender++
    },
    openProjectSelector: function () {
      this.isProjectSelectModalOpen = true
      // // console.log('open project select', this.isProjectSelectModalOpen)
    },
    toggleAccordion: function () {
      // // console.log(this.accordainState)
    },
    toggleSort: function () {
      this.selected = null
      this.sortFlag = !this.sortFlag
    },
    mapAccordain: function () {
      this.allProjects = this.projects.projects
      this.allProjects.forEach(_p => {
        if (!this.displayedProjects[_p._id]) {
          this.displayedProjects[_p._id] = true
        }
      }
      )
      this.filterProject = this.allProjects.reverse().slice(0, 5)
      this.accordainState.createNewProject = false
      this.firstPull = false
    },
    expandAccordain: function (_proID) {
      this.bsclear()
      this.selectProject(_proID)
      if (this.accordainState[_proID]) {
        this.accordainState[_proID] = false
      } else {
        this.accordainState = Object.assign(...Object.keys(this.accordainState).map(k => ({ [k]: false })))
        this.accordainState[_proID] = true
      }
    },
    refreshStudies: function () {},
    filteredList (projectId) {
      return this.projects.projects.filter((project) => project._id === projectId)
    },
    openCreateModal () {
      this.isCreateProjectModalOpen = true
    },
    closeCreateProject () {
      this.isCreateProjectModalOpen = false
      this.firstPull = true
      this.getUserProjects()
    },
    displayResult: async function (data) {
      this.current = data

      const requestOptions = {
        method: 'GET',
        headers: authHeader()
      }
      const _response = await fetch(
        apiRoute() +
          '/api/v1/staff/covid/heatmaps/' +
          this.current.submission._id,
        requestOptions
      )
      const results = await _response.json()
      this.current.heatmaps = results
    },
    getConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return 'High Probability'
      } else if (name.indexOf('intermediate') > -1) {
        return 'Intermediate Probability'
      } else if (name.indexOf('low') > -1) {
        return 'Low Probability'
      } else if (name.indexOf('other') > -1) {
        return 'Other X-Rays'
      } else {
        return 'No Pathologies Found'
      }
    },
    getConclusionClass: function (name) {
      if (name.indexOf('high') > -1) {
        return 'text-danger'
      } else if (name.indexOf('intermediate') > -1) {
        return 'text-warning'
      } else {
        return 'text-success'
      }
    },
    getCurrentClass: function (_id) {
      if (this.current && this.current.submission) {
        if (this.current.submission._id === _id) {
          // // console.log('Selected')
          return 'selected-row'
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    originalImage: function (result) {
      if (result) {
        return apiRoute() + result.attachments[0].path
      } else {
        return null
      }
    },
    boundingBoxes: function (result) {
      if (result) {
        return apiRoute() + result.attachments[0].response.bounding.path
      } else {
        return null
      }
    },
    hasCardiomegaly: function (result) {
      if (result) {
        const _pathology =
          result.attachments[0].response.labels[0].pathologies.find(
            (pathology) => pathology.predicted_diagnosis === 'cardiomegaly'
          )
        if (_pathology) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    selectPhase (id) {
      this.selected = id
    },
    onCollapseState: function (collapseId, isJustShown) {
      if (isJustShown) {
        this.projectId = collapseId
        this.selected = null
      }
    },
    updateAccordain () {
      for (let i = 0; i < this.filterProject.length; i++) {
        this.filterProject[i] = this.projects.projects.find(p => p._id === this.filterProject[i]._id)
      }
    },
    checkUserAcceptance () {
      // console.log('token', this.auth.user.id)
      if (localStorage.getItem(this.auth.user.id) !== null) {
        // console.log('The key exists in local storage.')
        this.termsModal = false
      } else {
        this.termsModal = true
        // console.log('The key does not exist in local storage.')
      }
    },
    termsAccept () {
      localStorage.setItem(this.auth.user.id, 'Accepted')
      this.termsModal = false
    },
    setPeriod (period) {
      this.period = period
    },
    setPage (page) {
      this.page = page
    },
    setPmsImageCount (count) {
      this.pmsImagesCount = count
    },
    addPmsImages (images) {
      this.pmsImages = [...this.pmsImages, ...images]
    },
    clearPmsImages () {
      this.pmsImages = []
    },
    reloadPms () {
      this.pmsReload++
    },
    reloadDetails () {
      this.fetchLabelledImages2(this.projectId)
      this.fetchAILabelledImages(this.projectId)
      this.getProjectImages2(this.projectId)
      this.getRejections2(this.projectId)
      this.getAllImages2(this.projectId)
    },
    async uploadFiles (files, maxConcurrent = 2) {
      const activeUploads = new Set()
      const queue = [...files]
      const results = []

      const processFile = async (fileObj) => {
        // Add to active uploads array for tracking
        this.uploaderActive.push(fileObj)

        try {
          return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest()
            const formData = new FormData()

            // Add the file
            formData.append('file', fileObj.file)

            // Add all data properties to formData
            if (fileObj.data) {
              Object.entries(fileObj.data).forEach(([key, value]) => {
                formData.append(key, value)
              })
            }

            // Setup progress tracking
            xhr.upload.onprogress = (event) => {
              if (event.lengthComputable) {
                const progress = Math.round((event.loaded * 100) / event.total * 100) / 100

                // Find the file in uploaderActive and update its progress
                const activeIndex = this.uploaderActive.findIndex(f => f.id === fileObj.id)
                if (activeIndex !== -1) {
                  this.uploaderActive[activeIndex].progress = progress
                }
              }
            }

            // Setup completion handler
            xhr.onload = () => {
              if (xhr.status >= 200 && xhr.status < 300) {
                // Move to done array and remove from active
                this.uploaderDone.push({
                  ...fileObj,
                  response: xhr.status
                })

                const activeIndex = this.uploaderActive.findIndex(f => f.id === fileObj.id)
                if (activeIndex !== -1) {
                  this.uploaderActive.splice(activeIndex, 1)
                }

                resolve({
                  status: xhr.status
                })
              } else {
                reject(new Error(`HTTP Error: ${xhr.status}`))
              }
            }

            // Setup error handler
            xhr.onerror = () => {
              reject(new Error('Network Error'))
            }

            // Add headers
            xhr.open('POST', fileObj.postAction)
            if (fileObj.headers) {
              Object.entries(fileObj.headers).forEach(([key, value]) => {
                xhr.setRequestHeader(key, value)
              })
            }

            // Send the request
            xhr.send(formData)
          })
        } catch (error) {
          // Move to done array with error and remove from active
          this.uploaderDone.push({
            ...fileObj,
            response: {
              error: error.message,
              status: 'error'
            }
          })

          const activeIndex = this.uploaderActive.findIndex(f => f.id === fileObj.id)
          if (activeIndex !== -1) {
            this.uploaderActive.splice(activeIndex, 1)
          }

          throw error
        }
      }

      // Process files with concurrency limit
      while (queue.length > 0 || activeUploads.size > 0) {
        while (queue.length > 0 && activeUploads.size < maxConcurrent) {
          const fileObj = queue.shift()
          const promise = processFile(fileObj)
            .then(result => {
              activeUploads.delete(promise)
              results.push(result)
            })
            .catch(error => {
              activeUploads.delete(promise)
              console.error(`Error uploading file ${fileObj.name}:`, error)
            })

          activeUploads.add(promise)
        }

        // Wait for at least one upload to complete before continuing
        if (activeUploads.size >= maxConcurrent || queue.length === 0) {
          await Promise.race(activeUploads)
        }
      }

      return results
    },
    async handleUploads () {
      if (this.uploaderState) {
        try {
          const files = this.uploader.files
          const results = await this.uploadFiles(files)
          console.log(results, files)
          this.alertSucces('All images have been uploaded.')
          const _zips = files.filter(f => f.name.endsWith('.zip'))
          console.log('zips', _zips)
          if (_zips && _zips.length > 0) {
            const pl = { val: true }
            this.toggleZipUpload(pl)
            if (!this.zipInterval) {
              const self = this
              this.zipInterval = setInterval(() => {
                const lp = { pid: 123 }
                self.getDownloadZips(lp)
              }, 5000)
            }
          }
          this.clearUploader()
          this.reloadDetails()
          this.uploaderActive = []
          this.uploaderDone = []
        } catch (error) {
          console.log(error)
          this.alertError('There was an error uploading images.')
        }
      }
    }
  },
  mounted () {
    this.checkUserAcceptance()
    const lp = { pid: 123 }
    this.getDownloadZips(lp)
    // this.getAllSubmissions(this.period)
    this.firstPull = true
    this.$root.$on('bv::collapse::state', this.onCollapseState)
    if (!this.licenseStatus || !this.licenseStatus.loaded) {
      this.getLicense()
    }
    this.getUserProjects()
    this.intervalId = setInterval(() => {
      this.getUserProjects()
    }, 30000)
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
  },
  destroyed () {
    this.$root.$off('bv::collapse::state', this.onCollapseState)

    clearInterval(this.interval)
    this.interval = null
  },
  watch: {
    loadState (loading) {
      // // console.log('project watch', loading)
      if (!loading && this.firstPull) {
        this.mapAccordain()
      } else {
        this.updateAccordain()
      }
    },
    getSearch (_new) {
      // // // console.log(_new)
      this.selected = null
      var _tProjects = []
      this.allProjects.forEach(_p => {
        if (_p.name.toLowerCase().includes(_new.toLowerCase()) && this.displayedProjects[_p._id]) {
          _tProjects.push(_p)
        }
      })
      if (this.sortFlag) {
        this.filterProject = _tProjects.slice(0, 5)
      } else {
        this.filterProject = _tProjects.reverse().slice(0, 5)
      }
    },
    isProjectSelectModalOpen (_new) {
      // // // console.log('filter')search
      if (!_new) {
        this.selected = null
        var _tProjects = []
        this.allProjects.forEach(_p => {
          if (_p.name.toLowerCase().includes(this.search.toLowerCase()) && this.displayedProjects[_p._id]) {
            _tProjects.push(_p)
          }
        })
        if (this.sortFlag) {
          this.filterProject = _tProjects.slice(0, 5)
        } else {
          this.filterProject = _tProjects.reverse().slice(0, 5)
        }
      }
      // var _projects = this.projects.projects
      // _projects = _projects.filter((_p) => this.displayedProjects[_p.id])
      // this.allProjects = _projects
    },
    sortOrientation (_new) {
      var _tProjects = []
      if (_new) {
        this.allProjects.forEach(_p => {
          if (_p.name.toLowerCase().includes(this.search.toLowerCase()) && this.displayedProjects[_p._id]) {
            _tProjects.push(_p)
          }
        })
        this.filterProject = _tProjects.slice(0, 5)
      } else {
        this.allProjects.forEach(_p => {
          if (_p.name.toLowerCase().includes(this.search.toLowerCase()) && this.displayedProjects[_p._id]) {
            _tProjects.push(_p)
          }
        })
        this.filterProject = _tProjects.reverse().slice(0, 5)
      }
    },
    downloadBatchesState () {
      if (this.downloadBatchesState) {
        if (!this.batchesInterval) {
          const self = this
          this.batchesInterval = setInterval(() => {
            self.getDownloadBatches()
          }, 5000)
        }
      } else {
        clearInterval(this.batchesInterval)
        this.batchesInterval = null
      }
    },
    downloadZipState () {
      console.log('zip sate', this.downloadZipState)
      if (this.downloadZipState) {
        if (!this.zipInterval) {
          const self = this
          this.zipInterval = setInterval(() => {
            const lp = { pid: 123 }
            self.getDownloadZips(lp)
          }, 5000)
        }
      } else {
        clearInterval(this.zipInterval)
        this.zipInterval = null
        const pl = { val: false }
        this.toggleZipUpload(pl)
        this.reloadDetails()
      }
    },
    uploaderState () {
      if (this.uploaderState) {
        if (this.uploader.files && this.uploader.files.length > 0) {
          this.handleUploads()
        }
      }
    }
    // uploaderCurrentImage () {
    //   console.log('uploaded', this.uploaderDone.length)
    //   if (this.uploaderDone && this.uploaderDone.length > 0 && this.uploaderDone[this.uploaderDone.length - 1].file.name.endsWith('.zip')) {
    //     console.log('uploaded', this.uploaderDone[this.uploaderDone.length - 1].file.name.endsWith('.zip'))
    //     this.toggleZipUpload()
    //   }
    // }
  }
}
</script>
